import { Injectable } from '@angular/core';
import { IOrgLevelDto } from '../../models/index';
import { OrgLevel, OrgLevelLocation, IOrgLevelLocation } from '../../../state-model/models/index';
import { organizationConfig } from '../../organization.config';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';

@Injectable()
export class OrgLevelMapService {


  public mapToOrgLevelNonStrict(item?: any): OrgLevel {
    if (item) return this.mapToOrgLevel(item);
    return this.getNotFoundOrgLevel();
  }

  public mapToOrgLevels(dtos: IOrgLevelDto[]): OrgLevel[] {
    let orgLevels: OrgLevel[] = [];
    _.each(dtos, (orgLevelDto: IOrgLevelDto) => {
      orgLevels.push(this.mapToOrgLevel(orgLevelDto));
    });
    return orgLevels;
  }

  public mapToOrgLevel(item: any): OrgLevel {
    Assert.isNotNull(item, 'item');
    Assert.isNotNull(item.id, 'item.id');

    let orgLevel: OrgLevel = new OrgLevel();
    orgLevel.id = item.id;
    orgLevel.name = item.name;
    orgLevel.type = item.orgLevelType;
    orgLevel.sequenceNumber = item.sequenceNumber;
    orgLevel.relatedItemId = item.relatedItemId;
    orgLevel.parentId = item.parentId;
    orgLevel.childs = [];
    orgLevel.treeViewPath = [];
    orgLevel.treeViewNamePath = [];
    orgLevel.organizationId = item.organizationId;
    orgLevel.location = this.mapToOrgLevelLocation(item.location);
    return orgLevel;
  }

  public mapToOrgLevelLocation(dto: IOrgLevelLocation): OrgLevelLocation {
    if (!dto) return null;
    let loc: OrgLevelLocation = new OrgLevelLocation();
    loc.city = dto.city;
    loc.zipCode = dto.zipCode;
    loc.latitude = dto.latitude;
    loc.longitude = dto.longitude;
    loc.timeZoneOffset = dto.timeZoneOffset;
    loc.timeZone = dto.timeZone;
    loc.state = dto.state;
    loc.stateAbbreviation = dto.stateAbbreviation;
    return loc;
  }

  public mapOrgLevelsDTO(lvls: OrgLevel[]): IOrgLevelDto[] {
    let dtos: IOrgLevelDto[] = [];
    _.each(lvls, (lvl: OrgLevel) => {
      let dto: IOrgLevelDto = this.mapOrgLevelDTO(lvl);
      dtos.push (dto);
    });
    return dtos;
  }

  public mapOrgLevelDTO(lvl: OrgLevel): IOrgLevelDto {
    return {
      id: lvl.id,
      name: lvl.name,
      parentId: lvl.parentId,
      relatedItemId: lvl.relatedItemId,
      sequenceNumber: lvl.sequenceNumber,
      type: lvl.type
    };
  }

  public mapToOrgTree(data: IOrgLevelDto[]): OrgLevel[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: OrgLevel[] = [];
    let hash: NumberMap<OrgLevel> = {};
    _.forEach(data, (item: IOrgLevelDto) => {
      let orgLevel: OrgLevel = this.mapToOrgLevel(item);
      Assert.isNotOwnProperty(hash, orgLevel.id, `Dublicate OrgLevel id ${orgLevel.id}.`);
      hash[orgLevel.id] = orgLevel;
    });
    for (let id in hash) {
      let orgLevel: OrgLevel = hash[id];
      Assert.isFalse(orgLevel.parentId === orgLevel.id);
      if (orgLevel.parentId === organizationConfig.domain.emptyId) {
        let seq: number = _.sortedIndexBy(roots, orgLevel, (ol: OrgLevel): string => {
          return ol.name ? ol.name.toLowerCase() : '';
        });
        roots.splice(seq, 0, orgLevel);
        continue;
      }
      Assert.isOwnProperty(hash, orgLevel.parentId, 'Unknown OrgLevel parentId');
      let parentOrgLevel: OrgLevel = hash[orgLevel.parentId];
      let seq: number = _.sortedIndexBy(parentOrgLevel.childs, orgLevel, (ol: OrgLevel): string => {
        return ol.name ? ol.name.toLowerCase() : '';
      });
      parentOrgLevel.childs.splice(seq, 0, orgLevel);
    }
    roots.forEach((root: OrgLevel) => this.createTreeViewPath(root));
    return roots;
  }

  private createTreeViewPath(orgLevel: OrgLevel): void {
    Assert.isNotNull(orgLevel, 'createTreeViewPath: orgLevel');
    Assert.isArray(orgLevel.childs, 'createTreeViewPath: child');
    Assert.isArray(orgLevel.treeViewPath, 'createTreeViewPath: treeViewPath');
    orgLevel.childs.forEach((child: OrgLevel) => {
      child.treeViewPath = orgLevel.treeViewPath.concat([orgLevel.id]);
      child.treeViewNamePath = orgLevel.treeViewNamePath.concat([orgLevel.name]);
      this.createTreeViewPath(child);
    });
  }

  private getNotFoundOrgLevel(): OrgLevel {
    let orgLevel: OrgLevel = new OrgLevel();
    orgLevel.id = organizationConfig.domain.emptyId;
    orgLevel.parentId = organizationConfig.domain.emptyId;
    orgLevel.childs = [];
    orgLevel.treeViewPath = [];
    orgLevel.treeViewNamePath = [];
    return orgLevel;
  }
}


