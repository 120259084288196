import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import * as _ from "lodash";

@Directive({
    selector: '[EmpAssignedType]'
})
export class EmpAssignedTypeDirective implements AfterViewInit {

    @Input()
    public EmpAssignedType: string;
    private colorCodes: any[] = [];
    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    ngAfterViewInit(): void {
        this.colorCodes = [];
        let types = !(_.isNull(this.EmpAssignedType)
            || _.isUndefined(this.EmpAssignedType)
            || _.lt(this.EmpAssignedType.length, 1)) ? this.EmpAssignedType.split(',') : '';
        _.each(types, (type) => this.createColorCode(type));
        this.appendChildNode();
    }

    private createColorCode(type) {
        if (_.eq(type.length, 0)) return;
        if (type == 'Agency') {
            this.colorCodes.push(
                {
                    name: type,
                    value: '#f68d2e',
                    className: 'agency',
                    toolTipMessage: 'Agency employee'
                })
        }
        else if (type == 'Absence') {
            this.colorCodes.push(
                {
                    name: type,
                    value: 'lawngreen',
                    className: 'absence',
                    toolTipMessage: 'Has Absence'
                })
        }
        else if ( type == 'Partical Absence') {
            this.colorCodes.push(
                {
                    name: type,
                    value: 'yellow',
                    className: 'Has Partial Absence',
                    toolTipMessage: 'Has Partial Absence'
                })
        }
        else if (type == 'OtherDepartment') {
            this.colorCodes.push(
                {
                    name: type,
                    value: '#0047bb',
                    className: 'otherDepartment',
                    toolTipMessage: 'Scheduled in different department'
                })
        }
        else if (type == 'SecondaryPosition') {
            this.colorCodes.push(
                {
                    name: type,
                    value: '#000',
                    className: 'secondaryPosition',
                    toolTipMessage: 'Scheduled to secondary position'
                })
        }
        else if (type == 'MultipleShifts') {
            this.colorCodes.push(
                {
                    name: type,
                    value: 'darkgreen',
                    className: 'multipleShifts',
                    toolTipMessage: 'Scheduled to multiple shifts'
                })
        }
    }

    private appendChildNode() {
        if (_.eq(this.colorCodes.length, 0)) return;
        _.each(this.colorCodes, (colorCode) => this.el.append(this.createNode(colorCode)));
    }

    private createNode(colorCode: any) {
        let parentNode = document.createElement('span');
        parentNode.className = `theme-tooltip`;
        let childNode = document.createElement('i');
        childNode.className = 'fas fa-circle';
        childNode.style.cssText = `
            color: ${colorCode.value};
            margin: 0 5px 0 0;
            font-size: 10px
        `
        let toolTipNode = document.createElement('span');
        toolTipNode.className = 'theme-tooltiptext';
        toolTipNode.style.cssText = `
            left: 12px;
        `
        toolTipNode.innerText = `${colorCode.toolTipMessage}  `;
        parentNode.append(toolTipNode);
        parentNode.append(childNode);
        return parentNode;
    }

    ngOnDestroy() {
        this.EmpAssignedType = "";
    }

}