import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridItem, SaveEvent } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { appMessages, IApplicationMessages } from '../../../app.messages';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { destroyService } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import {
  DialogOptions,
  KendoGridStateHelper,
  KendoGridCustomSelectionHelper, ColumnManagementService, StateManagementService, InfoDialogComponent, PopoverContentComponent, PopoverDirective
} from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';

import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { AccessManagementService, ShiftsManagementService } from '../../services/index';
import { Shift } from '../../models/shifts/shift';

import { ShiftRemoveDialogComponent } from './shift-remove-dialog/shift-remove-dialog.component';
import { ShiftsContainer } from '../../models/shifts/shifts-container';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { dateTimeUtils } from '../../../common/utils/index';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { AppSettingsManageService } from './../../../../app/app-settings/services';
import { AppServerConfig } from './../../../../app/app-settings/model/app-server-config';
import { ShiftSetting } from '../../models/shifts/shift-setting';
import { isNullOrUndefined } from 'util';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
  moduleId: module.id,
  selector: 'slx-shifts-component',
  templateUrl: 'shifts.component.html',
  styleUrls: ['shifts.component.scss'],
  providers: [ShiftsManagementService, AccessManagementService, ColumnManagementService, StateManagementService]
})
export class ShiftsComponent implements OnInit, OnDestroy {

  public readonly columnsGroupName: string = 'ConfigureShifts';
  public loadingPanelVisible = true;

  public get container(): ShiftsContainer {
    return this.m_container;
  }

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<Shift>;
  public gridState: KendoGridStateHelper<Shift>;
  public selectionHelper: KendoGridCustomSelectionHelper<Shift>;

  public appConfig: IApplicationConfig;
  public appMessages: IApplicationMessages;

  public prohibitedNameValues: string[] = [];

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  private m_container: ShiftsContainer;
  public parentShift: Shift = undefined;

  @ViewChild('kendoGrid', { static: false })
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;


  @ViewChild('pSettingsPopover', { static: false })
  private set settingPopover(value: PopoverContentComponent) {
    this.m_settingPopover = value;
  }

  private get settingPopover(): PopoverContentComponent {
    return this.m_settingPopover;
  }

  private m_settingPopover: PopoverContentComponent;


  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  @destroyService()
  private management: ShiftsManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private errorSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;
  @unsubscribe()
  private onLoadSubscription: Subscription;

  public defaultShift: any = [
    { id: 0, name: 'NO' },
    { id: 1, name: 'YES' }
  ];
  public isDefaultShiftEnabled: boolean;
  public isPartialShiftEnabled: boolean;
  public defaultPartialShiftCount: number;


  constructor(management: ShiftsManagementService, private modalService: ModalService, private stateManagement: StateManagementService, private appSettingManageService: AppSettingsManageService,) {
    this.management = management;
    this.gridState = new KendoGridStateHelper<Shift>("ShiftsComponent");
    this.gridState.preventCloseEditorOnSave = true;
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<Shift>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let shift: Shift = new Shift();
      let start: moment.Moment = moment().startOf('day');
      let end: moment.Moment = start.clone().add(1, 'hours');
      shift.start = start.toDate();
      shift.end = end.toDate();
      shift.name = Shift.createShiftName(shift.start, shift.end);
      shift.duration = 1;
      shift.lunchDuration = 0;
      shift.hasPartialShift = false;
      shift.shiftSetting = new ShiftSetting();
      this.crudHelper.addItem(shift);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
    this.getSettings();

  }

  /* ---------------------- Event Hanlders -----------*/
  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.appMessages = appMessages;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.stateManagement.init('ShiftsComponent');

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((shift: Shift) => {
      if (shift) {
        this.updateProhibitedNameValues(shift);
        if (this.mainForm) {
          this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
            if (this.mainForm.dirty) {
              this.management.markAsDirty();
            }
          });
        }
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }
    });

    this.savedSubscription = this.management.savedItemCmd$.subscribe((shift: Shift) => {
      this.parentShift = undefined;
      this.gridState.closeEditor(this.grid);
      this.gridState.refreshGrid();
    });

    this.errorSubscription = this.management.errorNotify$.subscribe((message: string) => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Error',
        message,
        this.modalService,
        (result: boolean) => {
          _.noop();
        }, options);
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: Shift[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: { dialogOptions: DialogOptions, itemToDelete: Shift }) => {
      if (request.dialogOptions) {

        let dialog: ShiftRemoveDialogComponent = this.modalService.globalAnchor.openDialog(ShiftRemoveDialogComponent, 'Choose shift to reassign employees', request.dialogOptions, null,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete, dialog.shift);
            }
          }
        );
        dialog.shifts = this.management.container.records.filter((obj: Shift, index: number, array: Shift[]) => {
          if (obj === request.itemToDelete || obj.id === 0)
            return false;
          return true;
        });

      } else if (request.itemToDelete.usedInIdealSchedule) {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Shift used in Ideal Schedule. Do you want to delete the shift?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete);
            }
          }, options);

      } else {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Do you want to delete the shift?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete);
            }
          }, options);
      }
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();
    this.state.isLoading = false;
    this.onLoadSubscription = this.management.onLoaded$.subscribe(() => {
      this.stateManagement.loadedData({});
      this.loadingPanelVisible = false;
    });
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }
  private getSettings() {
    let appServerConfig: Promise<AppServerConfig> = this.appSettingManageService.getAppServerConfig();
    appServerConfig.then((result: AppServerConfig) => {
      this.isDefaultShiftEnabled = result.isDefaultShiftEnabled;
      this.isPartialShiftEnabled = result.isPartialShiftEnabled;
      this.defaultPartialShiftCount = result.defaultPartialShiftCount;
    });
  }
  public onCopyItems(event: MouseEvent): void {
    event.preventDefault();
    this.management.openCopyItems();
  }

  public switchToConfigure(): void {
    this.management.closeCopyItems();
  }

  public updateProhibitedNameValues(shift: Shift): void {
    if (shift) {
      let values: string[] = [];
      _.each(this.management.container.records, (s: Shift) => {
        if (shift.id !== s.id) {
          values.push(s.name);
        }
      });
      this.prohibitedNameValues = values;
    }
  }

  public onShiftDateChanged(shift: Shift): void {
    let hours: number = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
    let duration: number = hours - shift.lunchDuration;
    if (duration < 0 || _.isNaN(shift.lunchDuration) && duration < shift.lunchDuration) {
      shift.duration = 0;
      shift.lunchDuration = duration;
      return;
    }

    if (shift.lunchDuration > 0) {
      shift.duration = hours - shift.lunchDuration;
    } else {
      shift.duration = hours;
    }

    if ((!shift.isNameEdited && this.management.isEditingNewItem) || (shift.parentShiftId > 0)) {
      shift.name = Shift.createShiftName(shift.start, shift.end);
    }
  }

  public shiftPaidTimeChanged(shift: Shift, value: number): void {
    if (_.isNaN(value)) {
      shift.duration = 0;
      return;
    }

    shift.duration = value > 0 ? value : 0;

    if (shift.start) {
      let totalHours: number = shift.duration + shift.lunchDuration;
      let milliseconds: number = totalHours * 1000 * 60 * 60;
      shift.end = new Date(milliseconds + shift.start.getTime());
    }

    if (!shift.isNameEdited && this.management.isEditingNewItem) {
      shift.name = Shift.createShiftName(shift.start, shift.end);
    }
  }

  public shiftUnpaidTimeChanged(shift: Shift, value: number): void {
    if (_.isNaN(value)) {
      shift.lunchDuration = 0;
      return;
    }

    let hours: number = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
    shift.lunchDuration = value > 0 ? value : 0;
    shift.duration = hours - shift.lunchDuration;

    if (shift.duration < 0) {
      shift.duration = 0;
    }
    if (shift.start) {
      let totalHours: number = shift.duration + shift.lunchDuration;
      let milliseconds: number = totalHours * 1000 * 60 * 60;
      shift.end = new Date(milliseconds + shift.start.getTime());
    }
  }

  public descriptionChanged(shift: Shift): void {
    shift.isNameEdited = true;
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: Shift, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    } else if (action === EditableListActionKind.START_EDIT) {
      this.crudHelper.startEdit(item, index);
    }
  }

  public onMobileEditorEvent(action: EditableListActionKind, item: Shift, index: number): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.completeEdit(item, index);
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.cancelEdit(index);
    }
  }

  public get minDateLimit(): Date {
    return this.parentShift ? this.parentShift.start : new Date();
  }

  public get maxDateLimit(): Date {
    return this.parentShift ? this.parentShift.end : new Date();
  }


  public defaultShiftChange(shift: Shift, index: number, e: any) {
    let defaultShift = _.filter(this.crudHelper.container.records, (i) => i.defaultshift == 1);
    let isShiftPresent = _.some(defaultShift, (i) => i.id == shift.id);
    if (defaultShift.length == 3 && isShiftPresent && e == 'NO') {
      InfoDialogComponent.OpenDialog(
        'Warning',
        'User not allowed to remove already configured Default Shift',
        this.modalService);
      this.crudHelper.cancelEdit(index);
      return;
    }
    if (defaultShift.length === 3 && !isShiftPresent && e == 'YES') {
      InfoDialogComponent.OpenDialog(
        'Warning',
        `Please note, these changes to the Default Shift will take affect on ${moment().format('MM/DD/YYYY')}`,
        this.modalService);
      return;
    }
  }

  public onAllowPartialShiftChanged(shift: Shift, index: number) {
    // Up on partial shift enable
    if (shift.hasPartialShift && shift.partialShiftList.length == 0) {
      shift.shiftSetting = new ShiftSetting();
      shift.shiftSetting.isUpdated = true;
      shift.shiftSetting.minPartialShiftCount = this.defaultPartialShiftCount;
      let setting = shift.shiftSetting;
      let remaining = shift.duration + shift.lunchDuration;
      let count = setting.minPartialShiftCount;

      if (remaining > setting.minPartialShiftCount) {
        let pDuration = _.round(remaining / count);
        let newstart = shift.start

        while (count > 0 && remaining >= pDuration) {
          let newPS = this.createPartialShift(shift, newstart, pDuration);
          newstart = newPS.end;
          --count;
          remaining = remaining - pDuration;
          pDuration = count == 1 ? remaining : pDuration;
        }
      }
      this.grid.expandRow(index);
      this.settingPopover.show();
    }
    if (!shift.hasPartialShift) {
      shift.partialShiftList.forEach((ps) => { if (ps.id > 0) { shift.deletedPartials.push(ps); } });
      //TODO: Create A dialog box before clearing.
      shift.partialShiftList.splice(0, shift.partialShiftList.length);
      //Reset ShiftSetting
      shift.shiftSetting = new ShiftSetting();
      this.grid.collapseRow(index);
      if (this.settingPopover && this.settingPopover.popoverDir) {
        this.settingPopover.popoverDir.toggle();
      }
    }
    this.parentShift = shift;
    this.management.editingItem = shift;
  }

  private originalPartialShiftList = undefined;

  onEdit($event: EditEvent) {
    this.parentShift = $event.dataItem;
    this.originalPartialShiftList = _.cloneDeep($event.dataItem.partialShiftList);
    $event.dataItem.isEditing = true;
    $event.sender.expandRow($event.rowIndex);
    this.gridState.editHandler($event);
  }

  onCancel($event: CancelEvent) {
    $event.dataItem.deletedPartials = [];
    $event.dataItem.partialShiftList = this.originalPartialShiftList;
    this.parentShift = undefined;
    this.originalPartialShiftList = undefined;
    this.grid.collapseRow($event.rowIndex);
    this.gridState.cancelHandler($event);
    $event.dataItem.isEditing = false;
  }


  public hasPartialDataOrNew(shift: Shift): boolean {
    return shift.hasPartialShift ? true : false;
  }


  pgAddHandler($event: AddEvent) {
    $event.dataItem = this.createPartialShift(this.parentShift, this.parentShift.start, 1);
    $event.sender.editRow($event.rowIndex);
  }

  pgRemoveHandler($event) {
    $event.dataItem.isDeleted = true;
    if ($event.dataItem.id > 0) {
      this.parentShift.deletedPartials.push($event.dataItem);
    }
    var index = this.parentShift.partialShiftList.findIndex(({ id }) => $event.dataItem.id === id);
    this.parentShift.partialShiftList.splice(index, 1);
    if (this.parentShift.partialShiftList.length == 0) { this.parentShift.hasPartialShift = false; }
  }

  pgCancelHandler($event) {
    $event.dataItem.isUpdated = false;
    $event.sender.closeRow($event.rowIndex);
  }

  pgEditHandler($event) {
    $event.sender.editRow($event.rowIndex);
  }

  pgSaveHanlder($event) {
    $event.dataItem.isUpdated = true;
    if (this.isPartialShiftOverlap()) {
      InfoDialogComponent.OpenDialog(
        'Error',
        'There is an overlapping on Start and End time between partial shifts.',
        this.modalService);
        this.pgCancelHandler($event);
      return;
    }
    if (this.isPartialShiftTotalOverflow()) {
      InfoDialogComponent.OpenDialog(
        'Error',
        'Total number of hours, accumulating partial shifts duration, is beyond parent shift total duration.',
        this.modalService);
        this.pgCancelHandler($event);
      return;
    }
    $event.sender.closeRow($event.rowIndex);
  }

  onSaveShiftSetting(shift: Shift, index: number) {
    shift.shiftSetting.isUpdated = true;
  }

  private async applyShiftSetting(shift: Shift) {
    if (shift && shift.id && !shift.shiftSetting.isUpdated) {
      await this.management.getShiftSetting(shift.id).then(setting => {
        if (!setting) {
          setting = new ShiftSetting();
          setting.shiftId = shift.id;
          setting.minPartialShiftCount = this.defaultPartialShiftCount;
        }
        if (setting.minPartialShiftCount == 0) {
          setting.minPartialShiftCount = this.defaultPartialShiftCount;
        }
        shift.shiftSetting = setting;
      });
    }
  }

  onShowShiftSetting(shift: Shift) {
    if (shift.hasPartialShift) {
      this.applyShiftSetting(shift);
    }
    else {
      this.settingPopover.hide();
    }
  }

  private createPartialShift(parent: Shift, newStart: Date, addDuration: number, lunchduration: number = 0): Shift {

    let ps: Shift = new Shift();
    ps.isUpdated = true;
    ps.start = newStart;
    let endMoment: moment.Moment = moment(newStart).clone().add(addDuration, 'hours');
    ps.end = endMoment.toDate();
    ps.name = Shift.createShiftName(ps.start, ps.end);
    ps.duration = addDuration;
    ps.lunchDuration = lunchduration;
    ps.hasPartialShift = false;
    ps.shiftSetting = new ShiftSetting();
    if (!isNullOrUndefined(parent)) {
      ps.group = parent.group;
      ps.parentShiftId = parent.id;
      parent.partialShiftList.push(ps);
    }
    return ps;
  }

  private isPartialShiftOverlap(): boolean {

    for (var i = 0; i < this.parentShift.partialShiftList.length; ++i) {
      var ps1 = this.parentShift.partialShiftList[i];
      for (var j = i + 1; j < this.parentShift.partialShiftList.length; ++j) {
        var ps2 = this.parentShift.partialShiftList[j];
        if ((ps1.start < ps2.start && ps2.start < ps1.end) || (ps1.start < ps2.end && ps2.end < ps1.end)) {
          return true;
        }
      }
    }
    return false;
  }

  private isPartialShiftTotalOverflow(): boolean {
    let total = 0;
    this.parentShift.partialShiftList.forEach((ps, i) => { total += ps.duration; })
    return total > this.parentShift.duration + this.parentShift.lunchDuration;
  }
}
