import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
;
import { BehaviorSubject, Observable } from 'rxjs';
import { PayCodeDetailsManagementService } from '../../services/pay-code-details/pay-code-details-management.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { ColorUtil } from '../../../common/utils/index';
import { DatePipe, Location } from '@angular/common';
import { appConfig } from '../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../common/index';
import { mutableSelect } from '../../../core/decorators/index';
import { DataService } from '../../services/pay-code-details/data.service';
import { take } from 'rxjs/operators';
var PayCodeDetailsComponent = /** @class */ (function () {
    function PayCodeDetailsComponent(stateManagement, management, columnManagementService, cdr, datePipe, location, dataService) {
        this.stateManagement = stateManagement;
        this.management = management;
        this.columnManagementService = columnManagementService;
        this.cdr = cdr;
        this.datePipe = datePipe;
        this.location = location;
        this.dataService = dataService;
        this.showConfirmationDialog = false;
        this.savedFormState = null;
        this.orgLevelId = null;
        this.container = new PayCodeDetailsContainer();
        this.allocationTypes = [];
        this.exceptionGroups = [];
        this.exceptionVariables = [];
        this.payDiffTypes = [];
        this.specialCodes = [];
        this.payCodeExceptions = [];
        this.workCodes = [];
        this.interfaceCategories = [];
        this.isImport = false;
        this.subscriptions = [];
        this.dataLoaded$ = new BehaviorSubject(false);
        this.columnGroup = 'PayCodeDetails';
    }
    PayCodeDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            exceptionsDirty: false,
            additionalRequirementsDirty: false,
            rulesDirty: false
        };
        this.subscriptions.push(this.dataLoaded$.subscribe(function (loaded) {
            if (loaded) {
                _this.sanitizeData();
                _this.populateDropdownData();
                _this.state.isLoading = false;
                _this.cdr.detectChanges();
            }
        }));
        this.subscriptions.push(this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgId = orgLevel.organizationId;
        }));
        this.bindPageLoadData();
        this.management.init();
        this.container = this.management.container;
        this.state.isLoading = true;
        this.subscriptions.push(this.dataService.data$.pipe(take(1)).subscribe(function (combinedData) {
            if (combinedData) {
                _this.jsonData = combinedData.data;
                _this.isImport = combinedData.isImport;
                // If not importing data, proceed to load data
                if (!_this.isImport) {
                    _this.loadData();
                }
            }
            else {
                // No data being imported, proceed to load data
                _this.loadData();
            }
        }));
        this.dataService.data$.subscribe(function (data) {
            if (data && data.orgLevelId !== undefined) {
                _this.orgLevelId = data.orgLevelId;
                localStorage.setItem('orgLevelId', _this.orgLevelId.toString());
            }
            else {
                var storedOrgLevelId = localStorage.getItem('orgLevelId');
                if (storedOrgLevelId !== null) {
                    _this.orgLevelId = +storedOrgLevelId;
                }
            }
        });
    };
    PayCodeDetailsComponent.prototype.ngAfterViewInit = function () {
        if (this.jsonData !== null && this.isImport) {
            this.state.isLoading = true; // Start loading state
            this.cdr.detectChanges(); // Ensure UI updates to show spinner
            this.importData();
        }
    };
    PayCodeDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    PayCodeDetailsComponent.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this.fetchAndSetData()];
                    case 1:
                        _a.sent();
                        if (this.container.exceptions.some(function (exception) { return exception.deleteInd; })) {
                            this.location.back();
                            return [2 /*return*/];
                        }
                        this.dataLoaded$.next(true);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error loading data:', error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.fetchAndSetData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.cdr.detectChanges();
                        if (!(this.container && this.container.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchDataAndPopulate(this.container.id)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = console.error('Container ID is not set.');
                        _b.label = 3;
                    case 3:
                        _a;
                        this.cdr.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.fetchDataAndPopulate = function (containerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all([
                                this.management.fetchPaycodeExceptions(containerId),
                                this.management.fetchAdditionalRequirements(containerId),
                                this.management.fetchRulesByExceptionId(containerId),
                            ])];
                    case 1:
                        _a.sent();
                        this.populateDropdownData();
                        // Ensure additionalRequirements array is initialized for new pay codes
                        if (!this.container.additionalRequirements || this.container.additionalRequirements.length === 0) {
                            this.container.additionalRequirements = [this.newAdditionalRequirement()];
                        }
                        this.setRuleFormulasValueType();
                        this.sanitizeData();
                        this.dataLoaded$.next(true);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error fetching data:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.populateDropdownData = function () {
        if (this.container.exceptions && this.container.exceptions.length > 0) {
            var exception = this.container.exceptions[0];
            this.allocationTypes = exception.allocationTypes || [];
            this.exceptionGroups = exception.exceptionGroups || [];
            this.exceptionVariables = exception.exceptionVariables || [];
            this.payDiffTypes = exception.payDiffTypes || [];
            this.specialCodes = exception.specialCodes || [];
            this.payCodeExceptions = exception.payCodeExceptions || [];
            this.workCodes = exception.workCodes || [];
            this.interfaceCategories = exception.interfaceCategories || [];
            exception.exInterfaceCategoryId = this.adjustInterfaceCategoryIdForDisplay(exception.exInterfaceCategoryId);
            this.parseAndFormatAdditionalRequirements();
            this.determineDefaultOption();
        }
    };
    PayCodeDetailsComponent.prototype.sanitizeData = function () {
        console.log('Sanitizing data...', this.container.exceptions);
        if (this.container.exceptions && this.container.exceptions.length > 0) {
            // Ensure the container exceptions aren't reset
            this.container.exceptions = this.container.exceptions.map(this.mergeWithDefaults.bind(this));
            // Sanitize rules and additional requirements
            this.container.rules = this.sanitizeArray(this.container.rules);
            this.container.additionalRequirements = this.sanitizeArray(this.container.additionalRequirements);
        }
        console.log('Sanitized exceptions:', this.container.exceptions); // Log state after sanitizing
    };
    PayCodeDetailsComponent.prototype.mergeWithDefaults = function (exception) {
        var defaults = this.newPaycodeException();
        // Merge defaults with exception ensuring undefined values in exception are replaced by defaults
        Object.keys(defaults).forEach(function (key) {
            if (exception[key] === undefined) {
                exception[key] = defaults[key];
            }
        });
        return exception;
    };
    PayCodeDetailsComponent.prototype.sanitizeArray = function (array) {
        return array.map(function (item) {
            Object.keys(item).forEach(function (key) {
                if (item[key] === null || item[key] === undefined) {
                    item[key] = '';
                }
            });
            return item;
        });
    };
    PayCodeDetailsComponent.prototype.formatDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    PayCodeDetailsComponent.prototype.determineDefaultOption = function () {
        if (this.container.exceptions[0].exActionStart || this.container.exceptions[0].exActionEnd) {
            this.selectedOption = 'Action';
        }
        else if (this.container.exceptions[0].exActionStartVar || this.container.exceptions[0].exActionInterval) {
            this.selectedOption = 'Interval';
        }
        else if (this.container.exceptions[0].exFormulaStart || this.container.exceptions[0].exFormulaEnd) {
            this.selectedOption = 'Formula';
        }
        else {
            this.selectedOption = 'Action';
        }
        this.updateDisplayedFields();
    };
    PayCodeDetailsComponent.prototype.parseOverride = function (value) {
        if (typeof value === 'string') {
            // Try to match the specific pattern first
            var match = value.match(/OT (\d+)/);
            if (match) {
                return +match[1];
            }
            // Try to convert directly if no match
            var intValue = parseInt(value, 10);
            return isNaN(intValue) ? null : intValue;
        }
        return typeof value === 'number' ? value : null;
    };
    PayCodeDetailsComponent.prototype.parseAndFormatAdditionalRequirements = function () {
        var _this = this;
        this.container.additionalRequirements.forEach(function (req) {
            req.regularOverrideTemp = _this.parseOverride(req.regularOverride);
            req.otOverrideTemp = _this.parseOverride(req.otOverride);
            req.holidayOverrideTemp = _this.parseOverride(req.holidayOverride);
        });
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.updateDisplayedFields = function () {
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isSaveDisabled()) {
                            return [2 /*return*/];
                        }
                        this.state.isLoading = true;
                        this.sanitizeData();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        if (!this.state.exceptionsDirty) return [3 /*break*/, 3];
                        // Save pay code exceptions first
                        return [4 /*yield*/, this.savePaycodeExceptions()];
                    case 2:
                        // Save pay code exceptions first
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!this.state.additionalRequirementsDirty) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.saveAdditionalRequirements()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        if (!this.state.rulesDirty) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.saveRules()];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        this.state.isLoading = false;
                        this.state.exceptionsDirty = false;
                        this.state.additionalRequirementsDirty = false;
                        this.state.rulesDirty = false;
                        this.isImport = false;
                        this.cdr.detectChanges();
                        return [3 /*break*/, 9];
                    case 8:
                        error_3 = _a.sent();
                        console.error('Error saving changes:', error_3);
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.savePaycodeExceptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, newExceptionDescription, currentExceptionId, savePromises, error_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.exceptions.map(function (exception) { return (tslib_1.__assign({}, exception)); });
                        this.state.isLoading = true;
                        newExceptionDescription = updatedData[0].exceptionDescription.trim().toLowerCase();
                        currentExceptionId = updatedData[0].id;
                        // Check if the exception description already exists (case-insensitive)
                        if (this.payCodeExceptions.some(function (existingException) {
                            return existingException &&
                                existingException.description &&
                                existingException.description.trim().toLowerCase() == newExceptionDescription &&
                                existingException.id != currentExceptionId;
                        })) {
                            window.alert("Pay code description is already used.");
                            this.state.isLoading = false;
                            throw new Error('Duplicate exception description');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        savePromises = updatedData.map(function (dataItem) { return _this.management.savePaycodeException(dataItem); });
                        return [4 /*yield*/, Promise.all(savePromises)];
                    case 2:
                        _a.sent();
                        this.state.exceptionsDirty = false;
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        console.error('Error saving paycode exceptions:', error_4);
                        throw error_4; // Re-throw the error to propagate it to the calling method
                    case 4:
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.saveAdditionalRequirements = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, savePromises, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.additionalRequirements;
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        savePromises = updatedData.map(function (dataItem) { return _this.management.saveAdditionalRequirement(_this.container.id, dataItem); });
                        return [4 /*yield*/, Promise.all(savePromises)];
                    case 2:
                        _a.sent();
                        this.state.additionalRequirementsDirty = false;
                        return [3 /*break*/, 5];
                    case 3:
                        error_5 = _a.sent();
                        console.error('Error saving additional requirements:', error_5);
                        return [3 /*break*/, 5];
                    case 4:
                        this.parseAndFormatAdditionalRequirements();
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.saveRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, savePromises, _loop_1, this_1, _i, updatedData_1, rule, state_1, error_6;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.rules.map(function (rule) { return (tslib_1.__assign({}, rule, { whereClause: _this.generateWhereClause(rule), startDate: rule.startDate ? _this.formatDate(rule.startDate) : undefined, endDate: rule.endDate ? _this.formatDate(rule.endDate) : undefined })); });
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, 9, 10]);
                        savePromises = [];
                        _loop_1 = function (rule) {
                            var isValid;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!rule.whereClause) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.management.validateRule(rule.whereClause)];
                                    case 1:
                                        isValid = _a.sent();
                                        if (!isValid) {
                                            this_1.state.isLoading = false;
                                            this_1.cdr.detectChanges();
                                            return [2 /*return*/, { value: void 0 }];
                                        }
                                        _a.label = 2;
                                    case 2:
                                        if (rule.id && rule.id > 0) {
                                            savePromises.push(this_1.management.saveRule(rule).then(function (savedRule) {
                                                rule.isNew = false; // Reset the isNew flag after saving
                                                return savedRule;
                                            }));
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, updatedData_1 = updatedData;
                        _a.label = 2;
                    case 2:
                        if (!(_i < updatedData_1.length)) return [3 /*break*/, 5];
                        rule = updatedData_1[_i];
                        return [5 /*yield**/, _loop_1(rule)];
                    case 3:
                        state_1 = _a.sent();
                        if (typeof state_1 === "object")
                            return [2 /*return*/, state_1.value];
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [4 /*yield*/, Promise.all(savePromises)];
                    case 6:
                        _a.sent();
                        // Re-fetch the rules to ensure the state is up-to-date
                        return [4 /*yield*/, this.management.fetchRulesByExceptionId(this.container.id)];
                    case 7:
                        // Re-fetch the rules to ensure the state is up-to-date
                        _a.sent();
                        this.setRuleFormulasValueType();
                        this.state.rulesDirty = false;
                        return [3 /*break*/, 10];
                    case 8:
                        error_6 = _a.sent();
                        console.error('Error saving rules:', error_6);
                        return [3 /*break*/, 10];
                    case 9:
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.bindPageLoadData = function () {
        this.initServices();
        this.appConfig = appConfig;
    };
    PayCodeDetailsComponent.prototype.initServices = function () {
        this.stateManagement.init('PayCodeDetailsComponent');
        this.columnManagementService.init('PayCodeDetailsComponent');
        this.columnManagementService.initGroup(this.columnGroup, 12);
    };
    PayCodeDetailsComponent.prototype.markExceptionsDirty = function () {
        this.state.exceptionsDirty = true;
    };
    PayCodeDetailsComponent.prototype.markAdditionalRequirementsDirty = function () {
        this.state.additionalRequirementsDirty = true;
    };
    PayCodeDetailsComponent.prototype.markRulesDirty = function () {
        this.state.rulesDirty = true;
    };
    PayCodeDetailsComponent.prototype.isSaveDisabled = function () {
        var formValid = this.templateForm ? this.templateForm.valid : false;
        var dropdownsValid = this.container.exceptions.every(function (exception) {
            return exception.exAllocationType && exception.exPayDiffType;
        });
        return !(formValid &&
            dropdownsValid &&
            (this.state.exceptionsDirty || this.state.additionalRequirementsDirty || this.state.rulesDirty));
    };
    PayCodeDetailsComponent.prototype.onExActionStartVarChange = function (value) {
        this.container.exceptions[0].exActionStartVar = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onGroupIdChange = function (value) {
        this.container.exceptions[0].groupId = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExPayDiffTypeChange = function (value) {
        this.container.exceptions[0].exPayDiffType = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExAllocationTypeChange = function (value) {
        this.container.exceptions[0].exAllocationType = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExWorkStatusChange = function (value) {
        this.container.exceptions[0].exWorkStatus = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.intToHexColor = function (value) {
        return ColorUtil.DecToHexString(value, true);
    };
    PayCodeDetailsComponent.prototype.onColorChange = function (event) {
        var colorValue = parseInt(event.target.value.replace('#', ''), 16);
        this.container.exceptions[0].screenColor = colorValue;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onActionVariableChange = function (value, field) {
        this.container.exceptions[0][field] = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onOverrideChange = function (field, value) {
        this.container.additionalRequirements[0][field + 'Temp'] = value;
        this.container.additionalRequirements[0][field] = "OT " + value;
        this.markAdditionalRequirementsDirty();
    };
    PayCodeDetailsComponent.prototype.cancel = function () {
        if (this.isSaveDisabled()) {
            this.cancelChanges();
        }
        else {
            this.showConfirmationDialog = true;
        }
    };
    PayCodeDetailsComponent.prototype.isFormDirty = function () {
        return this.state.exceptionsDirty ||
            this.state.additionalRequirementsDirty ||
            this.state.rulesDirty;
    };
    PayCodeDetailsComponent.prototype.onConfirm = function () {
        this.showConfirmationDialog = false;
        this.cancelChanges();
    };
    PayCodeDetailsComponent.prototype.onDialogCancel = function () {
        this.showConfirmationDialog = false;
    };
    PayCodeDetailsComponent.prototype.cancelChanges = function () {
        this.location.back();
    };
    PayCodeDetailsComponent.prototype.deletePaycodeException = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.management.deletePaycodeException(this.container.id)];
                    case 2:
                        _a.sent();
                        this.location.back();
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        console.error('Error deleting paycode exception:', error_7);
                        this.state.isLoading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.addRule = function () {
        this.container.rules.push(this.newRule());
        this.markRulesDirty();
    };
    PayCodeDetailsComponent.prototype.removeRule = function (index) {
        var _this = this;
        var rule = this.container.rules[index];
        if (rule.isNew) {
            this.container.rules.splice(index, 1);
            this.cdr.detectChanges();
        }
        else if (rule.id && rule.id > 0) {
            this.management.deleteRule(rule.id).then(function () {
                _this.setRuleFormulasValueType();
                _this.cdr.detectChanges();
            }).catch(function (error) {
                console.error('Error deleting rule:', error);
            });
        }
    };
    PayCodeDetailsComponent.prototype.addRuleFormula = function (rule) {
        var newFormula = {
            id: Math.floor(Math.random() * 9000000) + 1000000,
            ruleId: rule.id,
            variableId1: '',
            operator: '=',
            variableId2: '',
            isNew: true,
            valueType1: 'value',
            valueType2: 'value'
        };
        rule.ruleFormulas.push(newFormula);
        this.markRulesDirty();
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.removeRuleFormula = function (rule, formulaIndex) {
        var _this = this;
        var formula = rule.ruleFormulas[formulaIndex];
        if (formula.isNew) {
            rule.ruleFormulas.splice(formulaIndex, 1);
            this.cdr.detectChanges();
        }
        else if (formula.id && formula.id > 0) {
            this.management.deleteRuleFormula(rule.id, formula.id).then(function () {
                rule.ruleFormulas.splice(formulaIndex, 1);
                _this.cdr.detectChanges();
            }).catch(function (error) {
                console.error('Error deleting rule formula:', error);
            });
        }
    };
    PayCodeDetailsComponent.prototype.setRuleFormulasValueType = function () {
        var _this = this;
        if (!this.container.rules) {
            return;
        }
        this.container.rules.forEach(function (rule) {
            rule.ruleFormulas.forEach(function (formula) {
                if (formula.variableId2 != null && formula.variableId2 != undefined) {
                    if (formula.variableId2.includes('@')) {
                        formula.valueType2 = 'formula';
                    }
                    else if (_this.exceptionVariables.some(function (variable) { return variable.description === formula.variableId2; })) {
                        formula.valueType2 = 'variable';
                    }
                    else {
                        formula.valueType2 = 'value';
                    }
                }
                if (formula.variableId1 != null && formula.variableId1 != undefined) {
                    if (formula.variableId1.includes('@')) {
                        formula.valueType1 = 'formula';
                    }
                    else if (_this.exceptionVariables.some(function (variable) { return variable.description === formula.variableId1; })) {
                        formula.valueType1 = 'variable';
                    }
                    else {
                        formula.valueType1 = 'value';
                    }
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.generateWhereClause = function (rule) {
        var _this = this;
        var clauses = rule.ruleFormulas.map(function (formula) {
            var operator = formula.operator;
            var variable1;
            var variable2;
            if (formula.valueType1 === 'variable') {
                variable1 = _this.getFieldProperty(formula.variableId1);
            }
            else if (formula.valueType1 === 'formula') {
                variable1 = formula.variableId1.startsWith('@') ? formula.variableId1.slice(1) : formula.variableId1;
            }
            else {
                variable1 = formula.variableId1;
            }
            if (formula.valueType2 === 'variable') {
                variable2 = _this.getFieldProperty(formula.variableId2);
            }
            else if (formula.valueType2 === 'formula') {
                variable2 = formula.variableId2.startsWith('@') ? formula.variableId2.slice(1) : formula.variableId2;
            }
            else {
                variable2 = formula.variableId2;
            }
            // Check if dataType is 'char' and operator is '=' to wrap variable1 in single quotes
            var variable23 = _this.exceptionVariables.find(function (v) { return v.description === formula.variableId1; });
            if (variable23 && variable23.dataType === 'char' && operator === '=' && !variable1.startsWith("'") && !variable1.endsWith("'")) {
                variable1 = "'" + variable1 + "'";
            }
            // Check if dataType is 'char' and operator is '=' to wrap variable2 in single quotes
            var variable = _this.exceptionVariables.find(function (v) { return v.description === formula.variableId2; });
            if (variable && variable.dataType === 'char' && operator === '=' && !variable2.startsWith("'") && !variable2.endsWith("'")) {
                variable2 = "'" + variable2 + "'";
            }
            return variable1 + " " + operator + " " + variable2;
        });
        console.log('Generated WHERE Clause:', "WHERE " + clauses.join(' AND '));
        return "WHERE " + clauses.join(' AND ');
    };
    PayCodeDetailsComponent.prototype.getFieldProperty = function (variableDescription) {
        var variable = this.exceptionVariables.find(function (v) { return v.description === variableDescription; });
        return variable ? variable.field : '';
    };
    PayCodeDetailsComponent.prototype.adjustInterfaceCategoryIdForDisplay = function (id) {
        return (Number)(id) + 1;
    };
    PayCodeDetailsComponent.prototype.newPaycodeException = function () {
        return {
            id: this.container.id,
            exceptionDescription: '',
            screenColor: 0,
            accrualInd: false,
            paidInd: false,
            payDiffPct: 0,
            deleteInd: false,
            discrepancyInd: false,
            exAllocationType: 0,
            exInterface: '',
            exWorkStatus: '',
            exInterfaceCategoryId: 0,
            exFormulaStart: '',
            exFormulaEnd: '',
            exAllowMultiple: false,
            exCountTowardsOvertimeInd: false,
            exSortOrder: 0,
            exMinimumInterval: 0,
            exMaximumAppliedInterval: 0,
            exMinimumAppliedInterval: 0,
            exShiftDiffCategory: '',
            exAddlVariable: '',
            ex2ndPayRateExceptionId: 0,
            exSecondaryJobCode: 0,
            exApplyToWeekday: false,
            exApplyShiftDiff: false,
            groupId: 0,
            exAction: '',
            exActionStart: '',
            exActionEnd: '',
            exActionStartVar: 0,
            exActionInterval: 0,
            exActionMessage: '',
            exDepartmentId: 0,
            exPayDiffType: 0,
            exShowInExceptionGrid: false,
            exAmount: 0,
            exDisplayGroup: 0,
            exOrganizationId: this.orgId,
            exUseInTimesheets: false,
            workedInd: false,
            acaInd: false,
            exceptionColumnGroup: '',
            isException: false
        };
    };
    PayCodeDetailsComponent.prototype.newAdditionalRequirement = function () {
        return {
            ignoreBreak: false,
            calculateNetWorkTime: false,
            useContiguousTime: false,
            stayWithinExceptionTimeframe: false,
            exportAsDollarAmount: false,
            paidAtHolidayRate: false,
            regularOverride: '',
            otOverride: '',
            holidayOverride: '',
            overtimeHolidayOverride: '',
            otherCodeOverride: '',
            recalculate: '',
            requirementIds: {}
        };
    };
    PayCodeDetailsComponent.prototype.newRule = function () {
        return {
            id: Math.floor(Math.random() * 9000000) + 1000000,
            ruleDescription: '',
            startDate: new Date(),
            endDate: undefined,
            period: 0,
            organizationId: this.orgId,
            departmentId: 0,
            jobCode: 0,
            exceptionId: this.container.id,
            whereClause: '',
            ruleFormulas: [],
            isNew: true
        };
    };
    PayCodeDetailsComponent.prototype.importData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parsedData, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, 6, 7]);
                        console.log('Starting import...'); // Log when import starts
                        parsedData = JSON.parse(this.jsonData);
                        if (!parsedData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.management.fetchPaycodeExceptions(this.container.id)];
                    case 1:
                        _a.sent();
                        this.populateDropdownData();
                        return [4 /*yield*/, this.populateAddPayCodeFormData(parsedData, this.isImport)];
                    case 2:
                        _a.sent();
                        this.parseAndFormatAdditionalRequirements();
                        this.determineDefaultOption();
                        this.sanitizeData();
                        return [3 /*break*/, 4];
                    case 3:
                        console.error('Failed to parse JSON data');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        error_8 = _a.sent();
                        console.error('Error during data import:', error_8);
                        return [3 /*break*/, 7];
                    case 6:
                        this.state.isLoading = false; // End loading state
                        this.cdr.detectChanges(); // Update UI to hide spinner
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.populateAddPayCodeFormData = function (data, isImport) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var originalDescription_1, suffixCounter_1, normalizedIncomingException_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isImport) return [3 /*break*/, 2];
                        // Populate exceptions
                        this.container.exceptions = [data.exceptions];
                        this.container.exceptions[0].id = this.container.id;
                        this.container.exceptions[0].exOrganizationId = this.orgId;
                        originalDescription_1 = this.container.exceptions[0].exceptionDescription || '';
                        suffixCounter_1 = 1;
                        normalizedIncomingException_1 = originalDescription_1.trim().toLowerCase();
                        this.payCodeExceptions.forEach(function (item) {
                            var normalizedException = item.description.trim().toLowerCase();
                            if (normalizedException === normalizedIncomingException_1 ||
                                normalizedException.startsWith(normalizedIncomingException_1 + "_")) {
                                var newException_1 = originalDescription_1.trim() + "_" + suffixCounter_1;
                                while (_this.payCodeExceptions.some(function (c) { return c.description.trim().toLowerCase() === newException_1.trim().toLowerCase(); })) {
                                    suffixCounter_1++;
                                    newException_1 = originalDescription_1.trim() + "_" + suffixCounter_1;
                                }
                                _this.container.exceptions[0].exceptionDescription = newException_1;
                            }
                        });
                        // Populate additional requirements
                        this.container.additionalRequirements = data.additionalRequirements;
                        // Populate rules
                        return [4 /*yield*/, this.populateRules(data.rules)];
                    case 1:
                        // Populate rules
                        _a.sent();
                        // Mark data as dirty
                        this.state.exceptionsDirty = true;
                        this.state.additionalRequirementsDirty = true;
                        this.state.rulesDirty = true;
                        // Update records and reflect the final state
                        this.container.updateRecords();
                        this.cdr.detectChanges();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.populateRules = function (rulesData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, rulesData_1, ruleData, newRuleId, newRule, _a, _b, formulaData, newFormula;
            return tslib_1.__generator(this, function (_c) {
                this.container.rules = []; // Initialize rules array
                for (_i = 0, rulesData_1 = rulesData; _i < rulesData_1.length; _i++) {
                    ruleData = rulesData_1[_i];
                    newRuleId = Math.floor(Math.random() * 9000000) + 1000000;
                    newRule = tslib_1.__assign({}, ruleData, { id: newRuleId, exceptionId: this.container.id, organizationId: this.orgId, ruleFormulas: [] });
                    // Populate rule formulas
                    for (_a = 0, _b = ruleData.ruleFormulas; _a < _b.length; _a++) {
                        formulaData = _b[_a];
                        newFormula = tslib_1.__assign({}, formulaData, { id: Math.floor(Math.random() * 9000000) + 1000000, ruleId: newRuleId });
                        newRule.ruleFormulas.push(newFormula);
                    }
                    this.container.rules.push(newRule);
                }
                return [2 /*return*/];
            });
        });
    };
    PayCodeDetailsComponent.prototype.exportData = function () {
        var _this = this;
        var exceptionsData = this.container.exceptions[0];
        var rulesData = this.container.rules;
        var additionalRequirementsData = this.container.additionalRequirements;
        var propertiesToRemove = [
            'allocationTypes', 'exceptionGroups', 'exceptionVariables',
            'payDiffTypes', 'specialCodes', 'payCodeExceptions', 'workCodes',
            'interfaceCategories'
        ];
        propertiesToRemove.forEach(function (property) {
            if (exceptionsData.hasOwnProperty(property)) {
                delete exceptionsData[property];
            }
        });
        var combinedData = {
            exceptions: exceptionsData,
            rules: rulesData,
            additionalRequirements: additionalRequirementsData
        };
        var jsonData = JSON.stringify(combinedData, null, 2);
        this.generateChecksum(jsonData).then(function (checksum) {
            combinedData['checksum'] = checksum;
            var exportJson = JSON.stringify(combinedData, null, 2);
            var now = new Date();
            var year = now.getFullYear();
            var month = String(now.getMonth() + 1).padStart(2, '0');
            var day = String(now.getDate()).padStart(2, '0');
            var hours = String(now.getHours()).padStart(2, '0');
            var minutes = String(now.getMinutes()).padStart(2, '0');
            var seconds = String(now.getSeconds()).padStart(2, '0');
            var timestamp = "" + year + month + day + "_" + hours + minutes + seconds;
            var fileName = "export-data_" + timestamp + ".json";
            _this.downloadJson(exportJson, fileName);
        });
    };
    PayCodeDetailsComponent.prototype.generateChecksum = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encoder, dataBuffer, hashBuffer, checksum;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        encoder = new TextEncoder();
                        dataBuffer = encoder.encode(data);
                        return [4 /*yield*/, window.crypto.subtle.digest('SHA-256', dataBuffer)];
                    case 1:
                        hashBuffer = _a.sent();
                        checksum = Array.from(new Uint8Array(hashBuffer))
                            .map(function (b) { return b.toString(16).padStart(2, '0'); })
                            .join('');
                        return [2 /*return*/, checksum];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.downloadJson = function (jsonData, fileName) {
        var blob = new Blob([jsonData], { type: 'application/json' });
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
    };
    PayCodeDetailsComponent.prototype.clearForm = function () {
        if (this.savedFormState) {
            this.restoreFormState();
        }
        else {
            this.templateForm.reset();
        }
    };
    PayCodeDetailsComponent.prototype.restoreFormState = function () {
        if (this.templateForm && this.savedFormState) {
            this.templateForm.resetForm(this.savedFormState);
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeDetailsComponent.prototype, "orgLevel$", void 0);
    return PayCodeDetailsComponent;
}());
export { PayCodeDetailsComponent };
