/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/components/breadcrumb/breadcrumb.component.ngfactory";
import * as i3 from "../../../core/components/breadcrumb/breadcrumb.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../core/services/breadcrumb/breadcrumb-state.service";
import * as i6 from "../../../app-settings/services/app-settings-manage.service";
import * as i7 from "../header-nav-time-element/header-nav-time-element.component.ngfactory";
import * as i8 from "../header-nav-time-element/header-nav-time-element.component";
import * as i9 from "../../../organization/services/time-clock/time-clock-data.service";
import * as i10 from "../../../organization/services/application-state-bus/application-state-bus.service";
import * as i11 from "../../../organization/components/org-level-breadcrumb/org-level-breadcrumb.component.ngfactory";
import * as i12 from "../../../organization/components/org-level-breadcrumb/org-level-breadcrumb.component";
import * as i13 from "../../../organization/services/org-level/org-level-watch.service";
import * as i14 from "./header-nav.component";
var styles_HeaderNavComponent = [i0.styles];
var RenderType_HeaderNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderNavComponent, data: {} });
export { RenderType_HeaderNavComponent as RenderType_HeaderNavComponent };
export function View_HeaderNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "slx-breadcrumb-toolbar-indents header-nav-first-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "left-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-breadcrumb", [], null, null, null, i2.View_BreadcrumbComponent_0, i2.RenderType_BreadcrumbComponent)), i1.ɵdid(3, 245760, null, 0, i3.BreadcrumbComponent, [i4.Router, i4.ActivatedRoute, i5.BreadcrumbStateService, i6.AppSettingsManageService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "right-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-header-nav-time-element", [], null, null, null, i7.View_HeaderNavTimeElementComponent_0, i7.RenderType_HeaderNavTimeElementComponent)), i1.ɵdid(6, 245760, null, 0, i8.HeaderNavTimeElementComponent, [i9.TimeclockDataService, i10.ApplicationStateBusService, i6.AppSettingsManageService], { showTimeclocksCount: [0, "showTimeclocksCount"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "org-breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "slx-org-level-breadcrumb", [], null, [["document", "keyup"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).pressEscape($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).clickOutside($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_OrgLevelBreadcrumbComponent_0, i11.RenderType_OrgLevelBreadcrumbComponent)), i1.ɵdid(9, 245760, null, 0, i12.OrgLevelBreadcrumbComponent, [i13.OrgLevelWatchService, i10.ApplicationStateBusService, i1.ElementRef], { mode: [0, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.appConfig.headerTimeclocksCount; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.orgLevelBreadcrumbsHeaderMode; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_HeaderNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-nav", [], null, null, null, View_HeaderNavComponent_0, RenderType_HeaderNavComponent)), i1.ɵdid(1, 114688, null, 0, i14.HeaderNavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderNavComponentNgFactory = i1.ɵccf("slx-header-nav", i14.HeaderNavComponent, View_HeaderNavComponent_Host_0, { hideLeftSideBar: "hideLeftSideBar", hideOrgLevelBreadcrumb: "hideOrgLevelBreadcrumb" }, {}, []);
export { HeaderNavComponentNgFactory as HeaderNavComponentNgFactory };
