/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-timezones-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header-timezones-list.component";
import * as i4 from "../../../organization/services/application-state-bus/application-state-bus.service";
var styles_HeaderTimezonesListComponent = [i0.styles];
var RenderType_HeaderTimezonesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderTimezonesListComponent, data: {} });
export { RenderType_HeaderTimezonesListComponent as RenderType_HeaderTimezonesListComponent };
function View_HeaderTimezonesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "icon fal fa-clock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "details-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "org-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "org-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.organizationTimes[_v.context.$implicit.id]; _ck(_v, 7, 0, currVal_1); }); }
export function View_HeaderTimezonesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "header-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "header-list-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "header-list-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderTimezonesListComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "header-list-arrow"], ["x-arrow", ""]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organizations; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_HeaderTimezonesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-timezones-list", [], null, null, null, View_HeaderTimezonesListComponent_0, RenderType_HeaderTimezonesListComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderTimezonesListComponent, [i4.ApplicationStateBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderTimezonesListComponentNgFactory = i1.ɵccf("slx-header-timezones-list", i3.HeaderTimezonesListComponent, View_HeaderTimezonesListComponent_Host_0, {}, {}, []);
export { HeaderTimezonesListComponentNgFactory as HeaderTimezonesListComponentNgFactory };
