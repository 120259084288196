import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ApplicationStateBusService } from '../../../organization';
import { OrgLevelType } from '../../../state-model/models/index';
import { unsubscribe } from '../../../core/decorators/index';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
var HeaderTimezonesListComponent = /** @class */ (function () {
    function HeaderTimezonesListComponent(stateBus) {
        this.stateBus = stateBus;
        this.organizationTimes = {};
    }
    HeaderTimezonesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateBusSubscription = this.stateBus.orgLevelsLoaded$.subscribe(function (orgLevelTree) {
            _this.organizations = _this.getOrganizationsFromTree(orgLevelTree);
            _this.timeSubscription = Observable.timer(1000, 1000).subscribe(function () {
                _this.updateTime();
            });
        });
    };
    HeaderTimezonesListComponent.prototype.getOrganizationsFromTree = function (orgLevelTree) {
        var _this = this;
        var organizations = [];
        orgLevelTree.forEach(function (orgLevel) {
            organizations = organizations.concat(_this.getAllOrganizations(orgLevel));
        });
        organizations.forEach(function (organization) {
            _this.organizationTimes[organization.id] = _this.getTimeForTimezone(organization.location.timeZone);
        });
        return organizations;
    };
    HeaderTimezonesListComponent.prototype.getAllOrganizations = function (orgLevel) {
        var _this = this;
        var organizations = [];
        if (orgLevel.type === OrgLevelType.organization) {
            organizations.push(orgLevel);
        }
        if (orgLevel.childs && Array.isArray(orgLevel.childs) && orgLevel.childs.length > 0) {
            orgLevel.childs.forEach(function (child) {
                organizations = organizations.concat(_this.getAllOrganizations(child));
            });
        }
        return organizations;
    };
    HeaderTimezonesListComponent.prototype.updateTime = function () {
        var _this = this;
        this.organizations.forEach(function (organization) {
            _this.organizationTimes[organization.id] = _this.getTimeForTimezone(organization.location.timeZone);
        });
    };
    HeaderTimezonesListComponent.prototype.getTimeForTimezone = function (timezone) {
        if (timezone != null) {
            return moment.tz(timezone).format("hh:mm A") + " " + moment.tz(timezone).format("zz");
        }
        else {
            return moment.tz("America/Chicago").format("hh:mm A") + " " + moment.tz("America/Chicago").format("zz");
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderTimezonesListComponent.prototype, "timeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderTimezonesListComponent.prototype, "stateBusSubscription", void 0);
    return HeaderTimezonesListComponent;
}());
export { HeaderTimezonesListComponent };
