import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { OrgLevel } from '../../../state-model/models/index';
import { ModalAnchorDirective } from '../../../common/directives/index';
import { appConfig } from '../../../app.config';
import { MasterScheduleColumnsSettings, MasterScheduleTotalsSettings, MasterScheduleSettings, MasterScheduleDisplaySettings, MasterScheduleFilters, MasterScheduleLookupFilters, MasterScheduleGroupingTypes, IMasterScheduleGroupingType, IMasterScheduleCell } from '../../models/index';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import { mutableSelect, destroyService, unsubscribe } from '../../../core/decorators/index';
import { ColumnSettingsStorageService, StateManagementService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';
import { MasterScheduleGridComponent } from './master-schedule-grid/master-schedule-grid.component';
import { MasterScheduleManagementService, MasterScheduleToolbarService } from '../../services/index';
var MasterScheduleComponent = /** @class */ (function () {
    function MasterScheduleComponent(managementService, stateManagementService, columnSettingsStorageService, masterScheduleActions, masterScheduleToolbarService, element) {
        this.stateManagementService = stateManagementService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.masterScheduleActions = masterScheduleActions;
        this.masterScheduleToolbarService = masterScheduleToolbarService;
        this.element = element;
        this.doExport = false;
        this.onAction = new EventEmitter();
        this.componentId = 'MasterScheduleComponent';
        this.groupId = 'MasterScheduleGrid';
        this.totalsSettingsId = 'totals';
        this.displaySettingsId = 'display';
        this.filtersId = 'lookupFilters';
        this.groupingSettingsId = 'groupingSettings';
        this.selectedEmployeeRowId = 'selectedRowId';
        this.filtersDateId = 'filtersDate';
        this.filtersWeeksId = 'filtersWeeks';
        this.filtersShowFTE = 'filtersShowFTE';
        this.msGridDefaultViewState = 'MasterScheduleGridDefaultView';
        this.managementService = managementService;
    }
    MasterScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagementService.init(this.componentId, true);
        this.restoreFilters();
        this.managementService.onFiltersRestored(this.filters);
        this.employeeRowSelectedSubscription = this.managementService.onEmployeeCellSelected$.subscribe(function (cellInfo) {
            _this.saveSelectedRow(cellInfo);
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.actionsSubscription = this.managementService.actions$.subscribe(function (data) {
            _this.actions = data;
        });
        this.settingsSubscription = this.masterScheduleToolbarService.onSettingsChanged$.subscribe(function (settings) {
            _this.settings = settings;
            _this.saveSettings();
        });
        this.filtersSubscription = this.masterScheduleToolbarService.onFiltersChanged$.subscribe(function (filters) {
            _this.filters = filters;
            _this.saveFilters();
            if (!_this.settings) {
                _this.restoreSettings();
                _this.masterScheduleToolbarService.settingsChanged(_this.settings);
            }
        });
        this.filtersSubscription = this.masterScheduleToolbarService.onGroupingChanged$.subscribe(function (grouping) {
            if (!_this.settings) {
                _this.restoreSettings();
            }
            _this.settings.display.positionGrouping = grouping;
            _this.saveGrouping();
            _this.masterScheduleToolbarService.settingsChanged(_this.settings);
        });
        this.startCycleSubscription = this.managementService.onStartCycleSelect$.subscribe(function () {
            _this.onStartCycleSelect();
        });
        this.endCycleSubscription = this.managementService.onNonStartCycleSelect$.subscribe(function () {
            _this.onNonStartCycleSelect();
        });
    };
    MasterScheduleComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    MasterScheduleComponent.prototype.onStartCycleSelect = function () {
        this.isStartCycleDate = true;
    };
    MasterScheduleComponent.prototype.onNonStartCycleSelect = function () {
        this.isStartCycleDate = false;
    };
    MasterScheduleComponent.prototype.onExport = function () {
        this.masterScheduleGridComponent.onExport();
    };
    MasterScheduleComponent.prototype.onFetchSchedule = function (schedule) {
        if (!schedule) {
            this.masterScheduleActions.fetchMasterScheduleData();
            return;
        }
        this.masterScheduleActions.fetchEmployeesScheduleSuccess(this.actions, schedule);
        this.masterScheduleActions.fetchTotals();
    };
    MasterScheduleComponent.prototype.restoreSettings = function () {
        this.settings = new MasterScheduleSettings();
        this.settings.columns = new MasterScheduleColumnsSettings();
        this.settings.columns.createColumns();
        var totalsState = this.stateManagementService.getControlState(this.totalsSettingsId);
        if (totalsState.value) {
            this.settings.totals = totalsState.value;
        }
        else {
            this.settings.totals = new MasterScheduleTotalsSettings();
        }
        var displaySettings = this.stateManagementService.getControlState(this.displaySettingsId);
        if (totalsState.value) {
            this.settings.display = displaySettings.value;
        }
        else {
            this.settings.display = new MasterScheduleDisplaySettings();
        }
        this.columnSettingsStorageService.getColumnsState(this.componentId, this.groupId, this.settings.columns.columns);
    };
    MasterScheduleComponent.prototype.saveSettings = function () {
        var defaultViewState = this.stateManagementService.getControlState(this.msGridDefaultViewState);
        if (!defaultViewState.value) {
            this.defaultDisplayView();
        }
        this.stateManagementService.setControlState(this.totalsSettingsId, { value: this.settings.totals }, StateResetTypes.None);
        this.stateManagementService.setControlState(this.displaySettingsId, { value: this.settings.display }, StateResetTypes.None);
        this.columnSettingsStorageService.setColumnsState(this.componentId, this.groupId, this.settings.columns.columns);
    };
    MasterScheduleComponent.prototype.defaultDisplayView = function () {
        this.settings = new MasterScheduleSettings();
        this.settings.columns = new MasterScheduleColumnsSettings();
        this.settings.columns.isDefaultViewUpdated = true;
        this.stateManagementService.setControlState(this.msGridDefaultViewState, { value: this.settings.columns.isDefaultViewUpdated }, StateResetTypes.None);
        this.settings.columns.createColumns();
    };
    MasterScheduleComponent.prototype.restoreFilters = function () {
        this.filters = new MasterScheduleFilters();
        var filtersState = this.stateManagementService.getControlState(this.filtersId);
        this.filters.filters = new MasterScheduleLookupFilters();
        if (filtersState) {
            Object.assign(this.filters.filters, filtersState.value);
        }
        if (MasterScheduleManagementService.firstLoad) {
            this.filters.dateFrom = null;
            MasterScheduleManagementService.firstLoad = false;
        }
        else {
            var dateState = this.stateManagementService.getControlState(this.filtersDateId);
            if (!dateState) {
                this.filters.dateFrom = null;
            }
            else {
                this.filters.dateFrom = moment(dateState.value, appConfig.dateFormat).toDate();
            }
        }
        var filtersWeeksState = this.stateManagementService.getControlState(this.filtersWeeksId);
        if (!filtersWeeksState) {
            this.filters.weekNumber = 4;
        }
        else {
            this.filters.weekNumber = filtersWeeksState.value;
        }
        var filtersGroupingState = this.stateManagementService.getControlState(this.groupingSettingsId);
        if (filtersGroupingState && filtersGroupingState.value) {
            this.filters.groupBy = filtersGroupingState.value;
        }
        else {
            this.filters.groupBy = MasterScheduleGroupingTypes.ByPosition;
        }
        var selectedEmployeeRowIdState = this.stateManagementService.getControlState(this.selectedEmployeeRowId);
        if (selectedEmployeeRowIdState && selectedEmployeeRowIdState.value) {
            this.filters.selectedCell = selectedEmployeeRowIdState.value;
        }
        else {
            this.filters.selectedCell = null;
        }
        var filterShowFTEState = this.stateManagementService.getControlState(this.filtersShowFTE);
        if (filterShowFTEState && filterShowFTEState.value) {
            this.filters.showTotalsFTEs = filterShowFTEState.value;
        }
        else {
            this.filters.showTotalsFTEs = null;
        }
    };
    MasterScheduleComponent.prototype.saveFilters = function () {
        this.stateManagementService.setControlState(this.filtersId, { value: this.filters.filters }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
        this.stateManagementService.setControlState(this.filtersDateId, { value: moment(this.filters.dateFrom).format(appConfig.dateFormat) }, StateResetTypes.SessionEnd);
        this.stateManagementService.setControlState(this.filtersWeeksId, { value: this.filters.weekNumber }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
        this.stateManagementService.setControlState(this.filtersShowFTE, { value: this.filters.showTotalsFTEs }, StateResetTypes.None);
    };
    MasterScheduleComponent.prototype.saveGrouping = function () {
        this.stateManagementService.setControlState(this.groupingSettingsId, { value: this.settings.display.positionGrouping }, StateResetTypes.SessionEnd);
    };
    MasterScheduleComponent.prototype.saveSelectedRow = function (cellInfo) {
        if (cellInfo)
            this.stateManagementService.setControlState(this.selectedEmployeeRowId, { value: cellInfo }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "settingsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "filtersSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "startCycleSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "endCycleSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "employeeRowSelectedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleComponent.prototype, "actionsSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", MasterScheduleManagementService)
    ], MasterScheduleComponent.prototype, "managementService", void 0);
    return MasterScheduleComponent;
}());
export { MasterScheduleComponent };
