export interface IOrgLevelLocation {
    zipCode: string;
    city: string;
    latitude: number;
    longitude: number;
    timeZoneOffset: number;
    timeZone: string;
    state: string;
    stateAbbreviation: string;
}

export class OrgLevelLocation {
    public zipCode: string;
    public city: string;
    public latitude: number;
    public longitude: number;
    public timeZoneOffset: number;
    public timeZone: string;
    public state: string;
    public stateAbbreviation: string;
}
