import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.dataSubject = new BehaviorSubject(null);
        this.data$ = this.dataSubject.asObservable();
    }
    DataService.prototype.setData = function (data, isImport) {
        var combinedData = { data: data, isImport: isImport };
        this.dataSubject.next(combinedData);
    };
    DataService.prototype.setOrgLevelId = function (orgLevelId) {
        this.dataSubject.next({ orgLevelId: orgLevelId });
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
