import * as tslib_1 from "tslib";
import { Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { CopyPayCodesContainer } from '../../models/copy-paycodes/copy-paycodes-container';
import { CopyPayCodesAPIService } from './copy-paycodes-api.service';
import * as _ from 'lodash';
var CopyPayCodesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(CopyPayCodesManagementService, _super);
    function CopyPayCodesManagementService(access, changeService, api, notificationsService, route, router) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.notificationsService = notificationsService;
        _this.route = route;
        _this.router = router;
        _this.m_container = new CopyPayCodesContainer();
        return _this;
    }
    Object.defineProperty(CopyPayCodesManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    CopyPayCodesManagementService.prototype.markAsDirty = function () {
        throw new Error('Method not implemented.');
    };
    CopyPayCodesManagementService.prototype.init = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.route.queryParams.subscribe(function (params) {
            var id = params['id'];
            if (id) {
                _this.m_container.id = +id;
            }
        });
        this.appDataSubscription = this.orgLevel$.pipe(combineLatest(this.user$)).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    CopyPayCodesManagementService.prototype.loadPayCodes = function (orgLevelId) {
        var _this = this;
        return this.api.getPaycodeExceptions(orgLevelId).then(function (payCodes) {
            _this.m_container.records = payCodes;
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to load pay codes.');
            console.error('Error loading pay codes:', error);
            throw error;
        });
    };
    CopyPayCodesManagementService.prototype.loadOrganizations = function () {
        var _this = this;
        return this.api.getOrganizationDetails().then(function (organizations) {
            _this.m_container.organizations = organizations;
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to load organizations.');
            console.error('Error loading organizations:', error);
            throw error;
        });
    };
    CopyPayCodesManagementService.prototype.copyPayCodes = function (payCodeIds, organizationIds, currentOrganizationId, overrideMatchingException, copyOnlyIfExists) {
        var _this = this;
        return this.api.copyPayCodes(payCodeIds, organizationIds, currentOrganizationId, overrideMatchingException, copyOnlyIfExists).then(function () {
            _this.notificationsService.success('Success', 'Pay codes copied successfully.');
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to copy pay codes.');
            console.error('Error copying pay codes:', error);
            throw error;
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], CopyPayCodesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], CopyPayCodesManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], CopyPayCodesManagementService.prototype, "appDataSubscription", void 0);
    return CopyPayCodesManagementService;
}(ManagementBaseService));
export { CopyPayCodesManagementService };
