import * as tslib_1 from "tslib";
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { ApiUtilService } from "../../../common";
import { PaycodeExceptionMapService } from "../pay-code-details/paycode-exception-map.service";
var CopyPayCodesAPIService = /** @class */ (function () {
    function CopyPayCodesAPIService(apiUtilService, mapService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.taApiRoot = this.getTAServicesApiRoot(); // Use the TA Services API root directly
    }
    // PaycodeExceptions
    CopyPayCodesAPIService.prototype.getPaycodeExceptions = function (orgId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/paycodeexceptions/organizations/" + orgId + "/paycodeexceptions/all";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, response.data.map(this.mapService.mapToModel)];
                        }
                        return [2 /*return*/, []]; // Return empty list if no data found
                    case 3:
                        error_1 = _a.sent();
                        if (error_1.status === 404 || (error_1.error && error_1.error.status === 404)) {
                            console.warn('No paycode exceptions found for organization ID:', orgId);
                            return [2 /*return*/, []]; // Return empty list for 404 errors
                        }
                        this.handleError(error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CopyPayCodesAPIService.prototype.getOrganizationDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, response.data];
                        }
                        throw new Error("No organizations found.");
                    case 3:
                        error_2 = _a.sent();
                        if (error_2.status === 404 || (error_2.error && error_2.error.status === 404)) {
                            console.warn('No organizations found.');
                            return [2 /*return*/, null]; // Return null for 404 errors
                        }
                        this.handleError(error_2);
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CopyPayCodesAPIService.prototype.copyPayCodes = function (payCodeIds, organizationIds, currentOrganizationId, overrideMatchingException, copyOnlyIfExists) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, headers, request, response, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/paycodeexceptions/copy";
                        body = {
                            payCodeIds: payCodeIds,
                            organizationIds: organizationIds,
                            currentOrganizationId: currentOrganizationId,
                            overrideMatchingException: overrideMatchingException,
                            copyOnlyIfExists: copyOnlyIfExists
                        };
                        headers = new HttpHeaders({
                            'Content-Type': 'application/json'
                        });
                        request = new HttpRequest('POST', url, body, { headers: headers });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        console.log(JSON.stringify(body));
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        console.log('Pay codes copied successfully.');
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        this.handleError(error_3);
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CopyPayCodesAPIService.prototype.getTAServicesApiRoot = function () {
        return this.apiUtilService.getTAServicesApiRoot() + "/taappend";
    };
    CopyPayCodesAPIService.prototype.handleError = function (error) {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        }
        else {
            console.error('An error occurred:', error);
        }
    };
    return CopyPayCodesAPIService;
}());
export { CopyPayCodesAPIService };
