import { LicenseTypesComponent } from './license-types/license-types.component';
import { ModulesListConfigurationComponent } from './favorites/modules-list-configuration/modules-list-configuration.component';
import { FavoritesListConfigurationComponent } from './favorites/favorites-list-configurtion/favorites-list-configuration.component';
import { FavoritesConfigurationDialogComponent } from './favorites/favorites-configuration-dialog.component';
export * from './location-units/location-units.component';
export * from './location-units/location-unit-editor/location-unit-editor.component';
export * from './location-units/location-unit-remove-dialog/location-unit-remove-dialog.component';
export * from './location-units/location-unit-renderer/location-unit-renderer.component';

export * from './editableList/custom-list-actor.base';
export * from './editableList/editable-list.component';
export * from './editableList/listRow/editable-list-row.component';
export * from './editableList/listEditor/editable-list-editor.component';

export * from './absences/absences.component';
export * from './absences/absence-editor/configure-absence-editor.component';
export * from './absences/absence-renderer/configure-absence-renderer.component';

export * from './ideal-schedule/index';

export * from './positions/configure-positions.component';
export * from './positions/reassignPopup/configure-positions-reassign-popup.component';
export * from './positions/copyPositions/configure-copy-positions.component';
export * from './positions/copyPositions/copyPositionRenderer/configure-copy-position-renderer.component';
export * from './positions/manage-groups/index';

export * from './partner-integrations/configure-partner-positions.component';

export * from './shifts/index';
export * from './constraints/configure-constraints.component';
export * from './constraints/constraintRenderer/configure-constraint-renderer.component';
export * from './constraints/constraintEditor/configure-constraint-editor.component';

export * from './timeclocks/index';
export * from './holidays/index';
export * from './roles/index';
export * from './agency/index';
export * from './users/index';
export * from './agency-employee/index';
export * from './attendance-points-config/attendance-points-config.component';

export * from './pbj-organizations/index';
export * from './pay-codes/index';
export * from './paycodes-details/index';
export * from './copy-paycodes/index';
export * from './pay-policies/index';
export * from './user-password-settings/user-password-settings.component';
export * from './user-password-settings-dialog/user-password-settings-dialog.component';
export * from './cost-centers/index';

export * from './telepunch-locations/telepunch-locations.component';
export * from './telepunch-locations/telepunch-locations-grid/telepunch-locations-grid.component';
export * from './telepunch-locations/telepunch-locations-toolbar/telepunch-locations-toolbar.component';
export * from './geolocation/index';
export * from './employers/configure-employers.component';
export * from './employers/manage-employer-dialog/manage-employer-dialog.component';
export * from './employers/employer-organization-dialog/employer-organization-dialog.component';

export * from './aca-measurement/aca-measurement.component';
export * from './aca-measurement/aca-measurement-toolbar/aca-measurement-toolbar.component';
export * from './aca-measurement/aca-measurement-ag-grid/aca-measurement-ag-grid.component';
export * from './aca/index';

export * from './ip-management/index';

export * from './aca-1095-c/aca-1095-c.component';
export * from './aca-1095-c/aca-1095-c-toolbar/aca-1095-c-toolbar.component';
export * from './aca-1095-c/aca-1095-c-insights/aca-1095-c-insights.component';
export * from './aca-1095-c/aca-1095-c-grid/aca-1095-c-grid.component';
export * from './aca-c1095-audit/aca-c1095-audit.component';
export * from './aca-c1095-audit/aca-c1095-audit-toolbar/aca-c1095-audit-toolbar.component';
export * from './aca-c1095-audit/aca-c1095-audit-grid/aca-c1095-audit-grid.component';

export * from './user-login-management/user-login-management.component';

import { EditableListComponent } from './editableList/editable-list.component';
import { EditableListRowComponent } from './editableList/listRow/editable-list-row.component';
import { EditableListEditorComponent } from './editableList/listEditor/editable-list-editor.component';

import { AbsencesComponent } from './absences/absences.component';
import { ConfigureAbsenceRendererComponent } from './absences/absence-renderer/configure-absence-renderer.component';
import { ConfigureAbsenceEditorComponent } from './absences/absence-editor/configure-absence-editor.component';

import { LocationUnitEditorComponent } from './location-units/location-unit-editor/location-unit-editor.component';

import { UnitRemoveDialogComponent } from './location-units/location-unit-remove-dialog/location-unit-remove-dialog.component';
import { UnitsComponent } from './location-units/location-units.component';
import { LocationUnitRendererComponent } from './location-units/location-unit-renderer/location-unit-renderer.component';

import { ConfigurePositionsComponent } from './positions/configure-positions.component';
import { ConfigurePositionRendererComponent } from './positions/positionRenderer/configure-position-renderer.component';
import { ConfigurePositionEditorComponent } from './positions/positionEditor/configure-position-editor.component';
import { ConfigureCopyPositionRendererComponent } from './positions/copyPositions/copyPositionRenderer/configure-copy-position-renderer.component';
import { ConfigureCopyPositionsComponent } from './positions/copyPositions/configure-copy-positions.component';
import { ConfigurePositionsReassignPopupComponent } from './positions/reassignPopup/configure-positions-reassign-popup.component';

import { ConfigureConstraintsComponent } from './constraints/configure-constraints.component';
import { ConfigureConstraintRendererComponent } from './constraints/constraintRenderer/configure-constraint-renderer.component';
import { ConfigureConstraintsEditorComponent } from './constraints/constraintEditor/configure-constraint-editor.component';

import {
  ShiftsComponent, ViewShiftRendererComponent, EditorShiftRendererComponent,
  ShiftRemoveDialogComponent, CopyShiftsComponent
} from './shifts/index';

import { HolidaysComponent, HolidaysEditorComponent, HolidaysCopyComponent } from './holidays/index';

import { ConfigureAgencyComponent, AgencyMappingsComponent, AgencyDetailsComponent } from './agency/index';

import {
  UserRolesAssignementComponent, UserRolesAssignementActionBarComponent, UserRolesAssignementGridComponent,
  UserProfileComponent, RoleUsersGridComponent, UserProfilePanelHeaderComponent, UserProfileSectionProfileComponent, UserProfileSectionRolesComponent,
  OrgLevelSelectorComponent
} from './users/index';

import { pbjOrganizationsComponents } from './pbj-organizations/index';

import { IdealScheduleOverviewDialogComponent } from './ideal-schedule/index';

import { payCodesConfigurationComponents } from './pay-codes/index';
import { payCodesDetailsComponents } from './paycodes-details';

import { ConfigureCostCentersComponent, CostCentersGridComponent, CostCentersToolbarComponent, CostCentersMobileViewComponent } from './cost-centers/index';

import { timeclocksComponents } from './timeclocks/index';
import { TimeclockRemoveDialogComponent } from './timeclocks/timeclock-remove-dialog/timeclock-remove-dialog.component';
import { rolesComponents } from './roles/index';
import { RolesLegendDialogComponent } from './roles/roles-legend-dialog/roles-legend-dialog.component';
import { agencyEmployeesComponents } from './agency-employee/index';
import { AttendancePointsConfigComponent } from './attendance-points-config/attendance-points-config.component';
import { UserPasswordSettingsComponent } from './user-password-settings/user-password-settings.component';
import { UserPasswordSettingsDialogComponent } from './user-password-settings-dialog/user-password-settings-dialog.component';

import { TelepunchLocationsComponent } from './telepunch-locations/telepunch-locations.component';
import { TelepunchLocationsGridComponent } from './telepunch-locations/telepunch-locations-grid/telepunch-locations-grid.component';
import { TelepunchLocationsToolbarComponent } from './telepunch-locations/telepunch-locations-toolbar/telepunch-locations-toolbar.component';
import { TelepunchLocationsMobileComponent } from './telepunch-locations/telepunch-locations-mobile/telepunch-locations-mobile.component';

import { geolocationComponents, GeolocationDialogComponent } from './geolocation/index';

import { manageGroupsComponents, ManagePositionGroupsDialogComponent } from './positions/manage-groups/index';
import { ConfigureEmployersComponent } from './employers/configure-employers.component';

import { manageALEGroupsComponents, ManageAleGroupsDialogComponent } from './employers/manage-groups/index';
import { ManageEmployerDialogComponent } from './employers/manage-employer-dialog/manage-employer-dialog.component';
import { EmployerOrganizationDialogComponent } from './employers/employer-organization-dialog/employer-organization-dialog.component';
import { FavoritesConfigurationComponent } from './favorites/favorites-configuration/favorites-configuration.component';

import { AcaMeasurementComponent } from './aca-measurement/aca-measurement.component';
import { AcaMeasurementToolbarComponent } from './aca-measurement/aca-measurement-toolbar/aca-measurement-toolbar.component';
import { AcaMeasurementAgGridComponent } from './aca-measurement/aca-measurement-ag-grid/aca-measurement-ag-grid.component';
import { ProgressbarComponent } from './aca-measurement/aca-measurement-ag-grid/progressbar.component';
import { AcaMeasurementInsightsComponent } from './aca-measurement/aca-measurement-insights/aca-measurement-insights.component';
import { acaConfigurationComponents } from './aca/index';

import { IpManagementComponent } from './ip-management/ip-management/ip-management.component';
import { IpWhitelistingComponent } from './ip-management/ip-whitelisting/ip-whitelisting.component';
import { IpBlacklistingComponent } from './ip-management/ip-blacklisting/ip-blacklisting.component';

import { Aca1095cComponent } from './aca-1095-c/aca-1095-c.component';
import { Aca1095cToolbarComponent } from './aca-1095-c/aca-1095-c-toolbar/aca-1095-c-toolbar.component';
import { Aca1095cGridComponent } from './aca-1095-c/aca-1095-c-grid/aca-1095-c-grid.component';
import { Aca1095cInsightsComponent } from './aca-1095-c/aca-1095-c-insights/aca-1095-c-insights.component';
import { Aca1095CAddEmployeePopupComponent} from './aca-1095-c/add-employee-popup/add-employee-popup.component';
import {Edit1095CPopup} from './aca-1095-c/edit-1095-c-popup/edit-1095-c-popup.component';
import {QuickEdit1095CPopup} from './aca-1095-c/aca-1095c-quick-edit/aca-1095c-quick-edit.component';
import {QuickConfirm1095CPopup} from './aca-1095-c/aca-1095c-quick-confirm/aca-1095c-quick-confirm.component';
import {QuickEditReset1095CPopup} from './aca-1095-c/aca-1095c-quick-edit-reset/aca-1095c-quick-edit-reset.component';
import {QuickConfirmReset1095CPopup} from './aca-1095-c/aca-1095c-quic-confirm-reset/aca-1095c-quick-confirm-reset.component';
import {Aca1095cGridColumnHeaderComponent} from './aca-1095-c/aca-1095-c-grid/aca-1095-c-grid-column-header.component';
import { AcaC1095AuditComponent } from './aca-c1095-audit/aca-c1095-audit.component';
import { AcaC1095AuditToolbarComponent } from './aca-c1095-audit/aca-c1095-audit-toolbar/aca-c1095-audit-toolbar.component';
import { AcaC1095AuditGridComponent} from './aca-c1095-audit/aca-c1095-audit-grid/aca-c1095-audit-grid.component';
import { AcaC1095CustomGroupHeader } from './aca-c1095-audit/aca-c1095-audit-grid/aca-c1095-custom-gh.component';
import { ConfigurePartnerPositionsComponent } from './partner-integrations/configure-partner-positions.component';

import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsGridComponent } from './notifications/notifications-grid/notifications-grid.component';
import { ClientActivationComponent } from './client-activation/client-activation.component';

import { UserLoginManagement } from './user-login-management/user-login-management.component';
import { WfmSyncComponent } from './wfm-sync/wfm-sync.component';
import { WfmSyncResponseComponent } from './wfm-sync/wfm-sync-response/wfm-sync-response.component';
import { WfmDailytransactionReportComponent } from './wfm-sync/wfm-dailytransaction-report/wfm-dailytransaction-report.component';
import { WorkLocationMigrationComponent } from './wfm-sync/work-location-migration/work-location-migration.component';
import { WorkLocationSyncComponent } from './wfm-sync/work-location-sync/work-location-sync.component';
import { EventReportComponent } from './wfm-sync/event-report/event-report.component';
import { ShiftGroupComponent } from './shift-group/shift-group.component';
import { ShiftGroupGridComponent } from './shift-group/shift-group-grid/shift-group-grid.component';
import { IdealScheduleImportTemplateDialogComponent } from './ideal-schedule/ideal-schedule-import-template-dialog/ideal-schedule-import-template-dialog.component';

import { FileUploadsComponent } from './file-uploads/file-uploads.component';
import { TAEventReportComponent } from './wfm-sync/ta-event-report/ta-event-report.component';
import { PaydataEventReportComponent } from './wfm-sync/paydata-event-report/paydata-event-report.component';
import { copyPayCodesComponent } from './copy-paycodes/index';
import { payPoliciesComponent } from './pay-policies';

export const editableListClasses: any[] = [
  EditableListComponent, EditableListRowComponent, EditableListEditorComponent
];

const absenceComponents: any[] = [
  AbsencesComponent, ConfigureAbsenceRendererComponent, ConfigureAbsenceEditorComponent
];

const unitComponents: any[] = [
  LocationUnitEditorComponent, UnitRemoveDialogComponent, UnitsComponent, LocationUnitRendererComponent
];

const positionsComponents: any[] = [
  ConfigurePositionsComponent, ConfigurePositionRendererComponent,
  ConfigurePositionEditorComponent, ConfigurePositionsReassignPopupComponent, ConfigureCopyPositionRendererComponent,
  ConfigureCopyPositionsComponent,
  ...manageGroupsComponents
];

const partnerComponents: any[] = [
  ConfigurePartnerPositionsComponent
];

const employersComponents: any[] = [
  ConfigureEmployersComponent,
  ...manageALEGroupsComponents,
  ManageEmployerDialogComponent,
  EmployerOrganizationDialogComponent
];

const acaComponents: any[] = [
  AcaMeasurementComponent,
  AcaMeasurementToolbarComponent,
  AcaMeasurementAgGridComponent,
  ProgressbarComponent,
  AcaMeasurementInsightsComponent,
  Aca1095cComponent,
  Aca1095cToolbarComponent,
  Aca1095cGridComponent,
  Aca1095cInsightsComponent,
  Aca1095CAddEmployeePopupComponent,
  Edit1095CPopup,
  QuickEdit1095CPopup,
  QuickConfirm1095CPopup,
  QuickEditReset1095CPopup,
  QuickConfirmReset1095CPopup,
  Aca1095cGridColumnHeaderComponent,
  AcaC1095AuditComponent,
  AcaC1095AuditToolbarComponent,
  AcaC1095AuditGridComponent,
  AcaC1095CustomGroupHeader
];


const constraintsComponents: any[] = [
  ConfigureConstraintsComponent, ConfigureConstraintRendererComponent, ConfigureConstraintsEditorComponent
];
const shiftsComponents: any[] = [
  ShiftsComponent, ViewShiftRendererComponent, EditorShiftRendererComponent,
  ShiftRemoveDialogComponent, CopyShiftsComponent
];

const holidaysComponents: any[] = [
  HolidaysComponent, HolidaysEditorComponent, HolidaysCopyComponent
];

const telepunchComponents: any[] = [
  TelepunchLocationsComponent, TelepunchLocationsGridComponent, TelepunchLocationsToolbarComponent,
  TelepunchLocationsMobileComponent
];

const favoritesComponents: any[] = [
  FavoritesConfigurationDialogComponent, FavoritesConfigurationComponent, FavoritesListConfigurationComponent,
  ModulesListConfigurationComponent
];

export const agencyComponents: any[] = [
  ConfigureAgencyComponent, AgencyMappingsComponent, AgencyDetailsComponent
];

export const usersComponents: any[] = [
  UserRolesAssignementComponent, UserRolesAssignementActionBarComponent, UserRolesAssignementGridComponent,
  UserProfileComponent, RoleUsersGridComponent,
  UserProfilePanelHeaderComponent, UserProfileSectionProfileComponent, UserProfileSectionRolesComponent,
  OrgLevelSelectorComponent, UserLoginManagement
];

export const costCentersComponents: any[] = [
  CostCentersGridComponent,
  CostCentersMobileViewComponent,
  CostCentersToolbarComponent,
  ConfigureCostCentersComponent
];

export const ipManagementComponents: any[] = [
  IpManagementComponent,
  IpWhitelistingComponent,
  IpBlacklistingComponent
];

export const notificationsComponents: any[] = [
  NotificationsComponent,
  NotificationsGridComponent
];
export const wfmComponents: any[] = [
  ClientActivationComponent,
  WfmSyncComponent,
  WfmSyncResponseComponent,
 WfmDailytransactionReportComponent,
 WorkLocationMigrationComponent,
 WorkLocationSyncComponent,
 EventReportComponent,
 TAEventReportComponent,
 PaydataEventReportComponent
];
export const shiftGroupComponents: any[] = [
  ShiftGroupComponent, ShiftGroupGridComponent
];

export const fileUploadsComponents: any[] = [
  FileUploadsComponent
];

export const entryConfigurationComponents: any[] = [
  IdealScheduleOverviewDialogComponent,
  UnitRemoveDialogComponent,
  ShiftRemoveDialogComponent,
  ConfigurePositionsReassignPopupComponent,
  TimeclockRemoveDialogComponent,
  HolidaysEditorComponent,
  RolesLegendDialogComponent,
  UserPasswordSettingsDialogComponent,
  GeolocationDialogComponent,
  ManagePositionGroupsDialogComponent,
  ManageAleGroupsDialogComponent,
  ManageEmployerDialogComponent,
  EmployerOrganizationDialogComponent,
  FavoritesConfigurationDialogComponent,
  EmployerOrganizationDialogComponent,
  ProgressbarComponent,
  Aca1095CAddEmployeePopupComponent,
  Edit1095CPopup,
  QuickEdit1095CPopup,
  QuickConfirm1095CPopup,
  QuickEditReset1095CPopup,
  QuickConfirmReset1095CPopup,
  Aca1095cGridColumnHeaderComponent,
  AcaC1095CustomGroupHeader,
  IdealScheduleImportTemplateDialogComponent
];

export const licenseTypesComponents: any[] = [
  LicenseTypesComponent
];

export const exportConfigurationComponents: any = [
  ...absenceComponents,
  ...positionsComponents,
  ...partnerComponents,
  ...timeclocksComponents,
  ...editableListClasses,
  ...agencyComponents,
  ...usersComponents,
  ...agencyEmployeesComponents,
  ...pbjOrganizationsComponents,
  ...payCodesConfigurationComponents,
  ...payCodesDetailsComponents,
  ...copyPayCodesComponent,
  ...payPoliciesComponent,
  ...costCentersComponents,
  AttendancePointsConfigComponent,
  UserPasswordSettingsComponent,
  UserPasswordSettingsDialogComponent,
  ...geolocationComponents,
  ...telepunchComponents,
  ...employersComponents,
  ...acaComponents,
  ...acaConfigurationComponents,
  ...employersComponents,
  ...favoritesComponents,
  ...notificationsComponents,
  ...licenseTypesComponents,
  ...wfmComponents,
  ...fileUploadsComponents
];



export const configurationComponents: any[] = [
  ...exportConfigurationComponents,
  rolesComponents,
  editableListClasses,
  constraintsComponents,
  ConfigurePositionsReassignPopupComponent,
  unitComponents,
  shiftsComponents,
  holidaysComponents,
  telepunchComponents,
  ...ipManagementComponents,
  shiftGroupComponents
];
