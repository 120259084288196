import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { KendoGridStateHelper, ModalService } from '../../../../common';
import { unsubscribe } from '../../../../core/decorators';
import * as _ from 'lodash';
import { process } from '@progress/kendo-data-query';
import { PbjExportApiService } from '../../services/pbj-export/pbj-export-api.service';
import { appConfig } from '../../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DispositionState } from '../../models/pbj-duplicate-workers/pbj-disposition';
import { PbjDuplicateMatchesDialogOptions } from '../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options';
import { PbjDuplicateMatchesDialogComponent } from './pbj-duplicate-matches-dialog/pbj-duplicate-matches-dialog.component';
import * as kendo from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
var PbjDuplicateWorkersComponent = /** @class */ (function () {
    function PbjDuplicateWorkersComponent(route, apiService, modalService) {
        this.route = route;
        this.apiService = apiService;
        this.modalService = modalService;
        this.orgLevelId = null;
        this.data = [];
        this.pageSize = 50;
        this.hiddenColumns = [];
        this.recordControls = [
            {
                id: 0,
                text: "Pending",
                value: "Pending"
            },
            {
                id: 1,
                text: "Match",
                value: "Match"
            },
            {
                id: 3,
                text: "Not a Match",
                value: "Not a Match"
            },
            {
                id: 4,
                text: "All Records",
                value: "All Records"
            }
        ];
        this.dispoOptions = [
            {
                id: 0,
                text: "Pending",
                value: "Pending"
            },
            {
                id: 1,
                text: "Match",
                value: "Match"
            },
            {
                id: 3,
                text: "Not a Match",
                value: "Not a Match"
            },
        ];
        this.dispoState = new DispositionState();
        this.exportMatches = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.take = this.pageSize;
        this.gridState.state.filter = null;
        this.gridState.state.group = [];
        this.appConfig = appConfig;
        this.dispoState = this.recordControls[0];
        this.gridState.view = { data: this.data, total: this.data.length };
        if (this.orgLevelId != null && this.orgLevelId != undefined)
            this.getDuplicateRecords();
        this.getExportData = this.getExportData.bind(this);
    }
    PbjDuplicateWorkersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: true,
            showSpinnerMessage: false
        };
        this.colNames = ["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
        this.isDispositionDateLocked = true;
        this.gridState.state.skip = 0;
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var orgLevelId = +queryParams['orgLevelId'];
            if (_.isFinite(orgLevelId) && _this.orgLevelId !== orgLevelId) {
                _this.orgLevelId = orgLevelId;
                _this.hiddenColumns = [];
                _this.dispoState = _this.recordControls[0];
                _this.getDuplicateRecords();
            }
            if (!_.isFinite(_this.orgLevelId) && _.isFinite(orgLevelId)) {
                _this.orgLevelId = orgLevelId;
            }
        });
    };
    PbjDuplicateWorkersComponent.prototype.getDuplicateRecords = function () {
        var _this = this;
        this.state.isLoading = true;
        this.gridState.state.skip = 0;
        this.apiService.performDuplicateExport(this.orgLevelId).then(function (val) {
            _this.loadDataToGrid(val);
        })
            .catch(function (err) {
            console.error(err);
            _this.state.isLoading = false;
        });
    };
    PbjDuplicateWorkersComponent.prototype.loadDataToGrid = function (data) {
        var _this = this;
        this.allRecords = data;
        for (var i = 0; i < this.allRecords.length; i++) {
            if (this.allRecords[i].disposition == null)
                this.allRecords[i].disposition = "Pending";
        }
        this.data = this.dispoState.value != "All Records" ? data.filter(function (x) { return x.disposition == _this.dispoState.value; }) : this.allRecords;
        this.state.isLoading = false;
        this.refreshGrid();
    };
    PbjDuplicateWorkersComponent.prototype.refreshGrid = function () {
        if (this.data === undefined || this.data.length == 0)
            this.gridState.view = null;
        if (this.data.length > 0)
            this.gridState.view = process(this.data, this.gridState.state);
    };
    PbjDuplicateWorkersComponent.prototype.hideColumn = function (columnName) {
        if (!this.isHidden(columnName)) {
            this.hiddenColumns.push(columnName);
        }
        else {
            this.hiddenColumns.splice(this.hiddenColumns.indexOf(columnName), 1);
        }
    };
    PbjDuplicateWorkersComponent.prototype.isHidden = function (columnName) {
        return this.hiddenColumns.indexOf(columnName) > -1;
    };
    PbjDuplicateWorkersComponent.prototype.restrictReorder = function (event) {
        if (event.newIndex === 0 ||
            event.newIndex === 1 || event.column.title === "Disposition" ||
            event.column.title === "Disposition Date") {
            event.preventDefault();
        }
    };
    PbjDuplicateWorkersComponent.prototype.handleDisposition = function (event, colDetails) {
        var _this = this;
        if (event.value != colDetails.disposition && colDetails.disposition != null) {
            colDetails.disposition = event.text;
            colDetails.matching_org_id = this.orgLevelId;
            if (event.value == "Pending")
                colDetails.disposition_Date = null;
            this.state.isLoading = true;
            this.apiService.changeDisposition(colDetails).then(function (val) {
                _this.loadDataToGrid(val);
            });
        }
    };
    PbjDuplicateWorkersComponent.prototype.filterDuplicates = function (event) {
        this.gridState.state.filter = event;
        this.refreshGrid();
    };
    PbjDuplicateWorkersComponent.prototype.setSelectedPosition = function (dispoOption) {
        var obj = this.dispoOptions.find(function (e) { return e.value === dispoOption; });
        if (obj) {
            return obj;
        }
        else {
            return this.dispoOptions[0];
        }
    };
    PbjDuplicateWorkersComponent.prototype.selectClearCols = function (type) {
        if (type == 'select') {
            this.isDispositionDateLocked = true;
            this.hiddenColumns = [];
        }
        if (type == 'clear') {
            this.isDispositionDateLocked = false;
            this.hiddenColumns = ["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
        }
    };
    PbjDuplicateWorkersComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
    };
    PbjDuplicateWorkersComponent.prototype.exportExcel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getExportMatchesDefaultDate()];
                    case 1:
                        _a.sent();
                        this.exportMatches = false;
                        this.xlsxName = "EeDupes_" + this.getDate() + ".xlsx";
                        this.gridState.state.skip = 0;
                        this.gridState.state.take = this.data.length;
                        this.grid.saveAsExcel();
                        return [2 /*return*/];
                }
            });
        });
    };
    PbjDuplicateWorkersComponent.prototype.getFilteredRecords = function (target) {
        if (target != undefined) {
            this.gridState.state.skip = 0;
            this.gridState.state.filter = { filters: [], logic: 'and' };
            if (target.value == 'Pending')
                this.gridState.state.sort = [];
            this.dispoState = this.recordControls.find(function (x) { return x.value == target.value; });
            if (this.allRecords != undefined)
                this.data = (target.value != "All Records") ? this.allRecords.filter(function (x) { return x.disposition == target.value; }) : this.allRecords;
            this.refreshGrid();
        }
    };
    PbjDuplicateWorkersComponent.prototype.getExportMatchesDefaultDate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        return [4 /*yield*/, this.apiService.getExportMatchesDefaultDate(this.orgLevelId).then(function (val) {
                                _this.selectedDateForMatches = new Date(val.data);
                            })];
                    case 1:
                        _a.sent();
                        this.state.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PbjDuplicateWorkersComponent.prototype.updateExportMatchesDefaultDate = function (pbjDuplicateWorkers) {
        var _this = this;
        this.apiService.updateExportMatchesDefaultDate(pbjDuplicateWorkers, this.orgLevelId).then(function (val) {
            _this.state.isLoading = false;
        });
    };
    PbjDuplicateWorkersComponent.prototype.openExportMatchesDialog = function (matchingDupesFound) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options, dialogref;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getExportMatchesDefaultDate()];
                    case 1:
                        _a.sent();
                        options = new PbjDuplicateMatchesDialogOptions(this.selectedDateForMatches, matchingDupesFound);
                        dialogref = PbjDuplicateMatchesDialogComponent.openDialog(options, this.modalService, function (result) {
                            if (result) {
                                _this.state.isLoading = true;
                                _this.selectedDateForMatches = dialogref.selectedDate;
                                _this.exportMatches = true;
                                _this.grid.saveAsExcel();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PbjDuplicateWorkersComponent.prototype.getExportData = function () {
        var _this = this;
        var result = {
            data: this.exportMatches ? process(this.allRecords.filter(function (x) { return (x.disposition == 'Match' && (x.disposition_Date >= _this.selectedDateForMatches || x.matches_Export_Date === null)); }), this.gridState.state).data : process(this.data, this.gridState.state).data,
        };
        return result;
    };
    PbjDuplicateWorkersComponent.prototype.getDate = function () {
        var date = new Date();
        var month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : "" + (date.getMonth() + 1);
        var todayDate = date.getDate() < 10 ? "0" + date.getDate() : "" + date.getDate();
        var year = date.getFullYear().toString();
        var dateFormat = month + todayDate + year;
        return dateFormat;
    };
    PbjDuplicateWorkersComponent.prototype.onExcelExport = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileName_1, workbook, filteredData, filteredRows_1, sheet, matchesExportData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.exportMatches) {
                    e.preventDefault();
                    fileName_1 = "EeDupeMatches_" + this.getDate() + ".xlsx";
                    workbook = e.workbook;
                    workbook.sheets[0].name = "All Data";
                    filteredData = this.getExportData();
                    filteredRows_1 = [
                        { cells: [
                                { background: '#7a7a7a', color: '#fff', value: 'oldEmployeeId', colSpan: 1, rowSpan: 1 },
                                { background: '#7a7a7a', color: '#fff', value: 'newEmployeeId', colSpan: 1, rowSpan: 1 }
                            ],
                            type: 'header'
                        }
                    ];
                    filteredData.data.map(function (item) {
                        if (item.disposition_Date >= _this.selectedDateForMatches) {
                            var row = {
                                type: 'data',
                                cells: [
                                    { value: item.pbJ_ID_1 }, { value: item.pbJ_ID_2 }
                                ]
                            };
                            filteredRows_1.push(row);
                        }
                    });
                    if (filteredRows_1.length > 1) {
                        sheet = {
                            columns: [{ width: 150, autoWidth: false }, { width: 150, autoWidth: false }],
                            name: "EmployeeLink Data",
                            rows: filteredRows_1
                        };
                        workbook.sheets.unshift(sheet);
                        kendo.toDataURL(workbook).then(function (val) {
                            saveAs(val, fileName_1);
                        });
                        matchesExportData = this.allRecords.filter(function (x) { return x.disposition == 'Match' && (x.disposition_Date >= _this.selectedDateForMatches || x.matches_Export_Date === null); });
                        this.updateExportMatchesDefaultDate(matchesExportData);
                    }
                    else {
                        this.state.isLoading = false;
                        this.openExportMatchesDialog(false);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    PbjDuplicateWorkersComponent.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    PbjDuplicateWorkersComponent.prototype.getRefreshedRecords = function () {
        var _this = this;
        this.state.isLoading = true;
        this.gridState.state.skip = 0;
        this.state.showSpinnerMessage = true;
        this.apiService.getPBJEmployeeDuplicateRecordsRefresh(this.orgLevelId).then(function (val) {
            _this.state.isLoading = false;
            _this.state.showSpinnerMessage = false;
            _this.loadDataToGrid(val);
        });
    };
    PbjDuplicateWorkersComponent.prototype.getRefreshToolTip = function () {
        return "The data used in this process is populated by an overnight process. Click the refresh button to reflect the most recent change on the grid.";
    };
    PbjDuplicateWorkersComponent.prototype.getMatchScoreToolTip = function () {
        return "Highest point score (maximum of 26 points) is the most likely match. Lowest point score (minimum of 4 points) is the least likely match. Fuzzy Match logic is used comparing different data points such as Name, PBJ ID, SSN, DOB, Phone, etc.";
    };
    PbjDuplicateWorkersComponent.prototype.showTooltip = function (e) {
        var element = e.target;
        if (element.className.includes('fa-info-circle')) {
            this.tooltipDir.toggle(element);
        }
        else {
            this.tooltipDir.hide();
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjDuplicateWorkersComponent.prototype, "routeSubscripion", void 0);
    return PbjDuplicateWorkersComponent;
}());
export { PbjDuplicateWorkersComponent };
