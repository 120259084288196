import { Component, Provider, ViewChild, OnInit, OnDestroy, Output, EventEmitter, ElementRef, Inject } from '@angular/core';
import * as moment from 'moment';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { EmployeeScheduleDefinition, IScheduleActions } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { ModalAnchorDirective } from '../../../common/directives/index';
import { appConfig } from '../../../app.config';

import {
  EmployeeGridData, MasterScheduleColumnsSettings, MasterScheduleTotalsSettings,
  MasterScheduleSettings, MasterScheduleDisplaySettings, MasterScheduleFilters,
  MasterScheduleLookupFilters, MasterScheduleGroupingTypes, IMasterScheduleGroupingType,
  IMasterScheduleCell
} from '../../models/index';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import { mutableSelect, destroyService, unsubscribe } from '../../../core/decorators/index';
import { TOOLBAR_SERVICE } from '../../../core/services/index';
import { ColumnSettingsStorageService, StateManagementService, ChangeManagementService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';

import { MasterScheduleGridComponent } from './master-schedule-grid/master-schedule-grid.component';
import { MasterScheduleManagementService, MasterScheduleToolbarService, MasterScheduleQuickEditService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-master-shedule',
  templateUrl: 'master-shedule.component.html',
  styleUrls: ['master-shedule.component.scss'],
  providers: [MasterScheduleManagementService, StateManagementService, MasterScheduleQuickEditService, ChangeManagementService,
    { provide: TOOLBAR_SERVICE, useClass: MasterScheduleToolbarService }
  ]
})
export class MasterScheduleComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public isStartCycleDate: boolean;
  public isLoading: boolean;
  public doExport: boolean = false;

  @Output()
  public onAction: EventEmitter<string> = new EventEmitter();

  @ViewChild(ModalAnchorDirective, {static: true})
  private modalAnchor: ModalAnchorDirective;

  @ViewChild(MasterScheduleGridComponent, {static: true})
  private masterScheduleGridComponent: MasterScheduleGridComponent;

  @unsubscribe()
  private statusSubscription: Subscription;
  @unsubscribe()
  private settingsSubscription: Subscription;
  @unsubscribe()
  private filtersSubscription: Subscription;
  @unsubscribe()
  private startCycleSubscription: Subscription;
  @unsubscribe()
  private endCycleSubscription: Subscription;
  @unsubscribe()
  private employeeRowSelectedSubscription: Subscription;
  @unsubscribe()
  private actionsSubscription: Subscription;


  @destroyService()
  private managementService: MasterScheduleManagementService;

  private readonly componentId = 'MasterScheduleComponent';
  private readonly groupId = 'MasterScheduleGrid';
  private readonly totalsSettingsId = 'totals';
  private readonly displaySettingsId = 'display';
  private readonly filtersId = 'lookupFilters';
  private readonly groupingSettingsId = 'groupingSettings';
  private readonly selectedEmployeeRowId = 'selectedRowId';
  private readonly filtersDateId = 'filtersDate';
  private readonly filtersWeeksId = 'filtersWeeks';
  private readonly filtersShowFTE = 'filtersShowFTE';
  private readonly msGridDefaultViewState = 'MasterScheduleGridDefaultView';
  private settings: MasterScheduleSettings;
  private filters: MasterScheduleFilters;
  private actions: IScheduleActions;


  constructor(managementService: MasterScheduleManagementService,
    private stateManagementService: StateManagementService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private masterScheduleActions: MasterScheduleActions,
    @Inject(TOOLBAR_SERVICE) private masterScheduleToolbarService: MasterScheduleToolbarService,
    private element: ElementRef) {
    this.managementService = managementService;
  }

  public ngOnInit(): void {
    this.stateManagementService.init(this.componentId, true);

    this.restoreFilters();
    this.managementService.onFiltersRestored(this.filters);

    this.employeeRowSelectedSubscription = this.managementService.onEmployeeCellSelected$.subscribe((cellInfo: IMasterScheduleCell) => {
      this.saveSelectedRow(cellInfo);
    });

    this.statusSubscription = this.managementService.onLoadStatus$.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });

    this.actionsSubscription = this.managementService.actions$.subscribe((data: IScheduleActions) => {
      this.actions = data;
    });

    this.settingsSubscription = this.masterScheduleToolbarService.onSettingsChanged$.subscribe((settings: MasterScheduleSettings) => {
      this.settings = settings;
      this.saveSettings();
    });

    this.filtersSubscription = this.masterScheduleToolbarService.onFiltersChanged$.subscribe((filters: MasterScheduleFilters) => {
      this.filters = filters;
      this.saveFilters();
      if(!this.settings) {
        this.restoreSettings();
        this.masterScheduleToolbarService.settingsChanged(this.settings);
      }
    });

    this.filtersSubscription = this.masterScheduleToolbarService.onGroupingChanged$.subscribe((grouping: IMasterScheduleGroupingType) => {
      if(!this.settings) {
        this.restoreSettings();
      }
      this.settings.display.positionGrouping = grouping;
      this.saveGrouping();
      this.masterScheduleToolbarService.settingsChanged(this.settings);
  });

    this.startCycleSubscription = this.managementService.onStartCycleSelect$.subscribe(() => {
      this.onStartCycleSelect();
    });
    this.endCycleSubscription = this.managementService.onNonStartCycleSelect$.subscribe(() => {
      this.onNonStartCycleSelect();
    });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onStartCycleSelect(): void {
    this.isStartCycleDate = true;
  }

  public onNonStartCycleSelect(): void {
    this.isStartCycleDate = false;
  }

  public onExport(): void {
    this.masterScheduleGridComponent.onExport();
  }

  public onFetchSchedule(schedule: EmployeeScheduleDefinition[]): void {
    if (!schedule) {
      this.masterScheduleActions.fetchMasterScheduleData();
      return;
    }
    this.masterScheduleActions.fetchEmployeesScheduleSuccess(this.actions, schedule);
    this.masterScheduleActions.fetchTotals();
  }

  public restoreSettings(): void {
    this.settings = new MasterScheduleSettings();
    this.settings.columns = new MasterScheduleColumnsSettings();
    this.settings.columns.createColumns();
    const totalsState = this.stateManagementService.getControlState(this.totalsSettingsId);
    if (totalsState.value) {
      this.settings.totals = totalsState.value;
    } else {
      this.settings.totals = new MasterScheduleTotalsSettings();
    }
    const displaySettings = this.stateManagementService.getControlState(this.displaySettingsId);
    if (totalsState.value) {
      this.settings.display = displaySettings.value;
    } else {
      this.settings.display = new MasterScheduleDisplaySettings();
    }

    this.columnSettingsStorageService.getColumnsState(this.componentId, this.groupId, this.settings.columns.columns);
  }
  public saveSettings(): void {
    const defaultViewState = this.stateManagementService.getControlState(this.msGridDefaultViewState);
    if (!defaultViewState.value) {
      this.defaultDisplayView();
    }
    this.stateManagementService.setControlState(this.totalsSettingsId, { value: this.settings.totals }, StateResetTypes.None);
    this.stateManagementService.setControlState(this.displaySettingsId, { value: this.settings.display }, StateResetTypes.None);
    this.columnSettingsStorageService.setColumnsState(this.componentId, this.groupId, this.settings.columns.columns);
  }

  public defaultDisplayView(): void {
    this.settings = new MasterScheduleSettings();
    this.settings.columns = new MasterScheduleColumnsSettings();
    this.settings.columns.isDefaultViewUpdated = true;
    this.stateManagementService.setControlState(this.msGridDefaultViewState, { value: this.settings.columns.isDefaultViewUpdated }, StateResetTypes.None);
    this.settings.columns.createColumns();
  }

  public restoreFilters(): void {
    this.filters = new MasterScheduleFilters();
    const filtersState = this.stateManagementService.getControlState(this.filtersId);
    this.filters.filters = new MasterScheduleLookupFilters();
    if (filtersState) {
      Object.assign(this.filters.filters, filtersState.value);
    }

    if (MasterScheduleManagementService.firstLoad) {
      this.filters.dateFrom = null;
      MasterScheduleManagementService.firstLoad = false;
    } else {
      const dateState = this.stateManagementService.getControlState(this.filtersDateId);
      if (!dateState) {
        this.filters.dateFrom = null;
      } else {
        this.filters.dateFrom = moment(dateState.value,appConfig.dateFormat).toDate();
      }
    }

    const filtersWeeksState = this.stateManagementService.getControlState(this.filtersWeeksId);
    if (!filtersWeeksState) {
      this.filters.weekNumber = 4;
    } else {
      this.filters.weekNumber = filtersWeeksState.value;
    }

    const filtersGroupingState = this.stateManagementService.getControlState(this.groupingSettingsId);
    if (filtersGroupingState && filtersGroupingState.value) {
      this.filters.groupBy = filtersGroupingState.value;
    } else {
      this.filters.groupBy = MasterScheduleGroupingTypes.ByPosition;
    }

    const selectedEmployeeRowIdState = this.stateManagementService.getControlState(this.selectedEmployeeRowId);
    if (selectedEmployeeRowIdState && selectedEmployeeRowIdState.value) {
      this.filters.selectedCell = selectedEmployeeRowIdState.value;
    } else {
      this.filters.selectedCell = null;
    }

    const filterShowFTEState = this.stateManagementService.getControlState(this.filtersShowFTE);
    if (filterShowFTEState && filterShowFTEState.value) {
      this.filters.showTotalsFTEs = filterShowFTEState.value;
    } else {
      this.filters.showTotalsFTEs = null;
    }
  }

  public saveFilters(): void {
    this.stateManagementService.setControlState(this.filtersId, { value: this.filters.filters }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
    this.stateManagementService.setControlState(this.filtersDateId, { value: moment(this.filters.dateFrom).format(appConfig.dateFormat) }, StateResetTypes.SessionEnd);
    this.stateManagementService.setControlState(this.filtersWeeksId, { value: this.filters.weekNumber }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
    this.stateManagementService.setControlState(this.filtersShowFTE, { value: this.filters.showTotalsFTEs }, StateResetTypes.None);
  }

  public saveGrouping(): void {
    this.stateManagementService.setControlState(this.groupingSettingsId, { value: this.settings.display.positionGrouping }, StateResetTypes.SessionEnd);
  }

  public saveSelectedRow(cellInfo: IMasterScheduleCell): void {
    if (cellInfo)
      this.stateManagementService.setControlState(this.selectedEmployeeRowId, { value: cellInfo }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange);
  }

}
