import { environment } from './../environments/environment.local';
import * as moment from 'moment';
import { InjectionToken } from '@angular/core';
import { SignalrHubConfig } from './channel/models/index';
import { environment as config } from '../environments/environment';
import { ICacheConfig } from './common/models/cache/icache-config';

//const serverUrl: string = 'http://localhost:55863';
//const serverUrl: string = 'http://slxv6neudev66app01.azurewebsites.net';

export const APP_CONFIG = new InjectionToken<IApplicationConfig>('appConfig');

export interface IApplicationConfig {
  appVersion: string;
  cacheInvalidateAccessibleTime: number;
  cacheInvalidatePositionsTime: number;
  cacheInvalidateEmployeeDropDownTime: number;
  cacheInvalidateIndividualTimeCardTime: number;
  cacheInvalidateEmployeShortInfo: number;
  api: {
    url: string;
    urlTAServices: string;
    version: string;
    taVersion: string;
    version2: string;
    odata: string;
    sso: string;
    communicationsSignalRUrl: string;
    communicationsApiUrl: string;
    communicationsApiVersion: string;
    identityUrl: string;
  };
  b2sSSoLoginKey: string;
  notifyPoolingInterval: number;
  serverDateFormat: string;
  serverTimezone: string;
  dateFormat: string;
  dateFormatShortYear: string;
  linkDateTimeFormat: string;
  linkDateFormat: string;
  linkTimeFormat: string;
  requestDate: string;
  requestDateTime: string;
  requestDateTimeWithMilliSeconds: string;
  requestTime: string;
  dateTimeFormatYearless: string;
  dateTimeFormatShortYear: string;
  monthDayDateFormat: string;
  dateTimeFormat: string;
  dateTimeFormatUS: string;
  timeFormat: string;
  timeFormatTight: string;
  hourMinutesFormat: string;
  isDatesDecimal: boolean;
  dayNumberFormat: string;
  dayFullNumberFormat: string;
  dayShortNameWeekDayFormat: string;
  dayFullNameWeekDayFormat: string;
  militaryDateTimeFormat: string;
  militaryTimeFormat: string;
  planDateTimeFormat: string;
  dateMonthTextFormat: string;
  dateDayWeekMonthTextDayYearFormat: string;
  dateMonthTextDayYearHourFormat: string;
  dateMonthTextDayYearHourFormat2: string;
  dateMonthTextDayYearFormat: string;
  dateFullMonthFormat: string;
  fullMonthYearFormat: string;
  notificationsHubConfig: SignalrHubConfig;
  mobileMaxWidth: number;
  implementedAppDashboards: [string, string, string, string, string];
  devices: {
    mobileWidth: {
      min?: number,
      max: number
    },
    tabletWidth: {
      min: number,
      max: number
    },
    desktopWidth: {
      min: number,
      max?: number
    }
  };
  headerHeight: number;
  headerTimeclocksCount: number;
  minCorrectDate: string;
  maxCorrectDate: string;
  uiNotificationOptions: any;
  appInsightInstrumentationKey: string;
  showMessagesNotifications: boolean;
  showMessageCenterToolbarIcon: boolean;
  showSearchButton: boolean;
  showReminders: boolean;
  showMobileMarket: boolean;
  showChangesTimeoutMs: number;
  cacheConfig: {
    longTerm: ICacheConfig;
    midTerm: ICacheConfig;
    shortTerm: ICacheConfig;
    default: ICacheConfig;
  };
  links: {
    supportLink: string;
    helpLink: string;
    promoInfoLink: string;
    appLink: string
  };
  session: {
    maxExpiredSessionLoginAttempts: number;
    enableInactivityMonitorInDevMode: boolean;
    checkTokenExpirationPeriod: number;
    startRenewUntilSessionExpiredSeconds: number;
  };
  globalMenuItemsApplication: {
    id: number;
    name: string;
    title: string;
    link: string;
  };
  moreV5ItemsApplication: {
    id: number;
    name: string;
    db_menu_name: string;
    title: string;
    link: string;
  };
  extraApplicationsInfo: {
    benAdmin: {
      name: string;
      accessDeniedMessage: string;
    };
    hrms: {
      dashboards: {
        leaveManagement: {
          link: string;
        };
      }
    }
  };
  popupHeaderHeight: number;

  licenses: {
    froalaEditor: string;
    googleMapsApi: string;
  };

  debugging: {
    customHeaderForPost: {
      name: string;
      disabled: boolean;
      method: string;
    };
  };
}

export const appConfig: IApplicationConfig = {
  appVersion: '6.68.0',
  cacheInvalidateAccessibleTime: 60,
  cacheInvalidatePositionsTime: 60,
  cacheInvalidateEmployeeDropDownTime: 60,
  cacheInvalidateIndividualTimeCardTime: 60,
  cacheInvalidateEmployeShortInfo: 120,
  api: {
    url: `${config.API}/api`,
    urlTAServices: `${config.TA_SERVICES_API}/api`,
    version: 'v1',
    taVersion: 'v1',
    version2: 'v2',
    odata: 'odata',
    sso: 'sso/v5',
    communicationsSignalRUrl: `${config.API}/api/v2/communications`,
    communicationsApiUrl: `${config.API}/api/v2/communications/api`,
    communicationsApiVersion: '1.0',
    identityUrl: config.IDENTITY_API_URL
  },
  b2sSSoLoginKey: 'isB2cSsoLogin',
  notifyPoolingInterval: 300000,
  serverTimezone: 'America/Chicago',
  serverDateFormat: 'YYYY-MM-DD',
  dateFormat: 'MM/DD/YYYY',
  dateFormatShortYear: 'MM/DD/YY',
  linkDateTimeFormat: 'MMDDYYYYhhmmA',
  linkDateFormat: 'MMDDYYYY',
  linkTimeFormat: 'hhmmA',
  monthDayDateFormat: 'MM/DD',
  requestDate: 'MM-DD-YYYY',
  requestDateTime: 'MM-DD-YYYY HH:mm:ss',
  requestDateTimeWithMilliSeconds:'MM-DD-YYYY HH:mm:ss.SSS',
  requestTime: 'HH:mm:ss',
  isDatesDecimal: true,
  dateTimeFormatYearless: 'MM/DD hh:mm A',
  dateTimeFormat: 'MM/DD/YYYY HH:mm',
  dateTimeFormatUS: 'MM/DD/YYYY hh:mm A',
  dateTimeFormatShortYear: 'MM/dd/yy hh:mm tt',
  timeFormat: 'hh:mm A',
  timeFormatTight: 'hh:mmA',
  hourMinutesFormat: 'HH:mm',
  dayNumberFormat: 'D',
  dayFullNumberFormat: 'DD',
  dayShortNameWeekDayFormat: 'ddd',
  dayFullNameWeekDayFormat: 'dddd',
  dateMonthTextFormat: 'MMM',
  dateDayWeekMonthTextDayYearFormat: 'dddd, MMM DD, YYYY',
  dateMonthTextDayYearHourFormat: 'MMM D YYYY hh:mmA',
  dateMonthTextDayYearHourFormat2: 'MMM D, YYYY - hh:mm A',
  dateMonthTextDayYearFormat: 'MMM D, YYYY',
  dateFullMonthFormat: 'MMMM DD, YYYY',
  fullMonthYearFormat: 'MMMM, YYYY',
  militaryDateTimeFormat: 'MM/DD/YYYY hh:mm:ss A',
  militaryTimeFormat: 'HH:mm:ss',
  planDateTimeFormat: 'ddd MMM D YYYY',
  notificationsHubConfig: {
    url: `${config.API}/signalr`,
    hubName: 'notificationsHub',
  },
  mobileMaxWidth: 1023,
  implementedAppDashboards: [
    'Scheduler',
    'Time',
    'HR',
    'Reports',
    'PBJ'
  ],
  devices: {
    mobileWidth: {
      max: 600
    },
    tabletWidth: {
      min: 601,
      max: 1024
    },
    desktopWidth: {
      min: 1025,
    }
  },
  headerHeight: 40,
  headerTimeclocksCount: 5,
  minCorrectDate: '01/01/1900',
  maxCorrectDate: '06/06/2079',
  uiNotificationOptions: {
    position: ['bottom', 'right'],
    timeOut: 1000,
    lastOnBottom: true,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  },
  appInsightInstrumentationKey: config.APP_INSIGHT_INSTRUMENTATION_KEY,
  showMessagesNotifications: false,
  showMessageCenterToolbarIcon: true,
  showReminders: false,
  showSearchButton: false,
  showMobileMarket: false,
  showChangesTimeoutMs: 1000,
  cacheConfig: {
    longTerm: {
      expires: 240
    },
    midTerm: {
      expires: 60
    },
    shortTerm: {
      expires: 10
    },
    default: {
      expires: 10
    }
  },
  links: {
    supportLink: 'http://support.smartlinxsolutions.com',
    helpLink: 'https://smartlinx.knack.com/training-tracker#smartlinx-university-upcoming-trainings/',
    promoInfoLink: config.MARKETING_PAGE_LNK,
    appLink: config.APP_UI_URL
  },
  session: {
    maxExpiredSessionLoginAttempts: 3,
    enableInactivityMonitorInDevMode: false,
    checkTokenExpirationPeriod: 5,
    startRenewUntilSessionExpiredSeconds: 60
  },
  globalMenuItemsApplication: {
    id: -1,
    name: 'common',
    title: 'Common',
    link: 'common'
  },
  moreV5ItemsApplication: {
    id: -2,
    name: 'more',
    db_menu_name: 'more_apps',
    title: 'More Apps...',
    link: 'v1/sso/v5/'
  },
  extraApplicationsInfo: {
    benAdmin: {
      name: 'benadmin',
      accessDeniedMessage: 'We are sorry but you do not have access to Benefits. Please contact your HR administrator for more information'
    },
    hrms: {
      dashboards: {
        leaveManagement: {
          link: 'leave_management'
        }
      }
    }
  },
  popupHeaderHeight: 40,

  licenses: {
    froalaEditor: config.FROALA_EDITOR_LICENCE,
    googleMapsApi: config.GOOGLE_MAPS_API_LICENSE
  },

  debugging: {
    customHeaderForPost: {
      name: 'DebugStringHeader',
      disabled: false,
      method: 'POST'
    }
  }
};
