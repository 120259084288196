<slx-spinner [show]="state.isLoading" [showMessage]="state.showSpinnerMessage">

    <div class="btn-align duplicate-workers-header">
        <div>
            <slx-dropdown-list class="slx-wide slx-small-label" [options]="recordControls" placeholder ="Show Records" valueField="value" titleField="text" [(ngModel)]="dispoState" (ngModelChange)="getFilteredRecords($event)">
                </slx-dropdown-list>
        </div>
        <span class="d-flex">
         <button type="button" (click)="getRefreshedRecords()" class="slx-button slx-min-w90 slx-margin-l refresh-btn">Refresh</button>   
         <i aria-hidden="true" class="fa fa-info-circle info-icon refresh-info-icon"><span [slxTooltip]="getRefreshToolTip()" [tipClass]="'wide-tip'" tipPosition="bottom"></span></i>
       
         <button type="button" class="hideShowIcon slx-toolbar" [popper]="columnsToggler"
            [popperTrigger]="'click'" [popperPlacement]="'bottom-start'" [popperDisableStyle]="true">
            <i class="fas fa-sliders-h" aria-hidden="true"></i>
        </button>
        <popper-content #columnsToggler>
            <div class="slx-pbj-popper">
            <p class="pbj-popper-title">Duplicate Workers Columns to Display</p>
            <div class="button-container">
                <div *ngFor="let column of colNames" class="col-12 col-to-hide d-flex">
                    <input class="mt-0 check-styling" type="checkbox" id="{{ column }}" kendoCheckBox
                        [checked]="!isHidden(column)" (change)="hideColumn(column)" />
                    <label class="k-checkbox-label col-align" for="{{ column }}">{{ column }}</label>
                </div>
                <button class="slx-button slx-margin-r" type="button" (click)="selectClearCols('select')"> Select All </button>
                <button class="slx-button" type="button"(click)="selectClearCols('clear')"> Clear All</button>
            </div>
            </div>
        </popper-content>
        <span class="action-btns">
            <slx-actions-list>
                <slx-actions-button className="low-height auto-width" [popperContent]="Actionsettings"
                    [popperPosition]="'bottom-end'">
                    <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left: 10px">Actions</span>
                </slx-actions-button>
                <popper-content #Actionsettings>
                    <slx-actions-list-item (click)="exportExcel()">Export to Excel</slx-actions-list-item>
                    <slx-actions-list-item (click)="openExportMatchesDialog(true)">Export Matches</slx-actions-list-item>
                </popper-content>
            </slx-actions-list>
        </span>
       </span>
    </div>
    <ng-template #template let-anchor>
      Highest point score (maximum of 26 points) is the most likely match. Lowest point score (minimum of 4 points) is the least likely match. Fuzzy Match logic is used comparing different data points such as Name, Email, SSN, DOB, Phone, etc.
      </ng-template>

      <span kendoTooltip class="hide-scroll"
      showOn="none"
      [tooltipTemplate]="template"
      filter=".k-grid td"
      (mouseover)="showTooltip($event)"
        >
    <kendo-grid #exportKendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height duplicate-workers-grid" [data]="gridState.view" [pageable]="true" [pageSize]="pageSize" [skip]="gridState.state.skip"   (pageChange)="pageChange($event)"
        [reorderable]="true" (columnReorder)="restrictReorder($event)" [filterable]="'menu'"
        [filter]="gridState.state.filter"  (excelExport)="onExcelExport($event)"
        (filterChange)="filterDuplicates($event)" [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort"  (sortChange)="sortChange($event)" (dataStateChange)="gridState.dataStateChange($event)">

        <kendo-grid-column field="disposition" title="Disposition" width="150" [locked]="true" >

            <ng-template kendoGridCellTemplate let-dataItem>
                <slx-dropdown-list class="slx-wide slx-small-label" [options]="dispoOptions"
                    [ngModel]="setSelectedPosition(dataItem.disposition)" valueField="value" titleField="text"
                    (ngModelChange)="handleDisposition($event, dataItem)">
                </slx-dropdown-list>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="disposition_Date" title="Disposition Date" filter="date" width="160" [locked]="isDispositionDateLocked">
            <ng-template kendoGridCellTemplate let-dataItem>
               {{ dataItem.disposition_Date!=null ? (dataItem.disposition_Date | amDateFormat: appConfig.dateFormat) : "" }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="matching_score" title="Match Score" filter="text" width="200" *ngIf="!isHidden('Match Score')">
            <ng-template kendoGridHeaderTemplate>
              <span class="text-align">Match Score</span>  <i aria-hidden="true" class="fa fa-info-circle info-icon match-score"></i>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.matching_score}}
            </ng-template>       
            </kendo-grid-column>

        <kendo-grid-column field="location" title="Location" filter="text" width="120" *ngIf="!isHidden('Location')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.location}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pbJ_ID_1" title="PBJ ID 1" filter="text" width="200" *ngIf="!isHidden('PBJ ID 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a [employeeSectionLink]="dataItem?.emp_id_1">{{dataItem.pbJ_ID_1}}</a>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="last_name_1" title="Last Name 1" filter="text" width="200" *ngIf="!isHidden('Last Name 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.last_name_1}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="first_Name_1" title="First Name 1" filter="text" width="200" *ngIf="!isHidden('First Name 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.first_Name_1}}
            </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-column field="email_1" title="Email 1" filter="text" width="200" *ngIf="!isHidden('Email 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.email_1}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="phone_1" title="Phone 1" filter="text" width="200" *ngIf="!isHidden('Phone 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.phone_1}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="birthday_1" title="Birthdate 1" filter="date" width="200" *ngIf="!isHidden('Birthdate 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.birthday_1 | amDateFormat: appConfig.dateFormat}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="ssN_1" title="SSN 1" filter="text" width="200" *ngIf="!isHidden('SSN 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.ssN_1}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="primary_Location_1" title="Primary Location 1" filter="text" width="200" *ngIf="!isHidden('Primary Location 1')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.primary_Location_1}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pbJ_ID_2" title="PBJ ID 2" filter="text" width="200" *ngIf="!isHidden('PBJ ID 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a [employeeSectionLink]="dataItem?.emp_id_2">{{dataItem.pbJ_ID_2}}</a>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="last_name_2" title="Last Name 2" filter="text" width="200" *ngIf="!isHidden('Last Name 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.last_name_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="first_Name_2" title="First Name 2" filter="text" width="200" *ngIf="!isHidden('First Name 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.first_Name_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email_2" title="Email 2" filter="text" width="200" *ngIf="!isHidden('Email 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.email_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="phone_2" title="Phone 2" filter="text" width="200" *ngIf="!isHidden('Phone 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.phone_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="birthday_2" title="Birthdate 2" filter="date"  width="200"
            *ngIf="!isHidden('Birthdate 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.birthday_2 | amDateFormat: appConfig.dateFormat}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="ssN_2" title="SSN 2" filter="text" width="200" *ngIf="!isHidden('SSN 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.ssN_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="primary_Location_2" title="Primary Location 2" filter="text" width="200" *ngIf="!isHidden('Primary Location 2')">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.primary_Location_2}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-excel [fetchData]="getExportData" [fileName]="xlsxName"></kendo-grid-excel>

    </kendo-grid>
    </span>
</slx-spinner>