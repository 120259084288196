import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable, Injector } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../../core/services/index';

import { OrgLevel } from '../../../../state-model/models/index';
import { ServerFilterService, addObjectToFilter } from '../../../../core/services/index';
import { mutableSelect, unsubscribeInService } from '../../../../core/decorators/index';
import {
  IFilteredItems,
  ServerCompositeFilterDescriptor,
  PagingData,
  MetadataInfo,
  ServerQuery,
} from '../../../../core/models/index';
import { PbjExportApiService } from './pbj-export-api.service';
import { PBJBatchExport, PBJExportLogRecord, PBJExportRequest, PBJExportResult } from '../../models/index';
import { PBJExportParentGrid } from '../../models/pbj-export/pbj-export-parent-grid';
import { PBJExportResultFlagEnabled } from '../../models/pbj-export/pbj-export-result-flag-enabled';
import { PBJExportChildResult } from '../../models/pbj-export/pbj-export-child-result';
import { PBJExportChildGrid } from '../../models/pbj-export/pbj-export-child-grid';
import { FileService } from '../../../../common';
import { PBJChildDownload, PBJDownloadBulkExport, PBJDownloadExcelPdf, PBJDownloadSummary } from '../../models/pbj-export/pbjChildGridDownload';
import { ModalService } from '../../../../common/services/index';

import { PbjNewExportWarningDialogComponent } from '../../components/pbj-export/pbj-new-export-warning-dialog/pbj-new-export-warning-dialog/pbj-new-export-warning-dialog.component';
import { NotificationsService } from '../../../../core/components';
import { appMessages } from '../../../../app.messages';
import { IPBJExportCSV } from '../../models/pbj-export/pbj-export-csv';
import { PbjExportMapService } from './pbj-export-map.service';
import { PbjNewExportWarningDialogOptions } from '../../models/pbj-export/Pbj-new-export-warning-options';
import { PBJDuplicateWorkers } from '../../models/pbj-duplicate-workers/pbj-duplicate-workers';


@Injectable()
export class PbjExportLogManagementService extends ManagementBaseService<IFilteredItems<PBJExportLogRecord>, any> {
  public exportPerformed$: Subject<PBJExportResult>;
  public batchExportPerformed$: Subject<PBJExportResultFlagEnabled>;
  public parentGridExport$: Subject<PBJExportParentGrid[]> = new Subject<PBJExportParentGrid[]>();
  public childGridExport$: Subject<PBJExportChildResult[]> = new Subject<PBJExportChildResult[]>();
  public pbjDuplicateRefresh$ : Subject<any[]> = new Subject<[]>();
  public currentOrgLevel: OrgLevel;
  public pagingData: PagingData;
  public sort: SortDescriptor[];
  private hasFullDateRange: boolean;
  @unsubscribeInService()
  private filterChangeSubscription: Subscription;
  public IsPBJExportCorpLevelEnabled: boolean;
  public startDate: Date;
  public endDate: Date;
  public filteredData$ = new Subject<PBJExportParentGrid[]>();
  public realtimeFeedback$ = new Subject<PBJExportParentGrid[]>();
  public downloadOptionsFeedback$ = new Subject<any>();
  public collapseAll$ = new Subject<number[]>();
  public expandedBatchIds: number[] = [];

  constructor(
    private apiService: PbjExportApiService,
    private serverFilterService: ServerFilterService,
    private fileService: FileService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private mapService: PbjExportMapService,
  ) {
    super();
    this.exportPerformed$ = new Subject<PBJExportResult>();
    this.pagingData = { take: 50, skip: 0 };
    this.filterChangeSubscription = this.serverFilterService.changes$.subscribe(
      (filter: ServerCompositeFilterDescriptor) => {
        this.refreshData();
      }
    );
  }

  public refreshData(): void {
    if (!this.currentOrgLevel) {
      return;
    }
    this.onLoadStatusChanged(true);
    if (this.IsPBJExportCorpLevelEnabled != undefined && this.IsPBJExportCorpLevelEnabled != null && !this.IsPBJExportCorpLevelEnabled) {
      this.apiService
        .getPBJExportLogRecords(
          this.serverFilterService.createQuery(this.pagingData, this.sort),
          this.currentOrgLevel.id
        )
        .then((val: IFilteredItems<PBJExportLogRecord>) => {
          this.onLoadStatusChanged(false);
          this.onLoaded(val);
        })
        .catch((reason: any) => {
          this.onError(reason);
        });
    }
    if (this.IsPBJExportCorpLevelEnabled != undefined && this.IsPBJExportCorpLevelEnabled != null && this.IsPBJExportCorpLevelEnabled) {
      let data: PBJBatchExport = new PBJBatchExport();
      data.startDate = this.startDate;
      data.endDate = this.endDate;
      data.orgLevelId = this.currentOrgLevel.id;

      this.apiService
        .getBatchIDExportData(data)
        .then((val: PBJExportParentGrid[]) => {
          this.onLoadStatusChanged(false);
          _.forEach(val, (record, ind) => {
            _.forEach(this.expandedBatchIds, (id) => {
              if (id === record.batchId) {
                this.collapseAll$.next(this.expandedBatchIds);
              }
            })
          });
          this.filteredData$.next(val);
        })
        .catch((reason: any) => {
          this.onError(reason);
        });
    }
  }

  public performExport(request: PBJExportRequest): void {
    this.onLoadStatusChanged(true);
    this.apiService
      .performExport(request)
      .then((val: PBJExportResult) => {
        this.onLoadStatusChanged(false);
        this.exportPerformed$.next(val);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public performBatchExport(request: PBJExportRequest): void {
    this.onLoadStatusChanged(true);
    this.apiService
      .performBatchExport(request)
      .then((val: PBJExportResultFlagEnabled[]) => {
        this.onLoadStatusChanged(false);
        if (val[0].isSuccess) {
          let reqObj: PBJBatchExport = new PBJBatchExport();
          reqObj.startDate = request.startDate;
          reqObj.endDate = request.endDate;
          reqObj.orgLevelId = parseInt(request.orgLevelId);
          this.refreshData();
          this.getParentGridExportData(reqObj);
          this.notificationsService.success(
            `${appMessages.success.pbjExportStarted.title}`,
            appMessages.success.pbjExportStarted.message
          );
        } else {

          let csvData: IPBJExportCSV[] = this.mapService.mapPbjExportCSVRecords(val);

          csvData.sort(
            (a, b) =>
              a.Error_ID - b.Error_ID ||
              a.Error_Description.localeCompare(b.Error_Description) ||
              a.Location.localeCompare(b.Location)
          );
          console.log(csvData);
          const options: PbjNewExportWarningDialogOptions = new PbjNewExportWarningDialogOptions('Alert', csvData);

          PbjNewExportWarningDialogComponent.openDialog(options, this.modalService, (result: boolean) => {
            console.log('Trigger Pbj Export warning modal');
          });
        }
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public getParentGridExportData(request: PBJBatchExport) {
    this.onLoadStatusChanged(true);
    return this.apiService.getBatchIDExportData(request);
  }

  public getChildExportData(request: PBJExportChildGrid) {
    return this.apiService.getChildExportData(request);
  }

  public filterRangeChanged(fromFilter: Date, toFilter: Date): void {
    toFilter = new Date(toFilter);
    toFilter.setHours(23, 59, 59);
    this.hasFullDateRange = Boolean(fromFilter && toFilter);
    this.serverFilterService.removeFilter('exportDate');
    if (fromFilter) {
      this.serverFilterService.composeFilter({ field: 'exportDate', operator: 'gte', value: fromFilter });
      this.startDate  = fromFilter;
    }
    if (toFilter) {
      this.serverFilterService.composeFilter({ field: 'exportDate', operator: 'lte', value: toFilter });
      this.endDate  = toFilter;
    }
  }

  public applyFilter(): void {
    if (this.hasFullDateRange) this.serverFilterService.applyFilter();
  }

  public orgLevelChanged(orgLevel: OrgLevel): void {
    this.currentOrgLevel = orgLevel;
    this.serverFilterService.removeFilter('orgLevelId');
    this.serverFilterService.composeFilter({ field: 'orgLevelId', operator: 'eq', value: this.currentOrgLevel.id });
    this.applyFilter();
  }

  public pageChanged(pagingData: PagingData): void {
    this.pagingData = pagingData;
    this.refreshData();
  }

  public sortChanged(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshData();
  }

  public async downloadChildExportData(exportId: PBJChildDownload): Promise<void> {
    this.onLoadStatusChanged(true);
    try {
      const file = await this.apiService.downloadAllChildFiles(exportId);
      this.fileService.saveToFileSystem(file.blob, file.file);
    this.onLoadStatusChanged(false);
    } catch (e) {
      console.error(e);
    } finally {
    this.onLoadStatusChanged(false);
    }
  }

  public async downloadExportSummaryData(exportId: PBJDownloadSummary): Promise<void> {
    this.onLoadStatusChanged(true);
    try {
      const file = await this.apiService.downloadExportSummaryExcel(exportId);
      this.fileService.saveToFileSystem(file.blob, file.file);
      this.onLoadStatusChanged(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.onLoadStatusChanged(false);
    }
  }

  public async downloadBulkExportFile(exportId: PBJDownloadBulkExport): Promise<void> {
    this.onLoadStatusChanged(true);
    try {
      const file = await this.apiService.downloadBulkExportSummary(exportId);
      this.fileService.saveToFileSystem(file.blob, file.file);
      this.onLoadStatusChanged(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.onLoadStatusChanged(false);
    }
  }

  public async downloadExcelPDF(exportObj: PBJDownloadExcelPdf): Promise<void> {
    this.onLoadStatusChanged(true);
    let file:any;
    try {
      if(exportObj.type === "pdf"){
        file = await this.apiService.downloadPDFFile(exportObj);
      }else{
        file = await this.apiService.downloadExcelFile(exportObj);
      }
      if(file.blob != null){
        this.fileService.saveToFileSystem(file.blob, file.file);
      }else{
        this.notificationsService.info(
          "Your download is being processed, please wait!"
        );
      }
      this.onLoadStatusChanged(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.onLoadStatusChanged(false);
    }
  }

  public showBulkExportWarn() {
    this.notificationsService.info(
      "The selected Batch ID does not include the Staffing Hours section.  The Staffing Hours section is required to produce a bulk export file.  Please select a Batch ID that includes the Staffing Hours section to proceed.", '', true, 7000
    );
  }
}
