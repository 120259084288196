import * as _ from 'lodash';

import { IColumnSettings } from '../../../core/models/index';
import { MasterScheduleGroupingTypes, IMasterScheduleGroupingType } from './master-schedule-grouping-type';

export class MasterScheduleColumnsSettings {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public isDefaultViewUpdated: boolean = false;
  public createColumns(): void {
    this.columns = [
      {
        name: 'positionGroupName',
        description: 'Position Group',
        displayed: false
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true
      },
      {
        name: 'dateOfHire',
        description: 'Date of Hire',
        displayed: false
      },
      {
        name: 'dateTerminated',
        description: 'Date of Termination',
        displayed: false
      },
      {
        name: 'avgWeeklyRotationsHrs',
        description: 'Avg Weekly Hours',
        displayed: true
      },
      {
        name: 'shift',
        description: 'Shift',
        displayed: false
      },
      {
        name: 'employeeType',
        description: 'Employee Type',
        displayed: true
      }
    ];
    this.mapColumns();
  }
  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
}

export class MasterScheduleTotalsSettings {
  public weeklyTotals: boolean = true;
  public positionSubtotals: boolean = true;
  public dailyTotals: boolean = true;
  public idealSchedule: boolean = true;
  public openShifts: boolean = true;
  public difference: boolean = true;
  public census: boolean = true;
  public scheduledPpd: boolean = true;
  public staffingRatio: boolean = true;
  public actualHours: boolean = true;
  public actualPpd: boolean = true;
  public budgetedPars: boolean = true;
  public viewTotalsFTEs:boolean = false;
}

export class MasterScheduleDisplaySettings {
  public cellDisplay: string = 'unitName';
  public displayOrder: string = 'name';
  public separateSeconaryPositions: boolean = false;
  public pinTotalRows: boolean = true;
  public positionGrouping: IMasterScheduleGroupingType = MasterScheduleGroupingTypes.ByPosition;
}
export class MasterScheduleSettings {

  public display: MasterScheduleDisplaySettings;
  public columns: MasterScheduleColumnsSettings;
  public totals: MasterScheduleTotalsSettings;
  public displayOrderList = [
    {
      id: 'name',
      name: 'Name'
    },
    {
      id: 'seniority',
      name: 'Seniority'
    }
  ];

  public displayList = [
    {
      id: 'shiftTimes',
      name: 'Shift (0700A-0300P)'
    },
    {
      id: 'shiftName',
      name: 'Shift (7A-3P)'
    },
    {
      id: 'shiftNameAndUnit',
      name: 'Shift (7A-3P|FL1)'
    },
    {
      id: 'unitName',
      name: 'Unit (FL1)'
    },
    {
      id: 'shiftDurationHours',
      name: 'Duration(h)'
    },
    {
      id: 'shiftDurationMin',
      name: 'Duration(m)'
    }
  ];

  public groupByList = [
    MasterScheduleGroupingTypes.ByPosition,
    MasterScheduleGroupingTypes.ByPositionGroup
  ];
}
