import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../../app.config';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { AuditTrailManagementService } from '../../../services/audit-trail/audit-trail-management.service';
import { AuditTrailEventCmd } from '../../../models/audit-trail/audit-trail-event';
import { DatePipe } from '@angular/common';
var AuditTrailGridComponent = /** @class */ (function () {
    function AuditTrailGridComponent(changeDetector, managementService, datePipe) {
        this.changeDetector = changeDetector;
        this.managementService = managementService;
        this.datePipe = datePipe;
        this.gridPageChanged = new EventEmitter();
        this.gridSortChanged = new EventEmitter();
        this.pageSize = 50;
        this.useTZnotOffset = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'auditDateTime', dir: 'desc' }];
        this.gridState.state.group = [];
    }
    Object.defineProperty(AuditTrailGridComponent.prototype, "gridView", {
        set: function (value) {
            this.records = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuditTrailGridComponent.prototype, "skip", {
        set: function (value) {
            if (value === undefined) {
                return;
            }
            this.gridState.state.skip = value;
        },
        enumerable: true,
        configurable: true
    });
    AuditTrailGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.actionSubscription = this.managementService.subscribeToActions(function (event) {
            if (event.cmd === AuditTrailEventCmd.excelExport) {
                event.payload.items.forEach((function (item) {
                    item.auditDateTime = _this.datePipe.transform(item.auditDateTime, 'MM/dd/yyyy hh:mm a');
                }));
                _this.exportData = event.payload;
                _this.grid.saveAsExcel();
            }
            if (event.cmd === AuditTrailEventCmd.pdfExport) {
                _this.grid.saveAsPDF();
            }
        });
        this.useTZnotOffsetSubscription = this.managementService.useTZnotOffset$.subscribe(function (value) {
            _this.useTZnotOffset = value;
        });
        this.currentTimezoneCodeSubscription = this.managementService.currentTimezoneCode$.subscribe(function (value) {
            _this.currentTimezoneCode = value;
        });
    };
    AuditTrailGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.useTZnotOffsetSubscription.unsubscribe();
        this.currentTimezoneCodeSubscription.unsubscribe();
    };
    AuditTrailGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.gridPageChanged.emit({ take: event.take, skip: event.skip });
    };
    AuditTrailGridComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.gridSortChanged.emit(this.gridState.state.sort);
    };
    AuditTrailGridComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AuditTrailGridComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            return {
                data: _this.exportData.items,
                group: _this.gridState.state.group
            };
        };
    };
    AuditTrailGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = { data: this.records.items, total: this.records.count };
    };
    AuditTrailGridComponent.prototype.excelExport = function ($event) {
        var sheet = $event.workbook.sheets[0];
        sheet.freezePane = { rowSplit: 1, colSplit: 0 };
        for (var rowIndex = 0; rowIndex < sheet.rows.length; rowIndex++) {
            var row = sheet.rows[rowIndex];
            for (var colIndex = 0; colIndex < row.cells.length; colIndex++) {
                row.cells[colIndex].wrap = true;
            }
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailGridComponent.prototype, "actionSubscription", void 0);
    return AuditTrailGridComponent;
}());
export { AuditTrailGridComponent };
