import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription } from 'rxjs';
import { KendoGridStateHelper, ModalService, PbjNavigationService } from '../../../../common';
import { unsubscribe } from '../../../../core/decorators';
import * as _ from 'lodash';
import { PBJDuplicateWorkers } from '../../models/pbj-duplicate-workers/pbj-duplicate-workers';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { PbjExportApiService } from '../../services/pbj-export/pbj-export-api.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ColumnReorderEvent, GridComponent , PageChangeEvent} from '@progress/kendo-angular-grid';
import { DispositionState } from '../../models/pbj-duplicate-workers/pbj-disposition';
import { PbjDuplicateMatchesDialogOptions } from '../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options';
import { PbjDuplicateMatchesDialogComponent } from './pbj-duplicate-matches-dialog/pbj-duplicate-matches-dialog.component';
import { ExcelExportData, Workbook, WorkbookSheet } from '@progress/kendo-angular-excel-export';
import { ExcelExportEvent } from '@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event';
import * as kendo from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'slx-pbj-duplicate-workers',
  templateUrl: './pbj-duplicate-workers.component.html',
  styleUrls: ['./pbj-duplicate-workers.component.scss']
})
export class PbjDuplicateWorkersComponent implements OnInit {
  @unsubscribe()
  private routeSubscripion: Subscription;
  private orgLevelId: number = null;
  public data: PBJDuplicateWorkers[] = [];
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<PBJDuplicateWorkers>;
  @ViewChild('exportKendoGrid', { static: true })
  private grid: GridComponent;
  public pageSize = 50;
  public state :{
    isLoading: boolean;
    showSpinnerMessage : boolean;
  }
  colNames: string[];
  hiddenColumns: string[] = [];
  @ViewChild(TooltipDirective,  { static: true }) public tooltipDir: TooltipDirective;
  
  public recordControls: DispositionState[] = [
    {
      id: 0,
      text: "Pending",
      value: "Pending"
    },
    {
      id: 1,
      text: "Match",
      value: "Match"
    },
    {
      id: 3,
      text: "Not a Match",
      value: "Not a Match"
    },
    {
      id: 4,
      text: "All Records",
      value: "All Records"
    }
  ];
  public dispoOptions : DispositionState[] = [
    {
      id: 0,
      text: "Pending",
      value: "Pending"
    },
    {
      id: 1,
      text: "Match",
      value: "Match"
    },
    {
      id: 3,
      text: "Not a Match",
      value: "Not a Match"
    },
  ];
  xlsxName: string;
  dispoState = new DispositionState();
  allRecords: PBJDuplicateWorkers[];
  selectedDateForMatches:Date;
  exportMatches:boolean =false;
  public isDispositionDateLocked: boolean;
  constructor(private route: ActivatedRoute, private apiService: PbjExportApiService, private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<PBJDuplicateWorkers>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.take = this.pageSize;
    this.gridState.state.filter = null;
    this.gridState.state.group = [];
    this.appConfig = appConfig;
    this.dispoState = this.recordControls[0];
    this.gridState.view = { data: this.data, total: this.data.length };
    if(this.orgLevelId !=null && this.orgLevelId !=undefined) this.getDuplicateRecords();
    this.getExportData = this.getExportData.bind(this);
     }

  ngOnInit() {
    this.state ={
      isLoading : true,
    showSpinnerMessage : false
    }
    this.colNames = ["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
    this.isDispositionDateLocked = true;
    this.gridState.state.skip = 0;  
    this.routeSubscripion = this.route.params
      .combineLatest(this.route.queryParams)
      .subscribe(([params, queryParams]) => {
        const orgLevelId = +queryParams['orgLevelId'];
        if (_.isFinite(orgLevelId) && this.orgLevelId !== orgLevelId) {
          this.orgLevelId = orgLevelId;
          this.hiddenColumns=[];
          this.dispoState = this.recordControls[0];
          this.getDuplicateRecords();
        }
        if (!_.isFinite(this.orgLevelId) && _.isFinite(orgLevelId)) {
          this.orgLevelId = orgLevelId;
        }

      });
  }

  public getDuplicateRecords(){
    this.state.isLoading = true; 
    this.gridState.state.skip = 0;
    this.apiService.performDuplicateExport(this.orgLevelId).then((val: PBJDuplicateWorkers[]) => {
      this.loadDataToGrid(val);
    })
    .catch(err => {
      console.error(err);
      this.state.isLoading = false;
    });

  }

  public loadDataToGrid(data: PBJDuplicateWorkers[]){
    this.allRecords = data;
    for(let i =0; i< this.allRecords.length; i++){
      if(this.allRecords[i].disposition == null) this.allRecords[i].disposition = "Pending";
    }
    this.data = this.dispoState.value!="All Records" ? data.filter(x=> x.disposition == this.dispoState.value) : this.allRecords; 
    this.state.isLoading = false;
    this.refreshGrid();
  }

  public refreshGrid() {
    if (this.data === undefined || this.data.length == 0) this.gridState.view = null;
    if (this.data.length > 0) this.gridState.view = process(this.data, this.gridState.state);

  }

  public hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hiddenColumns.push(columnName);
    } else {
      this.hiddenColumns.splice(this.hiddenColumns.indexOf(columnName), 1);
    }
  }

  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public restrictReorder(event:ColumnReorderEvent) {
    if (event.newIndex === 0 || 
        event.newIndex === 1 ||event.column.title === "Disposition" || 
        event.column.title === "Disposition Date"){
      event.preventDefault();
    }
  }

  public handleDisposition(event, colDetails) {
    if(event.value!= colDetails.disposition && colDetails.disposition!=null){
    colDetails.disposition = event.text;
    colDetails.matching_org_id = this.orgLevelId;
    if(event.value == "Pending") colDetails.disposition_Date = null;
    this.state.isLoading = true;
    this.apiService.changeDisposition(colDetails).then((val: PBJDuplicateWorkers[]) => {
      this.loadDataToGrid(val);
    });
   }
  }

  public filterDuplicates(event) {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }

  public setSelectedPosition(dispoOption: string) {
    let obj = this.dispoOptions.find(e => e.value === dispoOption);
    if (obj) {
      return obj;
    }
    else {
      return this.dispoOptions[0];
    }
  }

  public selectClearCols(type){
   if(type == 'select'){ 
    this.isDispositionDateLocked = true;
    this.hiddenColumns=[];
   }
   if(type == 'clear') {
    this.isDispositionDateLocked = false;
    this.hiddenColumns=["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
  }
}
  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
  }

  public async exportExcel(){
    await this.getExportMatchesDefaultDate();
    this.exportMatches = false;
    this.xlsxName = `EeDupes_${this.getDate()}.xlsx`;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.data.length;
    this.grid.saveAsExcel();
  } 

  public getFilteredRecords(target){
    if(target!=undefined) {
      this.gridState.state.skip =0;
      this.gridState.state.filter={filters:[], logic:'and'};
      if(target.value == 'Pending') this.gridState.state.sort = []
      this.dispoState = this.recordControls.find(x=> x.value == target.value);
     if(this.allRecords != undefined) this.data = (target.value!= "All Records") ? this.allRecords.filter(x=> x.disposition == target.value) : this.allRecords;
    this.refreshGrid();
    }
  }

  public async getExportMatchesDefaultDate(){
  this.state.isLoading = true;
   await this.apiService.getExportMatchesDefaultDate(this.orgLevelId).then((val:any)=>{
      this.selectedDateForMatches = new Date(val.data);
    })
    this.state.isLoading = false;
  }

  public updateExportMatchesDefaultDate(pbjDuplicateWorkers:PBJDuplicateWorkers[]){
    this.apiService.updateExportMatchesDefaultDate(pbjDuplicateWorkers, this.orgLevelId).then((val:any)=>{
      this.state.isLoading = false;
    })
  }

  public async openExportMatchesDialog(matchingDupesFound:boolean){
    await this.getExportMatchesDefaultDate();
    const options: PbjDuplicateMatchesDialogOptions = new PbjDuplicateMatchesDialogOptions(this.selectedDateForMatches, matchingDupesFound);
    let dialogref = PbjDuplicateMatchesDialogComponent.openDialog(options, this.modalService, 
      (result: boolean) => {
        if(result){
          this.state.isLoading = true;
          this.selectedDateForMatches = dialogref.selectedDate;
          this.exportMatches = true;
          this.grid.saveAsExcel();
        }
      }
    );
  }

  public getExportData(): ExcelExportData {
    const result: ExcelExportData = {
        data: this.exportMatches ? process(this.allRecords.filter(x=> (x.disposition == 'Match' && (x.disposition_Date >=this.selectedDateForMatches || x.matches_Export_Date === null))), this.gridState.state).data : process(this.data, this.gridState.state).data,
    };
    return result;
  }

  public getDate():string{
    let date = new Date();
    let month = (date.getMonth()+1) < 10 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`;
    let todayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    let year = date.getFullYear().toString();
    let dateFormat = month+ todayDate + year; 
    return dateFormat;
  }

  public async onExcelExport(e:ExcelExportEvent){
    if(this.exportMatches){
      e.preventDefault();
      let fileName = `EeDupeMatches_${this.getDate()}.xlsx`;
      let workbook:Workbook = e.workbook;
      workbook.sheets[0].name = "All Data";
      let filteredData:ExcelExportData = this.getExportData();
      let filteredRows:any = [
        { cells: 
          [ 
            {background: '#7a7a7a', color: '#fff', value: 'oldEmployeeId', colSpan: 1, rowSpan: 1},
            {background: '#7a7a7a', color: '#fff', value: 'newEmployeeId', colSpan: 1, rowSpan: 1} 
          ],
          type: 'header'
        }
      ];
      filteredData.data.map((item:PBJDuplicateWorkers)=>{
        if(item.disposition_Date >= this.selectedDateForMatches){
          let row:any = {
            type: 'data',
            cells: [
              { value: item.pbJ_ID_1 }, { value: item.pbJ_ID_2 }
            ]
          }
          filteredRows.push(row);
        }
      })
      if(filteredRows.length > 1 ){
        let sheet:WorkbookSheet = {
          columns: [ { width: 150, autoWidth: false }, {width: 150, autoWidth: false} ],
          name: "EmployeeLink Data",
          rows: filteredRows
        }
        workbook.sheets.unshift(sheet);
        kendo.toDataURL(workbook).then((val:string)=>{
          saveAs(val,fileName);
        });
        let matchesExportData:PBJDuplicateWorkers[] = this.allRecords.filter(x=> x.disposition == 'Match' && (x.disposition_Date >=this.selectedDateForMatches || x.matches_Export_Date === null));
        this.updateExportMatchesDefaultDate(matchesExportData);
      } else {
        this.state.isLoading=false;
        this.openExportMatchesDialog(false);
      }
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public getRefreshedRecords(){
    this.state.isLoading = true;
    this.gridState.state.skip=0;
    this.state.showSpinnerMessage = true;
    this.apiService.getPBJEmployeeDuplicateRecordsRefresh (this.orgLevelId).then((val: PBJDuplicateWorkers[]) => {
      this.state.isLoading = false;
      this.state.showSpinnerMessage = false;
      this.loadDataToGrid(val);
    })
  }

  public getRefreshToolTip(): string {
    return `The data used in this process is populated by an overnight process. Click the refresh button to reflect the most recent change on the grid.`;
  }

  public getMatchScoreToolTip(): string {
  return `Highest point score (maximum of 26 points) is the most likely match. Lowest point score (minimum of 4 points) is the least likely match. Fuzzy Match logic is used comparing different data points such as Name, PBJ ID, SSN, DOB, Phone, etc.`
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (element.className.includes('fa-info-circle')) {
        this.tooltipDir.toggle(element);
    } else {
        this.tooltipDir.hide();
    }
}
}
    