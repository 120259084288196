/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../benefit-details-standart/benefit-details-standart.component.ngfactory";
import * as i4 from "../../../services/benefit-details/benefit-details-standart-management.service";
import * as i5 from "../../../services/benefit-details/benefit-details-management.service";
import * as i6 from "../../../services/benefit-details/benefit-details-permission.service";
import * as i7 from "../../../../../common/services/state-management/state-management.service";
import * as i8 from "../../../../../common/services/component-state/component-state-storage.service";
import * as i9 from "../../../services/benefit-details/benefit-details-api.service";
import * as i10 from "../../../services/benefit-employees/benefit-employees-api.service";
import * as i11 from "../../../../../common/services/file/file.service";
import * as i12 from "../../../services/benefit-eligibility-rules/benefit-eligibility-rules-management.service";
import * as i13 from "../../../services/benefit-details/calculation-common.service";
import * as i14 from "../benefit-details-standart/benefit-details-standart.component";
import * as i15 from "../../../../../common/services/modal/modal.service";
import * as i16 from "../../../../../../../node_modules/@progress/kendo-angular-layout/dist/es2015/index.ngfactory";
import * as i17 from "@progress/kendo-angular-layout";
import * as i18 from "@progress/kendo-angular-l10n";
import * as i19 from "../../../../../components-library/components/dropdown-list/dropdown-list.component.ngfactory";
import * as i20 from "../../../../../components-library/components/dropdown-list/dropdown-list.component";
import * as i21 from "@angular/forms";
import * as i22 from "./benefit-details-tabs.component";
import * as i23 from "../../../../../common/services/device-detector/device-detector.service";
var styles_BenefitDetailsTabsComponent = [i0.styles];
var RenderType_BenefitDetailsTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsTabsComponent, data: {} });
export { RenderType_BenefitDetailsTabsComponent as RenderType_BenefitDetailsTabsComponent };
function View_BenefitDetailsTabsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-sort-down selected-arrow"]], null, null, null, null, null))], null, null); }
function View_BenefitDetailsTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "slx-line-tabs__icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "em", [["class", "slx-line-tabs__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-line-tabs__icon"; var currVal_1 = _co.getIconClass(_v.parent.context.$implicit.type); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.isSelectedGroup(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.title.replace("401k", "401(k)"); _ck(_v, 4, 0, currVal_2); }); }
function View_BenefitDetailsTabsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-benefit-details-standart", [], null, null, null, i3.View_BenefitDetailsStandartComponent_0, i3.RenderType_BenefitDetailsStandartComponent)), i1.ɵprd(512, null, i4.BenefitDetailsStandartManagementService, i4.BenefitDetailsStandartManagementService, [i5.BenefitDetailsManagementService, i6.BenefitDetailsPermissionService, i7.StateManagementService, i8.ComponentStateStorageService, i9.BenefitDetailsApiService, i10.BenefitEmployeesApiService, i11.FileService, i12.BenefitEligibilityRulesManagementService, i13.CalculationCommonService]), i1.ɵdid(2, 245760, null, 0, i14.BenefitDetailsStandartComponent, [i15.ModalService, i5.BenefitDetailsManagementService, i6.BenefitDetailsPermissionService, i4.BenefitDetailsStandartManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_BenefitDetailsTabsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Benefit Group did not found"]))], null, null); }
function View_BenefitDetailsTabsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_6)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStandartGroup(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isUndefinedGroup(_v.parent.context.$implicit); _ck(_v, 3, 0, currVal_1); }, null); }
function View_BenefitDetailsTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "kendo-tabstrip-tab", [], null, null, null, i16.View_TabStripTabComponent_0, i16.RenderType_TabStripTabComponent)), i1.ɵdid(1, 1622016, [[1, 4]], 2, i17.TabStripTabComponent, [], { disabled: [0, "disabled"], selected: [1, "selected"] }, null), i1.ɵqud(603979776, 2, { _tabContent: 1 }), i1.ɵqud(335544320, 3, { tabTitle: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_BenefitDetailsTabsComponent_3)), i1.ɵdid(5, 16384, [[3, 4]], 0, i17.TabTitleDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_BenefitDetailsTabsComponent_5)), i1.ɵdid(7, 16384, [[2, 4]], 0, i17.TabContentDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditMode; var currVal_1 = _co.isSelectedGroup(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BenefitDetailsTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "kendo-tabstrip", [["class", "slx-line-tabs benefit-tabs"]], [[2, "k-widget", null], [2, "k-tabstrip", null], [2, "k-floatwrap", null], [2, "k-header", null], [2, "k-tabstrip-top", null], [2, "k-tabstrip-right", null], [2, "k-tabstrip-bottom", null], [2, "k-tabstrip-left", null], [1, "dir", 0]], [[null, "tabSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabSelect" === en)) {
        var pd_0 = (_co.onSelectTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_TabStripComponent_0, i16.RenderType_TabStripComponent)), i1.ɵprd(256, null, i18.L10N_PREFIX, "kendo.tabstrip", []), i1.ɵprd(131584, null, i18.LocalizationService, i18.LocalizationService, [i18.L10N_PREFIX, [2, i18.MessageService], [2, i18.RTL]]), i1.ɵdid(3, 245760, null, 1, i17.TabStripComponent, [i18.LocalizationService, i1.Renderer2, i1.ElementRef], null, { tabSelect: "tabSelect" }), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_9 = _co.groups; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).hostClasses; var currVal_1 = i1.ɵnov(_v, 3).hostClasses; var currVal_2 = i1.ɵnov(_v, 3).hostClasses; var currVal_3 = i1.ɵnov(_v, 3).hostClasses; var currVal_4 = i1.ɵnov(_v, 3).tabsAtTop; var currVal_5 = i1.ɵnov(_v, 3).tabsAtRight; var currVal_6 = i1.ɵnov(_v, 3).tabsAtBottom; var currVal_7 = i1.ɵnov(_v, 3).tabsAtLeft; var currVal_8 = i1.ɵnov(_v, 3).dir; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_BenefitDetailsTabsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-benefit-details-standart", [], null, null, null, i3.View_BenefitDetailsStandartComponent_0, i3.RenderType_BenefitDetailsStandartComponent)), i1.ɵprd(512, null, i4.BenefitDetailsStandartManagementService, i4.BenefitDetailsStandartManagementService, [i5.BenefitDetailsManagementService, i6.BenefitDetailsPermissionService, i7.StateManagementService, i8.ComponentStateStorageService, i9.BenefitDetailsApiService, i10.BenefitEmployeesApiService, i11.FileService, i12.BenefitEligibilityRulesManagementService, i13.CalculationCommonService]), i1.ɵdid(2, 245760, null, 0, i14.BenefitDetailsStandartComponent, [i15.ModalService, i5.BenefitDetailsManagementService, i6.BenefitDetailsPermissionService, i4.BenefitDetailsStandartManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_BenefitDetailsTabsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Benefit Group did not found"]))], null, null); }
function View_BenefitDetailsTabsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "benefit-tabs-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide benefit-tabs-mobile__providers"], ["titleField", "name"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedGroup = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onSelectedGroup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i19.View_DropdownListComponent_0, i19.RenderType_DropdownListComponent)), i1.ɵdid(2, 49152, null, 0, i20.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"], readonly: [3, "readonly"] }, null), i1.ɵprd(1024, null, i21.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i20.DropdownListComponent]), i1.ɵdid(4, 671744, null, 0, i21.NgModel, [[8, null], [8, null], [8, null], [6, i21.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i21.NgControl, null, [i21.NgModel]), i1.ɵdid(6, 16384, null, 0, i21.NgControlStatus, [[4, i21.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_10)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.groups; var currVal_8 = "id"; var currVal_9 = "name"; var currVal_10 = _co.isEditMode; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.selectedGroup; _ck(_v, 4, 0, currVal_11); var currVal_12 = _co.isStandartGroup(_co.selectedGroup); _ck(_v, 8, 0, currVal_12); var currVal_13 = _co.isUndefinedGroup(_co.selectedGroup); _ck(_v, 10, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_BenefitDetailsTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsTabsComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktop; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isDesktop; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BenefitDetailsTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-details-tabs", [], null, null, null, View_BenefitDetailsTabsComponent_0, RenderType_BenefitDetailsTabsComponent)), i1.ɵdid(1, 245760, null, 0, i22.BenefitDetailsTabsComponent, [i5.BenefitDetailsManagementService, i6.BenefitDetailsPermissionService, i23.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitDetailsTabsComponentNgFactory = i1.ɵccf("slx-benefit-details-tabs", i22.BenefitDetailsTabsComponent, View_BenefitDetailsTabsComponent_Host_0, {}, {}, []);
export { BenefitDetailsTabsComponentNgFactory as BenefitDetailsTabsComponentNgFactory };
