import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../../app.config';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { orderBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { AuditTrailManagementService } from '../../../services/audit-trail/audit-trail-management.service';
var AuditTraiLimitedGridComponent = /** @class */ (function () {
    function AuditTraiLimitedGridComponent(changeDetector, auditTrialManagementService) {
        this.changeDetector = changeDetector;
        this.auditTrialManagementService = auditTrialManagementService;
        this.gridPageChanged = new EventEmitter();
        this.pageSize = 50;
        this.useTZnotOffset = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
    }
    Object.defineProperty(AuditTraiLimitedGridComponent.prototype, "gridView", {
        set: function (value) {
            this.records = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    AuditTraiLimitedGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.useTZnotOffsetSubscription = this.auditTrialManagementService.useTZnotOffset$.subscribe(function (value) {
            _this.useTZnotOffset = value;
        });
        this.currentTimezoneCodeSubscription = this.auditTrialManagementService.currentTimezoneCode$.subscribe(function (value) {
            _this.currentTimezoneCode = value;
        });
    };
    AuditTraiLimitedGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.useTZnotOffsetSubscription.unsubscribe();
        this.currentTimezoneCodeSubscription.unsubscribe();
    };
    AuditTraiLimitedGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.gridPageChanged.emit({ take: event.take, skip: event.skip });
    };
    AuditTraiLimitedGridComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AuditTraiLimitedGridComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AuditTraiLimitedGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = { data: null, total: null };
        var filtered = filterBy(this.records.items, this.gridState.state.filter);
        this.gridState.view = { data: orderBy(filtered, this.gridState.state.sort), total: this.records.count };
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTraiLimitedGridComponent.prototype, "gridRefreshSubscription", void 0);
    return AuditTraiLimitedGridComponent;
}());
export { AuditTraiLimitedGridComponent };
