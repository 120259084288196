import * as tslib_1 from "tslib";
import { Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import * as _ from 'lodash';
import { PayPoliciesAPIService } from './pay-policies-api.service';
import { PayPoliciesContainer } from '../../models/pay-policies/pay-policies-container';
var PayPoliciesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayPoliciesManagementService, _super);
    function PayPoliciesManagementService(access, changeService, api, notificationsService, route, router) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.notificationsService = notificationsService;
        _this.route = route;
        _this.router = router;
        _this.m_container = new PayPoliciesContainer();
        return _this;
    }
    Object.defineProperty(PayPoliciesManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    PayPoliciesManagementService.prototype.markAsDirty = function () {
        throw new Error('Method not implemented.');
    };
    PayPoliciesManagementService.prototype.init = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.route.queryParams.subscribe(function (params) {
            var id = params['id'];
            if (id) {
                _this.m_container.id = +id;
            }
        });
        this.appDataSubscription = this.orgLevel$.pipe(combineLatest(this.user$)).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PayPoliciesManagementService.prototype.loadPayPolicies = function (orgId) {
        var _this = this;
        return this.api.getAllPayPolicies(orgId).then(function (payPolicies) {
            // Filter pay policies by organizationId
            _this.m_container.records = payPolicies;
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to load pay policies.');
            console.error('Error loading pay policies:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.loadPayPolicyById = function (organizationId, policyId) {
        var _this = this;
        return this.api.getPayPolicyById(organizationId, policyId).then(function (payPolicy) {
            _this.m_container.selectedPolicy = payPolicy;
        }).catch(function (error) {
            _this.notificationsService.error('Error', "Failed to load pay policy with ID " + policyId + ".");
            console.error('Error loading pay policy:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.createPayPolicy = function (organizationId, policy) {
        var _this = this;
        return this.api.createPayPolicy(organizationId, policy).then(function () {
            _this.notificationsService.success('Success', 'Pay policy created successfully.');
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to create pay policy.');
            console.error('Error creating pay policy:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.deletePayPolicyById = function (organizationId, policyId) {
        var _this = this;
        return this.api.deletePayPolicyById(organizationId, policyId).then(function () {
            _this.notificationsService.success('Success', 'Pay policy deleted successfully.');
        }).catch(function (error) {
            _this.notificationsService.error('Error', "Failed to delete pay policy with ID " + policyId + ".");
            console.error('Error deleting pay policy:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.loadPayPolicyRules = function (organizationId) {
        var _this = this;
        return this.api.getAllPayPolicyRules(organizationId).then(function (rules) {
            _this.m_container.policyRules = rules;
        }).catch(function (error) {
            _this.notificationsService.error('Error', 'Failed to load pay policy rules.');
            console.error('Error loading pay policy rules:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.loadPayPolicyRulesByPolicyId = function (organizationId, policyId) {
        var _this = this;
        return this.api.getPayPolicyRulesByPolicyId(organizationId, policyId).then(function (rules) {
            _this.m_container.policyRules = rules; // No mapping needed, API service handles it
        }).catch(function (error) {
            _this.notificationsService.error('Error', "Failed to load rules for policy with ID " + policyId + ".");
            console.error('Error loading pay policy rules:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.createPayPolicyRule = function (organizationId, policyId, rule) {
        return this.api.createPayPolicyRule(organizationId, policyId, rule).catch(function (error) {
            console.error('Error creating pay policy rule:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.saveMultiplePolicyRules = function (organizationId, policyId, rules, paycodes) {
        var _this = this;
        var successCount = 0;
        var errorCount = 0;
        return Promise.all(rules.map(function (rule, index) {
            return _this.createPayPolicyRule(organizationId, policyId, rule)
                .then(function () {
                successCount++;
                paycodes[index].hasChanged = false; // Reset hasChanged to false on successful save
            })
                .catch(function () { return errorCount++; });
        })).then(function () {
            if (errorCount === 0) {
                _this.notificationsService.success('Success', successCount + " pay policy rules saved successfully.");
            }
            else if (successCount > 0) {
                _this.notificationsService.warn('Partial Success', successCount + " rules saved, but " + errorCount + " failed.");
            }
            else {
                _this.notificationsService.error('Error', 'Failed to save all pay policy rules.');
            }
        });
    };
    PayPoliciesManagementService.prototype.deletePayPolicyRule = function (organizationId, policyId, ruleId) {
        var _this = this;
        return this.api.deletePayPolicyRule(organizationId, policyId, ruleId).then(function () {
            _this.notificationsService.success('Success', 'Pay policy rule deleted successfully.');
        }).catch(function (error) {
            _this.notificationsService.error('Error', "Failed to delete rule with ID " + ruleId + " for policy " + policyId + ".");
            console.error('Error deleting pay policy rule:', error);
            throw error;
        });
    };
    PayPoliciesManagementService.prototype.isPolicyUsed = function (organizationId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isUsed, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.isPolicyUsed(organizationId, policyId)];
                    case 1:
                        isUsed = _a.sent();
                        return [2 /*return*/, isUsed];
                    case 2:
                        error_1 = _a.sent();
                        this.notificationsService.error('Error', "Failed to check if policy with ID " + policyId + " is used.");
                        console.error('Error checking if pay policy is used:', error_1);
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayPoliciesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], PayPoliciesManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayPoliciesManagementService.prototype, "appDataSubscription", void 0);
    return PayPoliciesManagementService;
}(ManagementBaseService));
export { PayPoliciesManagementService };
