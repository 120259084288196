import * as moment from 'moment';
import { IEmployeeDefinition } from '../../../../organization/models/employee/index';

export interface IAuditTrailEmployeeAction {
  action: string;
  actionType: string;
  area: string;
  auditDateTime: Date;
  effectiveDate: string;
  employeeId: number;
  employeeName: string;
  fieldName: string;
  orgLevelId: number;
  recordNumber: number;
  userLoginId: string;
  valueAfter: string;
  valueBefore: string;
  departmentName: string;
  empOrganization: string;
}

export class AuditTrailEmployeeAction {
  public action: string;
  public actionType: string;
  public area: string;
  public auditDateTime: Date;
  public effectiveDate?: Date;
  public employeeId: number;
  public employeeName: string;
  public fieldName: string;
  public orgLevelId: number;
  public recordNumber: number;
  public userLoginId: string;
  public valueAfter: string;
  public valueBefore: string;
  public departmentName: string;
  public empOrganization: string;
}


export interface IAuditTrailConfigAction {
  action: string;
  actionType: string;
  area: string;
  auditDateTime: Date;
  effectiveDate: string;
  entityDate: string;
  entityDescription: string;
  fieldName: string;
  orgLevelId: number;
  recordNumber: number;
  userLoginId: string;
  valueAfter: string;
  valueBefore: string;
}

export class AuditTrailConfigAction {
  public action: string;
  public actionType: string;
  public area: string;
  public auditDateTime: Date;
  public effectiveDate: Date;
  public entityDate: string;
  public entityDescription: string;
  public fieldName: string;
  public orgLevelId: number;
  public recordNumber: number;
  public userLoginId: string;
  public valueAfter: string;
  public valueBefore: string;
}