import { LocalStorageService } from './../../../../core/services/local-storage/local-storage.service';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { process, State, aggregateBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColorUtil } from '../../../../common/utils/index';
import { IColumnSettings } from '../../../../core/models/index';
import * as kendoUiUtils from '../../../../core/utils/kendo-ui-utils';

import { ArrayUtils } from '../../../../framework/array-utils/array-utils';

import { GridComponent, CellClickEvent, RowArgs } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { PayRuleDefinition } from '../../../../organization/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { TimecardsDisplayManagementService, TimecardsStorageService, TimecardDisplayCommonService } from '../../../services/index';
import {
  TimecardsSummary,
  TimecardsEmployee,
  TimecardsEarning,
  TimecardsActionCmd,
  TimecardsAction,
  TimecardsState,
  TimecardsGrandtotals,
  TimecardFlatRecord,
  TimecardsColumnState,
  PayCodeGridModel,
  TimecardsLastSelectionState, TimecardsEarningPayRule
} from '../../../models/index';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { TimecardsActions } from '../../../store';
import { ISession } from '../../../../authentication/store';

const CURR_LOGGIN_USER_ALIAS_KEY = "slx-worklinx-user@alias";
export class TimecardMobile {
  public groupsOfEmployees: Array<TimecardFlatRecord[]>;
  public weekErrors: StringMap<number>;
}

@Component({
  moduleId: module.id,
  selector: 'slx-timecards-display-flat-grid',
  templateUrl: 'timecards-display-flat-grid.component.html',
  styleUrls: ['timecards-display-flat-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimecardsDisplayFlatGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: TimecardFlatRecord[];
  public container: TimecardsSummary;
  public state: TimecardsState;
  public grandTotals: TimecardsGrandtotals;
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen$: Observable<boolean>;
  public total: any = {};
  public gridState: KendoGridStateHelper<TimecardFlatRecord>;
  public isAllSelected: boolean;
  public aggregates: any = [];
  public aggregatesDef: any = [
    { field: 'productiveHours', aggregate: 'sum' },
    { field: 'nonProductiveHours', aggregate: 'sum' },
    { field: 'totalHours', aggregate: 'sum' },
    { field: 'regularHours', aggregate: 'sum' },
    { field: 'regularPay', aggregate: 'sum' },
    { field: 'overtimeAndExtraHours', aggregate: 'sum' },
    { field: 'overtimeAndExtraPay', aggregate: 'sum' },
    { field: 'totalAbsencePay', aggregate: 'sum' },
    { field: 'overTimePay', aggregate: 'sum' },
    { field: 'totalOtherPay', aggregate: 'sum' },
    { field: 'totalPay', aggregate: 'sum' },
  ];
  public isAllHidden: boolean;
  public collapsed: StringMap<boolean>;
  public groupOfEmployees: Array<TimecardFlatRecord[]>;
  public pageSize = 50;
  public punchFilter: 'missing' | 'invalid' | 'edited';
  private punchFilterFields: any = {
    missing: 'isError',
    invalid: 'isWarning',
    edited: 'isEdited',
    isError: 'missing',
    isWarning: 'invalid',
    isEdited: 'edited'
  }

  public get isShowHighPrecision(): boolean {
    if (!this.state) {
      return false;
    }
    return this.state.isShowHighPrecision;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  public highlightedRows: number[];
  private selectedRows: number[];

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private stateChangedSubscription: Subscription;
  @unsubscribe()
  private actionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private isLeftSidebarOpenSubscription: Subscription;
  @unsubscribe()
  private userSubscription: Subscription;

  private firstInit: boolean;
  private stateUpdated: boolean;
  private loading: boolean = false;
  private showPayRatesPreviousState: boolean = undefined;
  private mapFieldNameByFilterName: Object = {};
  private manuallyUnselectedEverything = false;

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  constructor(
    private managementService: TimecardsDisplayManagementService,
    private changeDetector: ChangeDetectorRef,
    private storageService: TimecardsStorageService,
    private _commonService: TimecardDisplayCommonService,
    public timecardActions: TimecardsActions,
    public localStorageService: LocalStorageService
  ) {
    this.gridState = new KendoGridStateHelper<TimecardFlatRecord>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.group = [];
    this.gridState.state.sort = [{ field: 'emp.employeePosition.employee.name', dir: 'asc' }];
    this.aggregates = _.map(this.aggregatesDef, (rule: PayRuleDefinition) => rule);
    this.highlightedRows = [];
    this.selectedRows = [];
    this.firstInit = true;
    this.stateUpdated = false;
    this.collapsed = {};
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(
      (container: TimecardsSummary) => {
        if (this.stateUpdated && _.size(this.records) > 0) {
          this.gridState.state.skip = 0;
        }

        this.container = container;
        this.initFieldNameByFilterNameMapping();
        this.aggregates = _.map(this.aggregatesDef, (rule: PayRuleDefinition) => rule);
        _.forEach(container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
          this.aggregates.push({ field: rule.uniqName, aggregate: 'sum' });
        });
        this.load();
        this.onToggleAllSelected(false);
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.actionSubscription = this.managementService.onActionCmd$.subscribe((v: TimecardsAction): void => {
      if (v.cmd === TimecardsActionCmd.excelExport) {
        this.makeXlsExport();
      }
      if (v.cmd === TimecardsActionCmd.pdfExport) {
        this.grid.saveAsPDF();
      }
    });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: TimecardsState) => {
        this.state = state;

        if (this.state.gridState) {
          this.gridState.state = this.state.gridState.state;

          if (this.gridState.state.filter && this.gridState.state.filter.filters.length > 0) {
            this.gridState.state.filter.filters.forEach((filter: FilterDescriptor) => {
              if (filter.field && filter.field === 'isWarning' || filter.field === 'isError' || filter.field === 'isEdited') {
                this.punchFilter = this.punchFilterFields[filter.field];
              }
            });
          }
        }

        // manuallyUnselectedEverything flag checks if the user has manually unselected everything then
        // dont highlight the selected row from state.
        if (!this.manuallyUnselectedEverything && this.state.selectedEmployeeId && this.highlightedRows.indexOf(this.state.selectedEmployeeId) < 0) {
          this.highlightedRows.push(this.state.selectedEmployeeId);
        }

        if (this.showPayRatesPreviousState === undefined) {
          this.showPayRatesPreviousState = this.state.isShowPayRates;
        }
        const col = _.find(state.empColumns.columns, (c: IColumnSettings) => this.isVisible(c.name));
        this.isAllHidden = !!col;

        // manuallyUnselectedEverything flag checks if the user has manually unselected everything then
        // dont highlight the selected row from state.
        if (!this.manuallyUnselectedEverything && this.firstInit && this.state.lastViewedEmployee > 0) {
          this.highlightedRows.push(this.state.lastViewedEmployee);
        }
        if (this.firstInit && this.state.lastViewedPage > 0) {
          this.gridState.state.skip = this.state.lastViewedPage;
          this.refreshGrid();
        }
        if (this.firstInit && this.state.lastSelectedEntries && this.state.lastSelectedEntries.length > 0) {
          this.selectedRows.push(...state.lastSelectedEntries);
        }
        if (this.container && !this.loading) {
          this.load(false);
        } else {
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }
        this.firstInit = false;
        this.stateUpdated = true;
      });

    this.isLeftSidebarOpenSubscription = this.isLeftSidebarOpen$.subscribe((isOpen: boolean) => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        if (session.alias && session.user && session.user.name) {
          let userAtAlias = session.user.name + '@' + session.alias;
          if (this.localStorageService.has(CURR_LOGGIN_USER_ALIAS_KEY) && this.localStorageService.get(CURR_LOGGIN_USER_ALIAS_KEY) !== userAtAlias) {
            this.timecardActions.clearTimecardsDisplayGridState();
          }
          this.localStorageService.set(CURR_LOGGIN_USER_ALIAS_KEY, userAtAlias);
        }
      }
    });
  }

  /**
   * Fix for 146380.
   * This function scroll up to the selected row in the grid
   * When user selects the employee in org level timecard and move to individual timecard and move back to org level time card,
   * shows the highlighted employee in the view.
   */
  public scrollToHighlighedRow(): void {
    Array.from(document.querySelectorAll('.k-state-selected')).forEach(item => {
      try {
        if (item && item.hasOwnProperty('scrollIntoViewIfNeeded')) {
          (item as any).scrollIntoViewIfNeeded();
        } else {
          item.scrollIntoView();
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onToggleAllSelected(selected: boolean): void {
    this.isAllSelected = selected;
    if (!this.isAllSelected) {
      _.forEach(this.container.records, (record: TimecardsEmployee) => {
        record.isSelected = false;
      });
    } else {
      const state: State = {
        skip: 0,
        take: undefined,
        filter: this.gridState.state.filter
      };
      const filtered = process(this.records, state);
      _.forEach(filtered.data, (record: TimecardFlatRecord) => {
        record.emp.isSelected = record.isLocked ? false : true;
      });
    }
    this.selectionChange();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onChangedState(...indexes: number[]): void {
    const key: string = _.map(indexes, (index: number) => String(index)).join('');
    this.collapsed[key] = !this.collapsed[key];
  }

  public selectionChange(): void {
    let selectedRecords: TimecardsEmployee[] = [];
    selectedRecords = _.filter(this.container.records, (record: TimecardsEmployee) => {
      return record.isSelected === true;
    });
    this.managementService.onRecordsSelected(selectedRecords);

    this.reSelectCheckAll();
  }

  public isVisible(field: string, payCode?: boolean): boolean {
    if (!this.state) {
      return false;
    }
    let columnState: TimecardsColumnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
    if (!columnState || !columnState.columnsMap[field]) {
      return false;
    }
    let column: IColumnSettings = columnState.columnsMap[field];
    if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    return column.displayed;
  }

  public onCellClick(cell: CellClickEvent): void {
    const record: TimecardFlatRecord = _.get(cell, 'dataItem', null);
    if (_.isObject(record)) {
      const currentId: number = record.emp.employeePosition.employee.id;
      const previousId: number = _.head(this.highlightedRows);
      this.highlightedRows.length = 0;
      if (currentId !== previousId) {
        this.highlightedRows.push(currentId);
      }

      // manuallyUnselectedEverything flag check at the time of state change if the user has manually unselected everything then
      // dont highlight the selected row from state.
      this.manuallyUnselectedEverything = (this.highlightedRows.length === 0);
    }
  }

  public isRowSelected = (e: RowArgs) => this.highlightedRows.indexOf(e.dataItem.emp.employeePosition.employee.id) >= 0;

  public onSelectEmployee(employeeId: number): void {
    const state = new TimecardsLastSelectionState(employeeId, this.gridState.state.skip);
    this.managementService.onSaveLastSelectionState(state);
    this.saveEmployeeListForIndividualScreen();
  }

  public getWidth(field: string): number {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 100;
  }

  public getFilter(field: string): string {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
  }

  public getColor(ruleDef: PayRuleDefinition): string {
    return ColorUtil.DecToHexString(ruleDef.color, true);
  }

  public getFieldPath(ruleDef: PayRuleDefinition): string {
    return ruleDef.uniqName;
  }

  public getCurrentState(...indexes: number[]): boolean {
    const key: string = _.map(indexes, (index: number) => String(index)).join('');

    return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
  }

  public getPayCodeModel(record: TimecardFlatRecord): PayCodeGridModel {
    const payCode: PayCodeGridModel = new PayCodeGridModel();
    payCode.positionName = record.positionName;
    payCode.rulesMap = record.rulesMap;

    return payCode;
  }

  public makeGrouppingForMobile(): void {
    const groupOfEmployees: Dictionary<TimecardFlatRecord[]> = _.groupBy(this.records, (r: TimecardFlatRecord) => {
      return r.emp.employeePosition.employee.id;
    });
    this.groupOfEmployees = _.values(groupOfEmployees);
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      let gridState: KendoGridStateHelper<TimecardFlatRecord> = new KendoGridStateHelper<TimecardFlatRecord>();
      gridState.state.skip = 0;
      gridState.state.take = this.records ? this.records.length : 0;
      gridState.state.group = this.gridState.state.group;
      gridState.state.sort = this.gridState.state.sort;
      gridState.state.filter = this.gridState.state.filter;
      this.updateExportState(gridState);
      const data = gridState.view ? gridState.view.data : null;
      return {
        data: data,
        group: this.gridState.state.group
      };
    };
  }

  private updateRecords(): void {
    _.forEach(this.container.records, (record: TimecardsEmployee) => {
      const index: number = _.indexOf(this.selectedRows, record.employeePosition.employee.id);
      if (index !== -1) {
        record.isSelected = true;
      }
    });
  }

  private refreshGrid(updateState: boolean = true): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    if (this.gridState.state.group && this.gridState.state.group.length) {
      this.removeUnavailableGroups();
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);

    let keepFilters;
    if (this.gridState.state.filter && this.gridState.state.filter.filters && this.gridState.state.filter.filters.length > 0) {
      this.removeUnavailableFilters();
    }

    if (this.gridState.state.filter && !this.isShowHighPrecision) {
      keepFilters = this.adaptFilterPrecision(this.gridState.state.filter);
    }
    if(this.gridState.state.skip > 0 && this.records && this.records.length < this.gridState.state.skip){
      this.gridState.state.skip = 0;
    }
    this.gridState.view = process(this.records, this.gridState.state);
    this.total = aggregateBy(this.records, this.aggregates);
    if (keepFilters) {
      this.gridState.state.filter = keepFilters;
    }

    if (updateState) {
      this.timecardActions.changeTimecardsDisplayGridState({
        state: this.gridState.state,
        records: this.records
      });
    }
    this.reSelectCheckAll();
  }

  private reSelectCheckAll(): void {
    const state: State = {
      skip: 0,
      take: undefined,
      filter: this.gridState.state.filter
    };
     const filteredRecords = process(this.records, state);
     this.isAllSelected = _.every(filteredRecords.data, (recordItem: TimecardFlatRecord) => {
      return recordItem.emp.isSelected;
    });
  }

  public adaptFilterPrecision(filter: CompositeFilterDescriptor): CompositeFilterDescriptor {
    let filters = _.cloneDeep(filter);
    kendoUiUtils.adaptNumberFilterPrecision(filter, 2);
    return filters;
  }

  private saveEmployeeListForIndividualScreen(): void {
    let sortOnlyState = new KendoGridStateHelper<TimecardFlatRecord>();
    sortOnlyState.state.skip = 0;
    sortOnlyState.state.take = _.size(this.records);
    sortOnlyState.state.group = [];
    sortOnlyState.state.sort = this.gridState.state.sort;
    sortOnlyState.state.filter = this.gridState.state.filter;
    sortOnlyState.view = process(this.records, sortOnlyState.state);
    const employeesList = _.map(sortOnlyState.view.data, (record: TimecardFlatRecord) => record.emp.employeePosition.employee.id);
    this.storageService.setEmployeesList(_.uniq(employeesList));
  }

  private updateExportState(state: KendoGridStateHelper<TimecardFlatRecord>): void {
    if (!this.records) {
      state.view = null;
      return;
    }

    state.state.sort = this.gridState.state.sort;
    state.state.filter = _.cloneDeep(this.gridState.state.filter);
    state.state.group = _.cloneDeep(this.gridState.state.group); //.forEach((group: any) => group.aggregates = this.aggregates);
    if (state.state.filter && !this.isShowHighPrecision) {
      this.adaptFilterPrecision(state.state.filter);
    }
    state.view = process(this.records, state.state);
  }

  protected makeXlsExport() {
    let gridState: KendoGridStateHelper<TimecardFlatRecord> = new KendoGridStateHelper<TimecardFlatRecord>();
    this.updateExportState(gridState);
    const data = gridState ? gridState : null;
    const grid = this.grid;
    const addHeaderInfo = '';
    const group = this.gridState.state.group;
    const fileName = '';
    const usedRulesDefinitionsPayRule = this.container ? this.container.usedRulesDefinitions : [];
    this._commonService.makeXlsExport(data, grid, group, addHeaderInfo, fileName, usedRulesDefinitionsPayRule);
  }

  private load(updateState: boolean = true): void {
    this.loading = true;
    let currentEmpId: number = null;
    try {
      //make note of current page (id of first record in the current page)
      if (this.showPayRatesPreviousState !== this.state.isShowPayRates && this.records) {
        currentEmpId = this.records[this.gridState.state.skip].emp.employeePosition.employee.id;
      }

      let groupByKeys = this.getGroupByKeys();
      this.records = _.reduce(this.container.records, (result: TimecardFlatRecord[], emp: TimecardsEmployee) => {
        let first: boolean = true;
        let rs: TimecardFlatRecord[] = _.map(emp.earnings, (earn: TimecardsEarning) => {
          let r: TimecardFlatRecord = new TimecardFlatRecord();
          r.emp = emp;
          r.earning = earn;
          r.regularHours = earn.regularHours;
          r.regularPay = earn.regularPay;
          r.overtimeAndExtraHours = earn.overtimeAndExtraHours;
          r.overtimeAndExtraPay = earn.overtimeAndExtraPay;
          r.overTimePay = earn.overTimePay;
          r.totalAbsencePay = earn.totalAbsencePay;
          r.totalOtherPay = earn.totalOtherPay;
          r.rulesMap = earn.rulesMap;
          r.shiftDiffPolicy = emp.shiftDiffPolicy;
          r.payPolicy = emp.payPolicy;
          r.standardPayRate = emp.standardPayRate;
          r.isError = emp.isError;
          r.isWarning = emp.isWarning;
          r.isPayCycleLocked = emp.isPayCycleLocked;
          r.isTimecardLocked = emp.isLocked;
          r.isEdited = emp.isEdited;
          //r.rulesValues = {};
          let ur: any = r;
          _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
            let value = r.getRuleValue(rule.name);
            ur[rule.uniqName] = value ? value : null;
          });
          r.totalPay = earn.totalPay;
          if (first) {
            first = false;
            r.productiveHours = emp.productiveHours;
            r.nonProductiveHours  = emp.nonProductiveHours;
            r.totalHours = emp.totalHours;
            r.isFirst = true;
          } else {
            r.productiveHours = null;
            r.nonProductiveHours = null;
            r.totalHours = null;
          }
          return r;
        });
        if (rs.length === 0) {
          let er: TimecardFlatRecord = new TimecardFlatRecord();
          er.isFirst = true;
          er.emp = emp;
          er.earning = null;
          er.productiveHours = emp.productiveHours;
          er.nonProductiveHours = emp.nonProductiveHours;
          er.totalHours = emp.totalHours;
          er.regularHours = null;
          er.regularPay = emp.regularPay;
          er.overtimeAndExtraHours = null;
          er.overtimeAndExtraPay = emp.overtimeAndExtra;
          er.overTimePay = emp.overTimePay;
          er.totalAbsencePay = emp.totalAbsencePay;
          er.totalOtherPay = emp.totalOtherPay;
          er.rulesMap = {};
          er.shiftDiffPolicy = emp.shiftDiffPolicy;
          er.payPolicy = emp.payPolicy;
          er.standardPayRate = emp.standardPayRate;
          er.isError = emp.isError;
          er.isWarning = emp.isWarning;
          er.totalPay = emp.totalPay;
          er.isPayCycleLocked = emp.isPayCycleLocked;
          er.isTimecardLocked = emp.isLocked;
          er.isEdited = emp.isEdited;
          let uer: any = er;
          _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
            uer[rule.uniqName] = null;
          });
          rs.push(er);
        }

        if (rs.length > 1) {
          let groupResult = ArrayUtils.groupObjects(rs, groupByKeys);
          let groupedRecords = [];
          let isFirst = true;
          _.forEach(groupResult, groupRecords => {
            let er: TimecardFlatRecord = new TimecardFlatRecord();
            groupRecords.forEach((item: TimecardFlatRecord) => {
              this.mergeTimecardRecord(item, er);
              er.isFirst = isFirst;
            });
            this.rebuildRulesMap(er);
            if (!er.isFirst) {
              er.productiveHours = null;
              er.nonProductiveHours = null;
              er.totalHours = null;
            }
            isFirst = false;
            groupedRecords.push(er);
          });
          rs = groupedRecords;
        }

        return (result || []).concat(rs);
      }, []);

      if (this.selectedRows.length > 0) {
        this.updateRecords();
      }
      if (currentEmpId) {
        let newRecordIndex = this.records.findIndex(record => record.emp.employeePosition.employee.id === currentEmpId);
        if (newRecordIndex && newRecordIndex > 1 && this.pageSize > 0) {
          let newPageIndex = (newRecordIndex - (newRecordIndex % this.pageSize)) / this.pageSize;
          this.gridState.state.skip = newPageIndex * this.pageSize;
        }
      }
      this.makeGrouppingForMobile();
      this.refreshGrid(updateState);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
    finally {
      this.scrollToHighlighedRow();
      this.loading = false;
      this.showPayRatesPreviousState = this.state.isShowPayRates;
    }
  }

  private getGroupByKeys(): string[] {
    let groupByProperties = ['emp.employeePosition.employee.name'];
    this.addGroupByField('organization', 'empOrganization', groupByProperties);
    this.addGroupByField('department', 'departmentName', groupByProperties);
    this.addGroupByField('position', 'positionName', groupByProperties);
    this.addGroupByField('payroll', 'emp.employeePosition.employee.payrollNumber', groupByProperties);
    this.addGroupByField('empType', 'emp.employeePosition.employee.employeeType.name', groupByProperties);
    this.addGroupByField('approved', 'emp.approved', groupByProperties);
    this.addGroupByField('certified', 'emp.certified', groupByProperties);
    this.addGroupByField('payPolicy', 'payPolicy', groupByProperties);
    this.addGroupByField('shiftDiffPolicy', 'earning.shiftDiffPolicy.name', groupByProperties);
    this.addGroupByField('shiftCodeName', 'earning.shiftCodeName', groupByProperties);
    this.addGroupByField('costCenterCode', 'earning.costCenterCode', groupByProperties);
    if (this.state.isShowPayRates) {
      this.addGroupByField('payRate', 'payRate', groupByProperties);
    }
    return groupByProperties;
  }

  private addGroupByField(columnName, fieldName, target: String[]) {
    if (this.isVisible(columnName)) {
      target.push(fieldName);
    }
  }

  private mergeTimecardRecord(source: TimecardFlatRecord, target: TimecardFlatRecord) {
    if (!target.earning) {
      target.earning = _.cloneDeep(source.earning);
    } else {
      source.earning.rules.forEach(rule => {
        let r = _.cloneDeep(rule);
        target.earning.rules.push(r);
      });
    }
    target.emp = source.emp;
    target.productiveHours = source.productiveHours + (target.productiveHours ? target.productiveHours : 0);
    target.nonProductiveHours = source.nonProductiveHours + (target.nonProductiveHours ? target.nonProductiveHours : 0);
    target.totalHours = source.totalHours + (target.totalHours ? target.totalHours : 0);
    target.regularHours = source.regularHours + (target.regularHours ? target.regularHours : 0);
    target.overtimeAndExtraHours = source.overtimeAndExtraHours + (target.overtimeAndExtraHours ? target.overtimeAndExtraHours : 0);
    target.regularPay = source.regularPay + (target.regularPay ? target.regularPay : 0);
    target.overtimeAndExtraPay = source.overtimeAndExtraPay + (target.overtimeAndExtraPay ? target.overtimeAndExtraPay : 0);
    target.overTimePay = source.overTimePay + (target.overTimePay ? target.overTimePay : 0);
    target.totalAbsencePay = source.totalAbsencePay + (target.totalAbsencePay ? target.totalAbsencePay : 0);
    target.totalOtherPay = source.totalOtherPay + (target.totalOtherPay ? target.totalOtherPay : 0);
    target.payPolicy = source.payPolicy;
    target.shiftDiffPolicy = source.shiftDiffPolicy;
    target.standardPayRate = source.standardPayRate;
    target.isError = source.isError;
    target.isWarning = source.isWarning;
    target.isPayCycleLocked = source.isPayCycleLocked;
    target.isTimecardLocked = source.isTimecardLocked;
    target.totalPay = source.totalPay + (target.totalPay ? target.totalPay : 0);
    target.isEdited = source.isEdited;
  }

  private rebuildRulesMap(target: TimecardFlatRecord) {
    target.earning.rulesMap = _.groupBy(target.earning.rules, (rule: TimecardsEarningPayRule) => {
      return rule.payRule.name;
    });
    target.rulesMap = _.groupBy(target.earning.rules, (rule: TimecardsEarningPayRule) => {
      return rule.payRule.name;
    });
    let ur: any = target;
    _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
      let value = target.getRuleValue(rule.name);
      ur[rule.uniqName] = value ? value : null;
    });
  }

  public filterByPunches(event: any, type: 'missing' | 'invalid' | 'edited') {

    event.stopImmediatePropagation();

    let filters = ((this.gridState.state.filter || { filters: [] }).filters as any).filter(f => f.field !== 'isError' && f.field !== 'isWarning' && f.field !== 'isEdited');

    if (this.punchFilter === type) {
      this.punchFilter = undefined;
    } else {
      filters.push({
        field: this.punchFilterFields[type],
        operator: 'eq',
        value: true
      });
      this.punchFilter = type;
    }

    if (this.gridState.state.filter) {
      this.gridState.state.filter.filters = filters;
    } else {
      this.gridState.state.filter = {
        filters,
        logic: 'and'
      };
    }

    this.refreshGrid();
  }

  /*
  * Check if the columns which are applied on filters are visible in the grid,
  * if they are not, then remove them from the filter column list.
  *
  * There are two exceptions, `isWarning` and `isError` are fields that can be filtered on but do not have a column.
  */
  private removeUnavailableFilters() {
    if (_.get(this.gridState, 'state.filter.filters')) {
      const result = this.gridState.state.filter.filters.filter((item: any) => {
        if (_.get(item, 'filters[0].field')) {
          const filtersToNotRemove = ['isWarning', 'isError', 'emp.employeePosition.employee.name'];
          return this.isVisible(this.getFieldNameByFilterName(item.filters[0].field)) || this.isVisible(this.getFieldNameByFilterName(item.filters[0].field), true) || filtersToNotRemove.includes(item.filters[0].field);
        } else {
          return true;
        }
      });

      if (this.gridState.state.filter.filters.length !== result.length) {
        this.gridState.state.filter.filters = result;
      }
    }
  }

  /*
  * Check if the columns which are applied on grouping are visible in the grid,
  * if they are not, then remove them from the grouping list
  */
  private removeUnavailableGroups() {
    const result = this.gridState.state.group.filter((item: any) => {
      // Name is a special column which will always be visible, need to skip check for it.
      return item.field === 'emp.employeePosition.employee.name' || this.isVisible(this.getFieldNameByFilterName(item.field)) || this.isVisible(this.getFieldNameByFilterName(item.field), true);
    });

    if (this.gridState.state.group.length !== result.length) {
      this.gridState.state.group = result;
    }
  }

  /*
  * Creates a mapping between column field property and column name.
  */
  private initFieldNameByFilterNameMapping() {

    this.mapFieldNameByFilterName = {};

    // Field Name ===> Column Name
    this.mapFieldNameByFilterName['emp.employeePosition.employee.name'] = 'emp.employeePosition.employee.name';
    this.mapFieldNameByFilterName['emp.employeePosition.employee.employeeType.name'] = 'empType';
    this.mapFieldNameByFilterName['organizationName'] = 'organization';
    this.mapFieldNameByFilterName['departmentName'] = 'department';
    this.mapFieldNameByFilterName['positionName'] = 'position';
    this.mapFieldNameByFilterName['emp.employeePosition.employee.payrollNumber'] = 'payroll'
    this.mapFieldNameByFilterName['emp.approved'] = 'approved'
    this.mapFieldNameByFilterName['emp.certified'] = 'certified'
    this.mapFieldNameByFilterName['earning.shiftDiffPolicy.name'] = 'shiftDiffPolicy'
    this.mapFieldNameByFilterName['earning.costCenterCode'] = 'costCenterCode'
    this.mapFieldNameByFilterName['emp.employeePosition.employee.dateHired'] = 'hireDate'
    this.mapFieldNameByFilterName['workedHours'] = 'workedHours'
    this.mapFieldNameByFilterName['totalHours'] = 'totalHours'
    this.mapFieldNameByFilterName['overtimeAndExtraPay'] = 'overtimeAndExtraPay'
    this.mapFieldNameByFilterName['overTimePay'] = 'overTimePay'
    this.mapFieldNameByFilterName['totalAbsencePay'] = 'totalAbsencePay'
    this.mapFieldNameByFilterName['totalOtherPay'] = 'totalOtherPay'
    this.mapFieldNameByFilterName['earning.shiftCodeName'] = 'shiftCodeName';
    this.mapFieldNameByFilterName['payRate'] = 'payRate';
    this.mapFieldNameByFilterName['totalPay'] = 'totalPay';
    this.mapFieldNameByFilterName['costCenterCode'] = 'costCenterCode';
    this.mapFieldNameByFilterName['overtimeAndExtraHours'] = 'overtimeAndExtraHours';
    this.mapFieldNameByFilterName['emp.homeShift.name'] = 'homeShift';
    this.mapFieldNameByFilterName['regularHours'] = 'regularHours';
    this.mapFieldNameByFilterName['regularPay'] = 'regularPay';
    this.mapFieldNameByFilterName['payPolicyName'] = 'payPolicy';
    this.mapFieldNameByFilterName['emp.supervisor'] = 'supervisor';

    if (this.container && this.container.usedRulesDefinitions && this.container.usedRulesDefinitions.length) {
      _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
        this.mapFieldNameByFilterName[this.getFieldPath(rule)] = rule.name;
      });
    }
  }

  /*
  * Method return a column name for a column field.
  */
  private getFieldNameByFilterName(name) {
    let result = this.mapFieldNameByFilterName[name];
    return result;
  }
}

