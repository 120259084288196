import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { mutableSelect } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { PayPoliciesManagementService } from '../../services/pay-policies/pay-policies-management.service';
import { CopyPayCodesManagementService } from '../../services/copy-paycodes/copy-paycodes-management.service';
import { Location } from '@angular/common';
var PayPoliciesComponent = /** @class */ (function () {
    function PayPoliciesComponent(management, copyPayCodesManagementService, stateManagement, location) {
        this.management = management;
        this.copyPayCodesManagementService = copyPayCodesManagementService;
        this.stateManagement = stateManagement;
        this.location = location;
        this.currentTab = 'payPolicy';
        this.filteredPolicies = [];
        this.selectedPolicyRules = [];
        this.paycodeExceptions = [];
        this.paycodeWithRules = [];
        this.newPolicyId = '';
        this.isPopupOpen = false;
        this.appConfig = appConfig;
        this.subscriptions = [];
        this.saveEnabled = false;
        this.state = { isLoading: false };
    }
    PayPoliciesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.subscriptions.push(this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel) {
                _this.organizationId = orgLevel.organizationId;
                _this.orgLevelId = orgLevel.id;
                _this.loadPayPolicies(_this.organizationId);
                _this.loadPayCodes(_this.organizationId);
            }
        }));
        this.bindPageLoadData();
        this.management.init();
    };
    PayPoliciesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    PayPoliciesComponent.prototype.switchTab = function (tab) {
        this.currentTab = tab;
        this.loadPayPolicies(this.organizationId);
        this.loadPayCodes(this.organizationId);
    };
    PayPoliciesComponent.prototype.loadPayPolicies = function (orgId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.state.isLoading = true;
            _this.management.loadPayPolicies(orgId).then(function () {
                _this.filteredPolicies = _this.management.container.records.filter(function (policy) { return policy.type == (_this.currentTab === 'payPolicy' ? 'P' : 'S'); });
                if (_this.filteredPolicies.length > 0) {
                    _this.selectedPolicyId = _this.filteredPolicies[0].id;
                    _this.onPolicySelect();
                    resolve(true); // Resolve the promise as successful
                }
                else {
                    _this.paycodeWithRules = [];
                    _this.state.isLoading = false;
                    resolve(false); // Resolve the promise but indicate no policies were found
                }
            }).catch(function (error) {
                console.error('Error loading pay policies:', error);
                reject(error); // Reject the promise if there's an error
            }).finally(function () {
                _this.state.isLoading = false; // Ensure the loading state is reset
            });
        });
    };
    PayPoliciesComponent.prototype.loadPayCodes = function (orgId) {
        var _this = this;
        this.copyPayCodesManagementService.loadPayCodes(orgId).then(function () {
            var groupFilter = _this.currentTab === 'payPolicy'
                ? function (groupId) { return groupId != 14 && groupId != 10; }
                : function (groupId) { return groupId == 14; };
            // Filter based on the group and eliminate duplicates by description
            var uniquePayCodesMap = new Map();
            _this.copyPayCodesManagementService.container.records.forEach(function (code) {
                if (groupFilter(code.groupId) && !uniquePayCodesMap.has(code.exceptionDescription)) {
                    uniquePayCodesMap.set(code.exceptionDescription, code);
                }
            });
            _this.paycodeExceptions = Array.from(uniquePayCodesMap.values());
            _this.mapPayCodesToRules();
        }).catch(function (error) {
            console.error('Error loading pay codes:', error);
        });
    };
    PayPoliciesComponent.prototype.onPolicySelect = function () {
        var _this = this;
        if (this.selectedPolicyId) {
            this.state.isLoading = true;
            this.management.loadPayPolicyRulesByPolicyId(this.organizationId, this.selectedPolicyId).then(function () {
                _this.mapPayCodesToRules();
                _this.resetSaveButton();
                _this.state.isLoading = false;
            }).catch(function () {
                _this.state.isLoading = false;
            });
        }
    };
    PayPoliciesComponent.prototype.openAddPopup = function () {
        this.isPopupOpen = true;
    };
    PayPoliciesComponent.prototype.closeAddPopup = function () {
        this.isPopupOpen = false;
        this.newPolicyId = '';
    };
    PayPoliciesComponent.prototype.goBack = function () {
        this.location.back(); // Go back to the previous page
    };
    PayPoliciesComponent.prototype.addNewPolicy = function () {
        var _this = this;
        if (!this.newPolicyId)
            return;
        // Check for spaces in the policy name
        if (/\s/.test(this.newPolicyId)) {
            alert('Policy name cannot contain spaces!');
            return;
        }
        var existingPolicy = this.filteredPolicies.find(function (policy) { return policy.id === _this.newPolicyId; });
        if (existingPolicy) {
            alert('Policy with this ID already exists!');
            return;
        }
        var newPolicy = {
            id: this.newPolicyId,
            organizationId: this.organizationId,
            description: this.newPolicyId,
            type: this.currentTab === 'payPolicy' ? 'P' : 'S',
            deleteInd: false,
            lastUpdatedDate: new Date(),
            lastUpdateId: 'System'
        };
        this.state.isLoading = true;
        this.management.createPayPolicy(this.organizationId, newPolicy).then(function () {
            _this.loadPayPolicies(_this.organizationId).then(function (success) {
                if (success) {
                    _this.selectedPolicyId = _this.newPolicyId; // Set the new policy as selected
                    _this.onPolicySelect(); // Refresh the table with the new policy
                }
                _this.closeAddPopup(); // Close popup after saving and selecting
            });
        }).catch(function (error) {
            console.error('Error creating new policy:', error);
        }).finally(function () {
            _this.state.isLoading = false;
        });
    };
    PayPoliciesComponent.prototype.deletePolicy = function () {
        var _this = this;
        if (!this.selectedPolicyId)
            return; // Ensure a policy is selected
        this.state.isLoading = true;
        // First, check if the policy is in use
        this.management.isPolicyUsed(this.organizationId, this.selectedPolicyId).then(function (isUsed) {
            _this.state.isLoading = false;
            if (isUsed) {
                // Alert the user that the policy can't be deleted
                alert('This policy cannot be deleted because it is currently in use.');
            }
            else {
                // Confirm deletion with the user
                if (confirm('Are you sure you want to delete this policy?')) {
                    _this.state.isLoading = true;
                    // Proceed to delete the policy
                    _this.management.deletePayPolicyById(_this.organizationId, _this.selectedPolicyId).then(function () {
                        // Reload the policy list after deletion
                        _this.loadPayPolicies(_this.organizationId);
                    }).catch(function (error) {
                        console.error('Error deleting policy:', error);
                    }).finally(function () {
                        _this.state.isLoading = false;
                    });
                }
            }
        }).catch(function (error) {
            _this.state.isLoading = false;
            console.error('Error checking if policy is used:', error);
        });
    };
    PayPoliciesComponent.prototype.mapPayCodesToRules = function () {
        var _this = this;
        var policyRules = this.management.container.policyRules;
        this.paycodeWithRules = this.paycodeExceptions.map(function (paycode) {
            var matchingRule = policyRules.find(function (rule) { return rule.exceptionId === paycode.id; });
            return {
                id: matchingRule ? matchingRule.id : _this.generateUniqueId(),
                exceptionId: paycode.id,
                exceptionDescription: paycode.exceptionDescription,
                isActive: matchingRule ? matchingRule.isActive : false,
                description: matchingRule ? matchingRule.description : '',
                notes: matchingRule ? matchingRule.notes : '',
                paid: paycode.paidInd ? 'Y' : 'N',
                effectiveDate: matchingRule && matchingRule.startDate ? _this.formatDate(matchingRule.startDate) : null,
                endDate: matchingRule && matchingRule.endDate ? _this.formatDate(matchingRule.endDate) : null,
                hasChanged: false // Track whether this row has been changed
            };
        });
    };
    PayPoliciesComponent.prototype.handleDescriptionClick = function (event, id) {
        if (event) {
            event.preventDefault();
        }
        var newPath = 'apps/common/paycode_details?orgLevelId=' + this.orgLevelId + '&id=' + id;
        var url = window.location.origin + '/#/' + newPath;
        window.location.href = url;
    };
    PayPoliciesComponent.prototype.generateUniqueId = function () {
        var existingIds = new Set(this.paycodeWithRules.map(function (paycode) { return paycode.id; }).concat(this.selectedPolicyRules.map(function (rule) { return rule.id; })));
        var newId;
        do {
            newId = Math.floor(Math.random() * 1000000) + 1; // Generate a random id
        } while (existingIds.has(newId));
        return newId;
    };
    PayPoliciesComponent.prototype.formatDate = function (date) {
        var d = new Date(date);
        var month = ("" + (d.getMonth() + 1)).padStart(2, '0'); // Ensure 2 digits for month
        var day = ("" + d.getDate()).padStart(2, '0'); // Ensure 2 digits for day
        var year = d.getFullYear();
        return [year, month, day].join('-');
    };
    PayPoliciesComponent.prototype.onFieldChange = function (paycode) {
        paycode.hasChanged = true; // Mark this paycode as changed
        this.saveEnabled = true; // Enable the save button
    };
    PayPoliciesComponent.prototype.resetSaveButton = function () {
        this.saveEnabled = false; // Reset save button to disabled
    };
    PayPoliciesComponent.prototype.bindPageLoadData = function () {
        this.initServices();
        this.appConfig = appConfig;
    };
    PayPoliciesComponent.prototype.saveChanges = function () {
        var _this = this;
        this.state.isLoading = true;
        var changedPaycodes = this.paycodeWithRules.filter(function (paycode) { return paycode.hasChanged; });
        var rulesToSave = changedPaycodes.map(function (paycode) { return ({
            id: paycode.id,
            exceptionId: paycode.exceptionId,
            isActive: paycode.isActive,
            description: paycode.description,
            notes: paycode.notes,
            startDate: new Date('2009-01-01'),
            endDate: new Date('2079-06-06'),
            lastUpdatedDate: new Date(),
            policyId: _this.selectedPolicyId,
            organizationId: _this.organizationId,
            deleteInd: false
        }); });
        this.management.saveMultiplePolicyRules(this.organizationId, this.selectedPolicyId, rulesToSave, changedPaycodes)
            .then(function () {
            _this.resetSaveButton();
            _this.state.isLoading = false;
        })
            .catch(function (error) {
            console.error('Error saving pay policy rules:', error);
            _this.state.isLoading = false;
        });
    };
    PayPoliciesComponent.prototype.initServices = function () {
        this.stateManagement.init('PayPoliciesComponent');
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayPoliciesComponent.prototype, "orgLevel$", void 0);
    return PayPoliciesComponent;
}());
export { PayPoliciesComponent };
