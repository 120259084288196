import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AccessibleApiService } from '../../../../organization';
import { BehaviorSubject } from 'rxjs';
import { Assert } from '../../../../framework';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { mutableSelect } from '../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { OrgLevelType } from '../../../../state-model/models/index';
var AuditTrailManagementService = /** @class */ (function () {
    function AuditTrailManagementService(accessibleApiService, appSettingsManageService) {
        var _this = this;
        this.accessibleApiService = accessibleApiService;
        this.appSettingsManageService = appSettingsManageService;
        this.userActonsChanged$ = new ReplaySubject(1);
        this.useTZnotOffset$ = new BehaviorSubject(false);
        this.currentTimezoneCode$ = new BehaviorSubject("");
        this.onActionCmd$ = new ReplaySubject(1);
        this.appSettingsManageService.getAppServerConfig()
            .then(function (appConfig) {
            _this.useTZnotOffset$.next(appConfig.useTZnotOffset);
        });
        this.orgLevel$.subscribe(function (orgLevel) {
            var timezone;
            if (orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department) {
                timezone = _.get(orgLevel, 'location.timeZone') || "America/Chicago";
            }
            else {
                timezone = moment.tz.guess();
            }
            _this.currentTimezoneCode$.next(moment.tz(timezone).format('zz'));
        });
    }
    AuditTrailManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
        this.onActionCmd$.complete();
        this.userActonsChanged$.complete();
        this.useTZnotOffset$.complete();
        this.currentTimezoneCode$.complete();
    };
    AuditTrailManagementService.prototype.subscribeToActions = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onActionCmd$.subscribe(callback);
    };
    AuditTrailManagementService.prototype.onActionCmd = function (cmd) {
        this.onActionCmd$.next(cmd);
    };
    AuditTrailManagementService.prototype.subscribeToUserActionsChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.userActonsChanged$.subscribe(callback);
    };
    AuditTrailManagementService.prototype.loadUserActions = function (orgLevelId) {
        var _this = this;
        this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
            .then(function (data) {
            _this.userActonsChanged$.next(data);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AuditTrailManagementService.prototype, "orgLevel$", void 0);
    return AuditTrailManagementService;
}());
export { AuditTrailManagementService };
