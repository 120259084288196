import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CopyPayCodesManagementService } from '../../services/copy-paycodes/copy-paycodes-management.service';
import { mutableSelect } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { Location } from '@angular/common';
var CopyPayCodesComponent = /** @class */ (function () {
    function CopyPayCodesComponent(management, stateManagement, cdr, location) {
        this.management = management;
        this.stateManagement = stateManagement;
        this.cdr = cdr;
        this.location = location;
        this.subscriptions = [];
        this.currentPaycodes = [];
        this.currentOrganizations = [];
        this.orgLevels = [];
        this.isAllPaycodesCheck = false;
        this.isAllOrgsCheck = false;
        this.overrideMatchingException = false;
        this.copyIfExceptionExists = false;
        this.state = { isLoading: false };
    }
    CopyPayCodesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true; // Set loading state to true at the start
        this.subscriptions.push(this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.orgId = orgLevel.organizationId;
            _this.loadData(); // Load data on init
        }));
        this.bindPageLoadData();
        this.management.init();
    };
    CopyPayCodesComponent.prototype.loadData = function () {
        var _this = this;
        // Promise.all to wait for both promises (loadPayCodes and loadOrganizations) to complete
        Promise.all([this.loadPayCodes(), this.loadOrganizations()])
            .then(function () {
            _this.state.isLoading = false; // Set loading state to false when both are done
            _this.cdr.detectChanges();
        })
            .catch(function (error) {
            console.error('Error loading data:', error);
            _this.state.isLoading = false; // Set loading state to false even if one fails
            _this.cdr.detectChanges();
        });
    };
    CopyPayCodesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    CopyPayCodesComponent.prototype.bindPageLoadData = function () {
        this.initServices();
        this.appConfig = appConfig;
    };
    CopyPayCodesComponent.prototype.initServices = function () {
        this.stateManagement.init('CopyPayCodesComponent');
        this.management.init();
    };
    CopyPayCodesComponent.prototype.loadPayCodes = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.orgLevelId) {
                _this.management.loadPayCodes(_this.orgId).then(function () {
                    _this.currentPaycodes = _this.management.container.records
                        .filter(function (pc) { return pc.exceptionDescription !== ''; })
                        .map(function (pc) { return ({
                        paycodeName: pc.exceptionDescription,
                        id: pc.id,
                        isChecked: false
                    }); });
                    resolve();
                }).catch(function (error) {
                    console.error('Error loading pay codes:', error);
                    reject(error);
                });
            }
            else {
                resolve(); // Resolve immediately if no orgLevelId
            }
        });
    };
    CopyPayCodesComponent.prototype.loadOrganizations = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.orgLevelId) {
                _this.management.loadOrganizations().then(function () {
                    _this.currentOrganizations = _this.management.container.organizations
                        .filter(function (o) { return o.id != _this.orgId; })
                        .sort(function (a, b) { return a.name.localeCompare(b.name); })
                        .map(function (o) { return ({
                        organizationName: o.name,
                        id: o.id,
                        isChecked: false
                    }); });
                    resolve();
                }).catch(function (error) {
                    console.error('Error loading organizations:', error);
                    reject(error);
                });
            }
            else {
                resolve(); // Resolve immediately if no orgLevelId
            }
        });
    };
    CopyPayCodesComponent.prototype.selectAllPaycodes = function () {
        this.isAllPaycodesCheck = !this.isAllPaycodesCheck;
        this.setAllCheck(this.currentPaycodes, this.isAllPaycodesCheck);
    };
    CopyPayCodesComponent.prototype.selectAllOrganizations = function () {
        this.isAllOrgsCheck = !this.isAllOrgsCheck;
        this.setAllCheck(this.currentOrganizations, this.isAllOrgsCheck);
    };
    CopyPayCodesComponent.prototype.onChangePaycodeCheck = function (e, data) {
        data.isChecked = !data.isChecked;
        this.isAllPaycodesCheck = this.currentPaycodes.every(function (pc) { return pc.isChecked; });
    };
    CopyPayCodesComponent.prototype.onChangeOrgLevelCheck = function (e, data) {
        data.isChecked = !data.isChecked;
        this.isAllOrgsCheck = this.currentOrganizations.every(function (org) { return org.isChecked; });
    };
    CopyPayCodesComponent.prototype.setAllCheck = function (data, isChecked) {
        data.forEach(function (item) { return item.isChecked = isChecked; });
    };
    Object.defineProperty(CopyPayCodesComponent.prototype, "saveDisabled", {
        get: function () {
            var currentPaycodesChecked = this.currentPaycodes.filter(function (i) { return i.isChecked; });
            var orgLevelsChecked = this.currentOrganizations.filter(function (i) { return i.isChecked; });
            return !(currentPaycodesChecked.length > 0 && orgLevelsChecked.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    // Method to handle changes in the first checkbox
    CopyPayCodesComponent.prototype.onOverrideMatchingExceptionChange = function (event) {
        this.overrideMatchingException = event.target.checked;
        if (!this.overrideMatchingException) {
            this.copyIfExceptionExists = false;
        }
    };
    // Method to handle changes in the second checkbox
    CopyPayCodesComponent.prototype.onCopyIfExceptionExistsChange = function (event) {
        if (event.target.checked) {
            this.overrideMatchingException = true;
        }
        this.copyIfExceptionExists = event.target.checked;
    };
    CopyPayCodesComponent.prototype.save = function () {
        var _this = this;
        var selectedPayCodeIds = this.currentPaycodes.filter(function (pc) { return pc.isChecked; }).map(function (pc) { return pc.id; });
        var selectedOrgIds = this.currentOrganizations.filter(function (org) { return org.isChecked; }).map(function (org) { return org.id; });
        this.state.isLoading = true; // Set loading state to true
        this.management.copyPayCodes(selectedPayCodeIds, selectedOrgIds, this.orgId, this.overrideMatchingException, this.copyIfExceptionExists).then(function () {
            console.log('Save operation completed successfully.');
            _this.discard(); // Uncheck everything after a successful save
            _this.state.isLoading = false; // Set loading state to false
        }).catch(function (error) {
            console.error('Failed to copy pay codes:', error);
            _this.state.isLoading = false; // Set loading state to false
        });
    };
    CopyPayCodesComponent.prototype.discard = function () {
        this.isAllPaycodesCheck = false;
        this.isAllOrgsCheck = false;
        this.setAllCheck(this.currentPaycodes, false);
        this.setAllCheck(this.currentOrganizations, false);
        this.cdr.detectChanges();
    };
    CopyPayCodesComponent.prototype.cancelChanges = function () {
        this.location.back(); // Navigates back to the previous page
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], CopyPayCodesComponent.prototype, "orgLevel$", void 0);
    return CopyPayCodesComponent;
}());
export { CopyPayCodesComponent };
