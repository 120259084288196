import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IDestroyService } from '../../../../core/models/index';
import { AuditTrailEvent } from '../../models/audit-trail/audit-trail-event';
import { AccessibleApiService,  UserAction } from '../../../../organization';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Assert } from '../../../../framework';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { mutableSelect } from '../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

@Injectable()
export class AuditTrailManagementService implements IDestroyService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public onActionCmd$: ReplaySubject<AuditTrailEvent>;
  private userActonsChanged$ = new ReplaySubject<UserAction[]>(1);
  public useTZnotOffset$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentTimezoneCode$:BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private accessibleApiService: AccessibleApiService, private appSettingsManageService: AppSettingsManageService) {
    this.onActionCmd$ = new ReplaySubject(1);
    this.appSettingsManageService.getAppServerConfig()
    .then((appConfig) => {
      this.useTZnotOffset$.next(appConfig.useTZnotOffset);
    });
    this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      let timezone:string;
      if(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department){
        timezone = _.get(orgLevel, 'location.timeZone') || "America/Chicago";
      }else{
        timezone = moment.tz.guess();
      }
      this.currentTimezoneCode$.next(moment.tz(timezone).format('zz'));
    });

  }

  public destroy(): void {
    // See #issueWithAOTCompiler
    this.onActionCmd$.complete();
    this.userActonsChanged$.complete();
    this.useTZnotOffset$.complete();
    this.currentTimezoneCode$.complete();
  }

  public subscribeToActions(callback: (cmd: AuditTrailEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onActionCmd$.subscribe(callback);
  }

  public onActionCmd(cmd: AuditTrailEvent): void {
    this.onActionCmd$.next(cmd);
  }

  public subscribeToUserActionsChanged(callback: (v: UserAction[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.userActonsChanged$.subscribe(callback);
  }

  public loadUserActions(orgLevelId: number): void {
    this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
      .then((data: UserAction[]) => {
        this.userActonsChanged$.next(data);
      });
  }
}
