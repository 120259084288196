import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent, StateManagementService, ColumnManagementService, ConfirmOptions } from '../../../common/index';
import { destroyService, unsubscribe, mutableSelect } from '../../../core/decorators/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { appConfig } from '../../../app.config';
import { PayCodesConfigurationManagementService, AccrualBalanceNavigationService } from '../../services/index';
import { PayCodeModel, PayCode } from '../../models/index';
import { ColorUtil } from '../../../common/utils/index';
import { configurationConfig } from '../../configuration.config';
import { AppSettingsManageService } from '../../../app-settings/services';
import { PaycodeExceptionSortOverrideModel } from '../../models/pay-codes/paycode-exceptionsortoverride';
import { DataService } from '../../services/pay-code-details/data.service';
var PayCodeConfigurationComponent = /** @class */ (function () {
    function PayCodeConfigurationComponent(management, stateManagement, modalService, router, route, columnManagementService, dataService, appSettingManageService) {
        var _this = this;
        this.stateManagement = stateManagement;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.columnManagementService = columnManagementService;
        this.dataService = dataService;
        this.isImport = false;
        this.isPopupVisible = false;
        this.errors = [];
        this.gridData = [];
        this.dirtyExceptionSortOrdersRecords = [];
        this.columnGroup = 'PayCode';
        this.subscriptions = {};
        this.accrualTypesLookup = [];
        this.accrualPoliciesLookup = [];
        this.isNextgenPayrollEnabled = false;
        this.sortOverrides = [];
        this.skip = 0;
        this.columnSettings = [];
        this.management = management;
        this.appSettingManageService = appSettingManageService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var code = new PayCodeModel();
            code.payCode = new PayCode();
            _this.crudHelper.addItem(code);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(PayCodeConfigurationComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCodeConfigurationComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    PayCodeConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
        });
        this.getSettings();
        this.bindPageLoadData();
        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true,
            canEditExceptionFlag: false,
            canEditPBJFlag: false,
            canEditUseInTimesheets: false,
            canEditAca: false,
            canEditPaycodestoAccrualMappingFlag: false,
            isSmartAdmin: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'bulkEditMode')) {
                if (state.bulkEditMode) {
                    if (_this.grid)
                        _this.gridState.closeEditor(_this.grid);
                }
                _this.state.bulkEditMode = state.bulkEditMode;
            }
            if (_.has(state, 'canBulkEdit'))
                _this.state.canBulkEdit = state.canBulkEdit;
            if (_.has(state, 'canEdit'))
                _this.state.canEdit = state.canEdit;
            if (_.has(state, 'canAdd'))
                _this.state.canAdd = state.canAdd;
            if (_.has(state, 'canDelete'))
                _this.state.canDelete = state.canDelete;
            if (_.has(state, 'orgLevelChanged')) {
                if (_this.grid)
                    _this.gridState.closeEditor(_this.grid);
            }
            if (_.has(state, 'canEditExceptionFlag'))
                _this.state.canEditExceptionFlag = state.canEditExceptionFlag;
            if (_.has(state, 'canEditPaycodestoAccrualMappingFlag'))
                _this.state.canEditPaycodestoAccrualMappingFlag = state.canEditPaycodestoAccrualMappingFlag;
            if (_.has(state, 'canEditExceptionFlag'))
                _this.state.canEditPBJFlag = state.canEditPBJFlag;
            if (_.has(state, 'canEditUseInTimesheets'))
                _this.state.canEditUseInTimesheets = state.canEditUseInTimesheets;
            if (_.has(state, 'canEditAca'))
                _this.state.canEditAca = state.canEditAca;
            if (_.has(state, 'isSmartAdmin'))
                _this.state.isSmartAdmin = state.isSmartAdmin;
        });
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.subscriptions.export = this.management
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        this.columnStateChangeSubscription = this.columnManagementService.columnsChanged$.filter(function (event) { return event.group === _this.columnGroup; })
            .subscribe(function (event) {
            _this.columnSettings = event.columns;
        });
    };
    PayCodeConfigurationComponent.prototype.isExport = function (property) {
        if (this.columnSettings && this.columnSettings.length > 0) {
            var state = _.find(this.columnSettings, function (c) { return c.name == property; });
            return state.displayed;
        }
        else {
            return true;
        }
    };
    PayCodeConfigurationComponent.prototype.bindPageLoadData = function () {
        var _this = this;
        this.getAccrualPolicy();
        this.getAccrualTypes();
        this.initServices();
        this.appConfig = appConfig;
        this.management.subscribeToBulkSaveResponse(function (redirectToAccrualBalances) {
            if (redirectToAccrualBalances) {
                _this.redirectToAccrualBalances();
            }
        });
    };
    PayCodeConfigurationComponent.prototype.onBulkEditClick = function () {
        this.management.doBulkEdit();
    };
    PayCodeConfigurationComponent.prototype.onBulkSaveClick = function () {
        if (this.management.isDirtySortOrder) {
            for (var i = 0; i < this.gridState.view.data.length; i++) {
                var sortOverrideItem = new PaycodeExceptionSortOverrideModel();
                sortOverrideItem.exceptionId = this.gridState.view.data[i].payCode.id;
                sortOverrideItem.overrideSortOrder = i + 1;
                this.dirtyExceptionSortOrdersRecords.push(sortOverrideItem);
            }
        }
        this.management.doBulkSave(this.dirtyExceptionSortOrdersRecords);
        this.refreshGrid();
    };
    PayCodeConfigurationComponent.prototype.onBulkDiscardClick = function () {
        this.management.doBulkDiscard();
    };
    PayCodeConfigurationComponent.prototype.addNewPayCode = function () {
        var newId = this.generateUniqueId();
        this.handlePayCodeClick(null, newId);
    };
    PayCodeConfigurationComponent.prototype.generateUniqueId = function () {
        var id;
        do {
            id = (Math.random() * 1000000).toFixed(0);
        } while (this.isIdUsed(id));
        return id;
    };
    PayCodeConfigurationComponent.prototype.isIdUsed = function (id) {
        for (var _i = 0, _a = this.management.container.records; _i < _a.length; _i++) {
            var record = _a[_i];
            if (record.payCode.id === id) {
                return true;
            }
        }
        return false;
    };
    PayCodeConfigurationComponent.prototype.handlePayCodeClick = function (event, id) {
        if (event) {
            event.preventDefault();
        }
        var newPath = '/apps/common/paycode_details?orgLevelId=' + this.orgLevelId + '&id=' + id;
        var url = window.location.origin + '/#/' + newPath;
        window.location.href = url;
        this.isImport = false;
        var jsonStr = null;
        this.sendData(jsonStr, this.isImport);
        this.setOrgLevelId();
    };
    PayCodeConfigurationComponent.prototype.handleCopyPayCodeClick = function () {
        var newPath = '/apps/common/copy_paycodes?orgLevelId=' + this.orgLevelId;
        var url = window.location.origin + '/#/' + newPath;
        window.location.href = url;
    };
    PayCodeConfigurationComponent.prototype.handlePayPoliciesClick = function () {
        var newPath = '/apps/common/paycode_policies?orgLevelId=' + this.orgLevelId;
        var url = window.location.origin + '/#/' + newPath;
        window.location.href = url;
    };
    PayCodeConfigurationComponent.prototype.onItemFieldChange = function (item, field, value) {
        switch (field) {
            case 'isAca':
                item.payCode.isAca = value;
                break;
            case 'isPaid':
                item.payCode.isPaid = value;
                break;
            case 'isPbj':
                item.payCode.isPbj = value;
                break;
            case 'isException':
                item.payCode.isException = value;
                break;
            case 'isUsedInAccrualCalc':
                item.payCode.isUsedInAccrualCalc = value;
                break;
            case 'useInTimesheets':
                item.payCode.useInTimesheets = value;
                break;
        }
        this.management.setItemDirty(item);
    };
    PayCodeConfigurationComponent.prototype.onChangeMultiselectValue = function (item, evnt) {
        var optionsIds = _.map(evnt, function (opt) { return opt.policyName; });
        item.payCode.accrualPolicies = optionsIds;
        item.payCode.accrualPolicyNames = ((_.isNull(optionsIds) || (optionsIds && optionsIds.length == 0)) && item.payCode.isUsedInAccrualCalc) ? 'All' : _.join(optionsIds, ', ');
        item.payCode.accrualPolicyList = evnt;
        item.isDirty = true;
    };
    PayCodeConfigurationComponent.prototype.onChangeAccrualType = function (item, evnt) {
        if (item.payCode.accrualType != evnt.accrualTypeName) {
            item.payCode.accrualType = evnt.accrualTypeName;
            item.isDirty = true;
        }
    };
    PayCodeConfigurationComponent.prototype.onColorPickerChange = function (payCode, colorCode) {
        if (payCode)
            payCode.payCode.color = ColorUtil.HexToDec(colorCode);
    };
    PayCodeConfigurationComponent.prototype.getColor = function (payCode) {
        return payCode && payCode.payCode ? ColorUtil.DecToHexString(payCode.payCode.color, true) : '#ffffff';
    };
    PayCodeConfigurationComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    };
    PayCodeConfigurationComponent.prototype.onClickExport = function (isPDF) {
        this.management.exportTo(isPDF);
    };
    PayCodeConfigurationComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    PayCodeConfigurationComponent.prototype.initServices = function () {
        this.stateManagement.init('PayCodeConfigurationComponent');
        this.columnManagementService.init('PayCodeConfigurationComponent');
        this.columnManagementService.initGroup(this.columnGroup, 12);
    };
    PayCodeConfigurationComponent.prototype.redirectToAccrualBalances = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showOK = true;
        options.showCancel = true;
        options.buttonOKtext = 'Yes';
        options.buttonCanceltext = 'No';
        ConfirmDialogComponent.openDialog('Warning', 'The changes made could impact the accrual balances of your employees. Would you like to proceed to the accrual balances screen and recalculate the balances?', this.modalService, function (result) {
            if (result) {
                var navService = new AccrualBalanceNavigationService(_this.router, _this.route);
                navService.NavigateToAccrualBalance();
            }
        }, options);
    };
    PayCodeConfigurationComponent.prototype.getAccrualPolicy = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.management.getAccrualPolicies(this.orgLevelId)
                .then(function (accrualPolicies) {
                _this.accrualPoliciesLookup = accrualPolicies;
            });
        }
    };
    PayCodeConfigurationComponent.prototype.fetchPaycodeExceptionSortOverrides = function () {
        var _this = this;
        this.management.fetchPaycodeExceptionSortOverrides()
            .then(function (exceptionSortOverrides) {
            _this.sortOverrides = exceptionSortOverrides;
        });
    };
    PayCodeConfigurationComponent.prototype.getAccrualTypes = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.management.getAccrualTypes(this.orgLevelId)
                .then(function (accrualTypes) {
                var accrualEmptyType = [{ accrualTypeId: null, accrualTypeName: '' }];
                _this.accrualTypesLookup = accrualEmptyType.concat(accrualTypes);
            });
        }
    };
    PayCodeConfigurationComponent.prototype.canEditAcaCheck = function (dataItem) {
        if ((dataItem.payCode.allocationTypeId == 1 || dataItem.payCode.allocationTypeId == 4) && dataItem.payCode.isPaid == true) {
            return true;
        }
        else if (dataItem.payCode.groupId == 4 && dataItem.payCode.isPaid == true) {
            return true;
        }
        else {
            return false;
        }
    };
    PayCodeConfigurationComponent.prototype.canDeductFrom = function (payCode) {
        return _.some(configurationConfig.deductFromGroups, function (group) { return payCode.payCode.group === group; });
    };
    PayCodeConfigurationComponent.prototype.getSettings = function () {
        var _this = this;
        this.appSettingManageService.getAppServerConfig().then(function (conf) {
            _this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
        });
    };
    PayCodeConfigurationComponent.prototype.onDataBound = function () {
        var _this = this;
        setTimeout(function () {
            var gridElement = _this.grid.wrapper.nativeElement;
            gridElement.querySelectorAll('.btnUp').forEach(function (button) {
                button.addEventListener('click', function (event) {
                    var rowIndex = Array.from(button.closest('tr').parentElement.children).indexOf(button.closest('tr'));
                    _this.moveRowUp(rowIndex);
                });
            });
            gridElement.querySelectorAll('.btnDown').forEach(function (button) {
                button.addEventListener('click', function (event) {
                    var rowIndex = Array.from(button.closest('tr').parentElement.children).indexOf(button.closest('tr'));
                    _this.moveRowDown(rowIndex);
                });
            });
        });
    };
    PayCodeConfigurationComponent.prototype.moveRowUp = function (rowIndex) {
        if (rowIndex > 0) {
            this.reorderRows(rowIndex, rowIndex - 1);
            this.management.setSortOrderDirty();
        }
    };
    PayCodeConfigurationComponent.prototype.moveRowDown = function (rowIndex) {
        if (rowIndex < this.gridState.view.data.length - 1) {
            this.reorderRows(rowIndex, rowIndex + 1);
            this.management.setSortOrderDirty();
        }
    };
    PayCodeConfigurationComponent.prototype.onDragStart = function (event, index) {
        this.draggedIndex = index;
        event.dataTransfer.setData('text', index.toString());
        this.management.setSortOrderDirty();
    };
    PayCodeConfigurationComponent.prototype.onDragOver = function (event, index) {
        event.preventDefault();
        this.management.setSortOrderDirty();
    };
    PayCodeConfigurationComponent.prototype.onDrop = function (event, index) {
        event.preventDefault();
        var draggedIndex = +event.dataTransfer.getData('text');
        this.reorderRows(draggedIndex, index);
        this.management.setSortOrderDirty();
    };
    PayCodeConfigurationComponent.prototype.reorderRows = function (draggedIndex, targetIndex) {
        var draggedItem = this.gridState.view.data[draggedIndex];
        this.gridState.view.data.splice(draggedIndex, 1);
        this.gridState.view.data.splice(targetIndex, 0, draggedItem);
        this.management.setSortOrderDirty();
    };
    PayCodeConfigurationComponent.prototype.refreshGrid = function () {
        this.gridState.view.data = this.gridState.view.data.slice();
    };
    PayCodeConfigurationComponent.prototype.openPopup = function () {
        this.isPopupVisible = true;
        this.errors = [];
    };
    PayCodeConfigurationComponent.prototype.closePopup = function () {
        this.isPopupVisible = false;
        this.errors = [];
    };
    PayCodeConfigurationComponent.prototype.onFileChange = function (event) {
        var _this = this;
        this.errors = [];
        var file = event.target.files[0];
        if (file && file.type === "application/json") {
            var reader = new FileReader();
            reader.onload = function (e) {
                try {
                    _this.fileContent = JSON.parse(e.target.result);
                    _this.validateAndImport(_this.fileContent);
                }
                catch (err) {
                    _this.errors.push("Invalid JSON file");
                    console.error('Invalid JSON file');
                }
            };
            reader.readAsText(file);
        }
        else {
            this.errors.push("Please upload a valid JSON file");
            console.error('Please upload a valid JSON file');
        }
    };
    PayCodeConfigurationComponent.prototype.validateAndImport = function (jsonData) {
        var _this = this;
        this.errors = [];
        if (!jsonData.checksum) {
            this.errors.push("Missing 'checksum' in uploaded JSON file.");
        }
        else {
            var providedChecksum_1 = jsonData.checksum;
            delete jsonData.checksum;
            var jsonDataString = JSON.stringify(jsonData, null, 2);
            this.generateChecksum(jsonDataString).then(function (computedChecksum) {
                if (computedChecksum !== providedChecksum_1) {
                    _this.errors.push("Checksum validation failed");
                }
                _this.performDataValidation(jsonData);
            });
        }
    };
    PayCodeConfigurationComponent.prototype.generateChecksum = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encoder, dataBuffer, hashBuffer, checksum;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        encoder = new TextEncoder();
                        dataBuffer = encoder.encode(data);
                        return [4 /*yield*/, window.crypto.subtle.digest('SHA-256', dataBuffer)];
                    case 1:
                        hashBuffer = _a.sent();
                        checksum = Array.from(new Uint8Array(hashBuffer))
                            .map(function (b) { return b.toString(16).padStart(2, '0'); })
                            .join('');
                        return [2 /*return*/, checksum];
                }
            });
        });
    };
    PayCodeConfigurationComponent.prototype.performDataValidation = function (jsonData) {
        if (!jsonData.exceptions) {
            this.errors.push("Missing 'exceptions' in uploaded JSON file.");
        }
        else {
            if (!jsonData.exceptions.description || jsonData.exceptions.description.trim() === "") {
                this.errors.push("Missing 'description' in uploaded JSON file.");
            }
            if (!jsonData.exceptions.exAllocationType || jsonData.exceptions.exAllocationType.trim() === "") {
                this.errors.push("Missing 'exAllocationType' in uploaded JSON file.");
            }
            if (!jsonData.exceptions.exPayDiffType || jsonData.exceptions.exPayDiffType.trim() === "") {
                this.errors.push("Missing 'exPayDiffType' in uploaded JSON file.");
            }
        }
    };
    PayCodeConfigurationComponent.prototype.onUpload = function () {
        if (this.errors.length === 0) {
            if (this.fileContent && this.errors.length === 0) {
                var jsonStr = JSON.stringify(this.fileContent);
                var newId = this.generateUniqueId();
                this.isImport = true;
                this.sendData(jsonStr, this.isImport);
                var newPath = '/apps/common/paycode_details?orgLevelId=' + this.orgLevelId + '&id=' + newId;
                var url = window.location.origin + '/#/' + newPath;
                window.location.href = url;
                this.closePopup();
            }
            else {
                console.error('No file content to upload or there are validation errors.');
            }
        }
    };
    PayCodeConfigurationComponent.prototype.sendData = function (jsonStr, isImport) {
        var jsonData = jsonStr;
        this.dataService.setData(jsonData, isImport);
    };
    PayCodeConfigurationComponent.prototype.setOrgLevelId = function () {
        this.dataService.setOrgLevelId(this.orgLevelId);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeConfigurationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PayCodesConfigurationManagementService)
    ], PayCodeConfigurationComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "columnStateChangeSubscription", void 0);
    return PayCodeConfigurationComponent;
}());
export { PayCodeConfigurationComponent };
