import * as tslib_1 from "tslib";
import { ApiUtilService } from "../../../common";
import { PayPoliciesMapService } from "./pay-policies-map.service";
import { HttpHeaders, HttpRequest } from '@angular/common/http';
var PayPoliciesAPIService = /** @class */ (function () {
    function PayPoliciesAPIService(apiUtilService, mapService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.taApiRoot = this.getTAServicesApiRoot(); // Use the TA Services API root directly
    }
    PayPoliciesAPIService.prototype.getTAServicesApiRoot = function () {
        return this.apiUtilService.getTAServicesApiRoot() + "/taappend";
    };
    // Get all pay policies for a specific organization
    PayPoliciesAPIService.prototype.getAllPayPolicies = function (organizationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, response.data.map(this.mapService.mapToModel)];
                        }
                        return [2 /*return*/, []]; // Return empty list if no data found
                    case 3:
                        error_1 = _a.sent();
                        if (error_1.status === 404 || (error_1.error && error_1.error.status === 404)) {
                            return [2 /*return*/, []]; // Return empty list for 404 errors
                        }
                        this.handleError(error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Get a specific pay policy by ID for an organization
    PayPoliciesAPIService.prototype.getPayPolicyById = function (organizationId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId;
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, this.mapService.mapToModel(response.data)];
                        }
                        throw new Error("No pay policy found with ID: " + policyId);
                    case 3:
                        error_2 = _a.sent();
                        if (error_2.status === 404 || (error_2.error && error_2.error.status === 404)) {
                            return [2 /*return*/, null];
                        }
                        this.handleError(error_2);
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Create a new pay policy for an organization
    PayPoliciesAPIService.prototype.createPayPolicy = function (organizationId, dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, headers, request, response, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies";
                        headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                        request = new HttpRequest('POST', url, dto, { headers: headers });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        console.log('Pay policy created successfully.');
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        this.handleError(error_3);
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Delete a pay policy by ID for an organization
    PayPoliciesAPIService.prototype.deletePayPolicyById = function (organizationId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        console.log('Pay policy deleted successfully.');
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        this.handleError(error_4);
                        throw error_4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Get all pay policy rules for an organization
    PayPoliciesAPIService.prototype.getAllPayPolicyRules = function (organizationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/rules";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, response.data.map(this.mapService.mapRuleToModel)];
                        }
                        return [2 /*return*/, []]; // Return empty list if no data found
                    case 3:
                        error_5 = _a.sent();
                        if (error_5.status === 404 || (error_5.error && error_5.error.status === 404)) {
                            return [2 /*return*/, []]; // Return empty list for 404 errors
                        }
                        this.handleError(error_5);
                        throw error_5;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Get pay policy rules by policy ID for an organization
    PayPoliciesAPIService.prototype.getPayPolicyRulesByPolicyId = function (organizationId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId + "/rules";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request, true)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data) {
                            return [2 /*return*/, response.data.map(this.mapService.mapRuleToModel)];
                        }
                        return [2 /*return*/, []]; // Return empty list if no data found
                    case 3:
                        error_6 = _a.sent();
                        if (error_6.status === 404 || (error_6.error && error_6.error.status === 404)) {
                            return [2 /*return*/, []]; // Return empty list for 404 errors
                        }
                        this.handleError(error_6);
                        throw error_6;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Create a pay policy rule for an organization
    PayPoliciesAPIService.prototype.createPayPolicyRule = function (organizationId, policyId, dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, headers, request, response, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId + "/rules";
                        headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                        request = new HttpRequest('POST', url, dto, { headers: headers });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        console.log('Pay policy rule created successfully.');
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        this.handleError(error_7);
                        throw error_7;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Delete a pay policy rule by ID for an organization
    PayPoliciesAPIService.prototype.deletePayPolicyRule = function (organizationId, policyId, ruleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId + "/rules/" + ruleId;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        console.log('Pay policy rule deleted successfully.');
                        return [3 /*break*/, 4];
                    case 3:
                        error_8 = _a.sent();
                        this.handleError(error_8);
                        throw error_8;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayPoliciesAPIService.prototype.isPolicyUsed = function (organizationId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taApiRoot + "/organizations/" + organizationId + "/paypolicies/" + policyId + "/isused";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        if (response && response.data && typeof response.data.isUsed === 'boolean') {
                            return [2 /*return*/, response.data.isUsed];
                        }
                        return [2 /*return*/, false]; // Default to false if no data is found
                    case 3:
                        error_9 = _a.sent();
                        if (error_9.status === 404 || (error_9.error && error_9.error.status === 404)) {
                            return [2 /*return*/, false]; // Return false for 404 errors, assuming policy is not used
                        }
                        this.handleError(error_9);
                        throw error_9;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayPoliciesAPIService.prototype.handleError = function (error) {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        }
        else {
            console.error('An error occurred:', error);
        }
    };
    return PayPoliciesAPIService;
}());
export { PayPoliciesAPIService };
