import * as tslib_1 from "tslib";
import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { AddEvent, CancelEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../app.config';
import { appMessages } from '../../../app.messages';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { destroyService } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { DialogOptions, KendoGridStateHelper, KendoGridCustomSelectionHelper, StateManagementService, InfoDialogComponent, PopoverContentComponent } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { ShiftsManagementService } from '../../services/index';
import { Shift } from '../../models/shifts/shift';
import { ShiftRemoveDialogComponent } from './shift-remove-dialog/shift-remove-dialog.component';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { dateTimeUtils } from '../../../common/utils/index';
import { NgForm } from '@angular/forms';
import { AppSettingsManageService } from './../../../../app/app-settings/services';
import { ShiftSetting } from '../../models/shifts/shift-setting';
import { isNullOrUndefined } from 'util';
var ShiftsComponent = /** @class */ (function () {
    function ShiftsComponent(management, modalService, stateManagement, appSettingManageService) {
        var _this = this;
        this.modalService = modalService;
        this.stateManagement = stateManagement;
        this.appSettingManageService = appSettingManageService;
        this.columnsGroupName = 'ConfigureShifts';
        this.loadingPanelVisible = true;
        this.prohibitedNameValues = [];
        this.parentShift = undefined;
        this.defaultShift = [
            { id: 0, name: 'NO' },
            { id: 1, name: 'YES' }
        ];
        this.originalPartialShiftList = undefined;
        this.management = management;
        this.gridState = new KendoGridStateHelper("ShiftsComponent");
        this.gridState.preventCloseEditorOnSave = true;
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var shift = new Shift();
            var start = moment().startOf('day');
            var end = start.clone().add(1, 'hours');
            shift.start = start.toDate();
            shift.end = end.toDate();
            shift.name = Shift.createShiftName(shift.start, shift.end);
            shift.duration = 1;
            shift.lunchDuration = 0;
            shift.hasPartialShift = false;
            shift.shiftSetting = new ShiftSetting();
            _this.crudHelper.addItem(shift);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
        this.getSettings();
    }
    Object.defineProperty(ShiftsComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftsComponent.prototype, "settingPopover", {
        get: function () {
            return this.m_settingPopover;
        },
        set: function (value) {
            this.m_settingPopover = value;
        },
        enumerable: true,
        configurable: true
    });
    /* ---------------------- Event Hanlders -----------*/
    ShiftsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.appMessages = appMessages;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false
        };
        this.stateManagement.init('ShiftsComponent');
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (shift) {
            if (shift) {
                _this.updateProhibitedNameValues(shift);
                if (_this.mainForm) {
                    _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                        if (_this.mainForm.dirty) {
                            _this.management.markAsDirty();
                        }
                    });
                }
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.savedSubscription = this.management.savedItemCmd$.subscribe(function (shift) {
            _this.parentShift = undefined;
            _this.gridState.closeEditor(_this.grid);
            _this.gridState.refreshGrid();
        });
        this.errorSubscription = this.management.errorNotify$.subscribe(function (message) {
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Error', message, _this.modalService, function (result) {
                _.noop();
            }, options);
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (request) {
            if (request.dialogOptions) {
                var dialog_1 = _this.modalService.globalAnchor.openDialog(ShiftRemoveDialogComponent, 'Choose shift to reassign employees', request.dialogOptions, null, function (result) {
                    if (result) {
                        request.itemToDelete = _this.management.onRemoveChildPartialShifts(request.itemToDelete);
                        _this.management.doRemoveItem(request.itemToDelete, dialog_1.shift);
                    }
                });
                dialog_1.shifts = _this.management.container.records.filter(function (obj, index, array) {
                    if (obj === request.itemToDelete || obj.id === 0)
                        return false;
                    return true;
                });
            }
            else if (request.itemToDelete.usedInIdealSchedule) {
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Confirmation', 'Shift used in Ideal Schedule. Do you want to delete the shift?', _this.modalService, function (result) {
                    if (result) {
                        request.itemToDelete = _this.management.onRemoveChildPartialShifts(request.itemToDelete);
                        _this.management.doRemoveItem(request.itemToDelete);
                    }
                }, options);
            }
            else {
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Confirmation', 'Do you want to delete the shift?', _this.modalService, function (result) {
                    if (result) {
                        request.itemToDelete = _this.management.onRemoveChildPartialShifts(request.itemToDelete);
                        _this.management.doRemoveItem(request.itemToDelete);
                    }
                }, options);
            }
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        this.state.isLoading = false;
        this.onLoadSubscription = this.management.onLoaded$.subscribe(function () {
            _this.stateManagement.loadedData({});
            _this.loadingPanelVisible = false;
        });
    };
    // Must be, see #issueWithAOTCompiler
    ShiftsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    ShiftsComponent.prototype.getSettings = function () {
        var _this = this;
        var appServerConfig = this.appSettingManageService.getAppServerConfig();
        appServerConfig.then(function (result) {
            _this.isDefaultShiftEnabled = result.isDefaultShiftEnabled;
            _this.isPartialShiftEnabled = result.isPartialShiftEnabled;
            _this.defaultPartialShiftCount = result.defaultPartialShiftCount;
        });
    };
    ShiftsComponent.prototype.onCopyItems = function (event) {
        event.preventDefault();
        this.management.openCopyItems();
    };
    ShiftsComponent.prototype.switchToConfigure = function () {
        this.management.closeCopyItems();
    };
    ShiftsComponent.prototype.updateProhibitedNameValues = function (shift) {
        if (shift) {
            var values_1 = [];
            _.each(this.management.container.records, function (s) {
                if (shift.id !== s.id) {
                    values_1.push(s.name);
                }
            });
            this.prohibitedNameValues = values_1;
        }
    };
    ShiftsComponent.prototype.onShiftDateChanged = function (shift) {
        var hours = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
        var duration = hours - shift.lunchDuration;
        if (duration < 0 || _.isNaN(shift.lunchDuration) && duration < shift.lunchDuration) {
            shift.duration = 0;
            shift.lunchDuration = duration;
            return;
        }
        if (shift.lunchDuration > 0) {
            shift.duration = hours - shift.lunchDuration;
        }
        else {
            shift.duration = hours;
        }
        if ((!shift.isNameEdited && this.management.isEditingNewItem) || (shift.parentShiftId > 0)) {
            shift.name = Shift.createShiftName(shift.start, shift.end);
        }
    };
    ShiftsComponent.prototype.shiftPaidTimeChanged = function (shift, value) {
        if (_.isNaN(value)) {
            shift.duration = 0;
            return;
        }
        shift.duration = value > 0 ? value : 0;
        if (shift.start) {
            var totalHours = shift.duration + shift.lunchDuration;
            var milliseconds = totalHours * 1000 * 60 * 60;
            shift.end = new Date(milliseconds + shift.start.getTime());
        }
        if (!shift.isNameEdited && this.management.isEditingNewItem) {
            shift.name = Shift.createShiftName(shift.start, shift.end);
        }
    };
    ShiftsComponent.prototype.shiftUnpaidTimeChanged = function (shift, value) {
        if (_.isNaN(value)) {
            shift.lunchDuration = 0;
            return;
        }
        var hours = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
        shift.lunchDuration = value > 0 ? value : 0;
        shift.duration = hours - shift.lunchDuration;
        if (shift.duration < 0) {
            shift.duration = 0;
        }
        if (shift.start) {
            var totalHours = shift.duration + shift.lunchDuration;
            var milliseconds = totalHours * 1000 * 60 * 60;
            shift.end = new Date(milliseconds + shift.start.getTime());
        }
    };
    ShiftsComponent.prototype.descriptionChanged = function (shift) {
        shift.isNameEdited = true;
    };
    ShiftsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
        else if (action === EditableListActionKind.START_EDIT) {
            this.crudHelper.startEdit(item, index);
        }
    };
    ShiftsComponent.prototype.onMobileEditorEvent = function (action, item, index) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.completeEdit(item, index);
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.cancelEdit(index);
        }
    };
    Object.defineProperty(ShiftsComponent.prototype, "minDateLimit", {
        get: function () {
            return this.parentShift ? this.parentShift.start : new Date();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftsComponent.prototype, "maxDateLimit", {
        get: function () {
            return this.parentShift ? this.parentShift.end : new Date();
        },
        enumerable: true,
        configurable: true
    });
    ShiftsComponent.prototype.defaultShiftChange = function (shift, index, e) {
        var defaultShift = _.filter(this.crudHelper.container.records, function (i) { return i.defaultshift == 1; });
        var isShiftPresent = _.some(defaultShift, function (i) { return i.id == shift.id; });
        if (defaultShift.length == 3 && isShiftPresent && e == 'NO') {
            InfoDialogComponent.OpenDialog('Warning', 'User not allowed to remove already configured Default Shift', this.modalService);
            this.crudHelper.cancelEdit(index);
            return;
        }
        if (defaultShift.length === 3 && !isShiftPresent && e == 'YES') {
            InfoDialogComponent.OpenDialog('Warning', "Please note, these changes to the Default Shift will take affect on " + moment().format('MM/DD/YYYY'), this.modalService);
            return;
        }
    };
    ShiftsComponent.prototype.onAllowPartialShiftChanged = function (shift, index) {
        // Up on partial shift enable
        if (shift.hasPartialShift && shift.partialShiftList.length == 0) {
            shift.shiftSetting = new ShiftSetting();
            shift.shiftSetting.isUpdated = true;
            shift.shiftSetting.minPartialShiftCount = this.defaultPartialShiftCount;
            var setting = shift.shiftSetting;
            var remaining = shift.duration + shift.lunchDuration;
            var count = setting.minPartialShiftCount;
            if (remaining > setting.minPartialShiftCount) {
                var pDuration = _.round(remaining / count);
                var newstart = shift.start;
                while (count > 0 && remaining >= pDuration) {
                    var newPS = this.createPartialShift(shift, newstart, pDuration);
                    newstart = newPS.end;
                    --count;
                    remaining = remaining - pDuration;
                    pDuration = count == 1 ? remaining : pDuration;
                }
            }
            this.grid.expandRow(index);
            this.settingPopover.show();
        }
        if (!shift.hasPartialShift) {
            shift.partialShiftList.forEach(function (ps) { if (ps.id > 0) {
                shift.deletedPartials.push(ps);
            } });
            //TODO: Create A dialog box before clearing.
            shift.partialShiftList.splice(0, shift.partialShiftList.length);
            //Reset ShiftSetting
            shift.shiftSetting = new ShiftSetting();
            this.grid.collapseRow(index);
            if (this.settingPopover && this.settingPopover.popoverDir) {
                this.settingPopover.popoverDir.toggle();
            }
        }
        this.parentShift = shift;
        this.management.editingItem = shift;
    };
    ShiftsComponent.prototype.onEdit = function ($event) {
        this.parentShift = $event.dataItem;
        this.originalPartialShiftList = _.cloneDeep($event.dataItem.partialShiftList);
        $event.dataItem.isEditing = true;
        $event.sender.expandRow($event.rowIndex);
        this.gridState.editHandler($event);
    };
    ShiftsComponent.prototype.onCancel = function ($event) {
        $event.dataItem.deletedPartials = [];
        $event.dataItem.partialShiftList = this.originalPartialShiftList;
        this.parentShift = undefined;
        this.originalPartialShiftList = undefined;
        this.grid.collapseRow($event.rowIndex);
        this.gridState.cancelHandler($event);
        $event.dataItem.isEditing = false;
    };
    ShiftsComponent.prototype.hasPartialDataOrNew = function (shift) {
        return shift.hasPartialShift ? true : false;
    };
    ShiftsComponent.prototype.pgAddHandler = function ($event) {
        $event.dataItem = this.createPartialShift(this.parentShift, this.parentShift.start, 1);
        $event.sender.editRow($event.rowIndex);
    };
    ShiftsComponent.prototype.pgRemoveHandler = function ($event) {
        $event.dataItem.isDeleted = true;
        if ($event.dataItem.id > 0) {
            this.parentShift.deletedPartials.push($event.dataItem);
        }
        var index = this.parentShift.partialShiftList.findIndex(function (_a) {
            var id = _a.id;
            return $event.dataItem.id === id;
        });
        this.parentShift.partialShiftList.splice(index, 1);
        if (this.parentShift.partialShiftList.length == 0) {
            this.parentShift.hasPartialShift = false;
        }
    };
    ShiftsComponent.prototype.pgCancelHandler = function ($event) {
        $event.dataItem.isUpdated = false;
        $event.sender.closeRow($event.rowIndex);
    };
    ShiftsComponent.prototype.pgEditHandler = function ($event) {
        $event.sender.editRow($event.rowIndex);
    };
    ShiftsComponent.prototype.pgSaveHanlder = function ($event) {
        $event.dataItem.isUpdated = true;
        if (this.isPartialShiftOverlap()) {
            InfoDialogComponent.OpenDialog('Error', 'There is an overlapping on Start and End time between partial shifts.', this.modalService);
            this.pgCancelHandler($event);
            return;
        }
        if (this.isPartialShiftTotalOverflow()) {
            InfoDialogComponent.OpenDialog('Error', 'Total number of hours, accumulating partial shifts duration, is beyond parent shift total duration.', this.modalService);
            this.pgCancelHandler($event);
            return;
        }
        $event.sender.closeRow($event.rowIndex);
    };
    ShiftsComponent.prototype.onSaveShiftSetting = function (shift, index) {
        shift.shiftSetting.isUpdated = true;
    };
    ShiftsComponent.prototype.applyShiftSetting = function (shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(shift && shift.id && !shift.shiftSetting.isUpdated)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.management.getShiftSetting(shift.id).then(function (setting) {
                                if (!setting) {
                                    setting = new ShiftSetting();
                                    setting.shiftId = shift.id;
                                    setting.minPartialShiftCount = _this.defaultPartialShiftCount;
                                }
                                if (setting.minPartialShiftCount == 0) {
                                    setting.minPartialShiftCount = _this.defaultPartialShiftCount;
                                }
                                shift.shiftSetting = setting;
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ShiftsComponent.prototype.onShowShiftSetting = function (shift) {
        if (shift.hasPartialShift) {
            this.applyShiftSetting(shift);
        }
        else {
            this.settingPopover.hide();
        }
    };
    ShiftsComponent.prototype.createPartialShift = function (parent, newStart, addDuration, lunchduration) {
        if (lunchduration === void 0) { lunchduration = 0; }
        var ps = new Shift();
        ps.isUpdated = true;
        ps.start = newStart;
        var endMoment = moment(newStart).clone().add(addDuration, 'hours');
        ps.end = endMoment.toDate();
        ps.name = Shift.createShiftName(ps.start, ps.end);
        ps.duration = addDuration;
        ps.lunchDuration = lunchduration;
        ps.hasPartialShift = false;
        ps.shiftSetting = new ShiftSetting();
        if (!isNullOrUndefined(parent)) {
            ps.group = parent.group;
            ps.parentShiftId = parent.id;
            parent.partialShiftList.push(ps);
        }
        return ps;
    };
    ShiftsComponent.prototype.isPartialShiftOverlap = function () {
        for (var i = 0; i < this.parentShift.partialShiftList.length; ++i) {
            var ps1 = this.parentShift.partialShiftList[i];
            for (var j = i + 1; j < this.parentShift.partialShiftList.length; ++j) {
                var ps2 = this.parentShift.partialShiftList[j];
                if ((ps1.start < ps2.start && ps2.start < ps1.end) || (ps1.start < ps2.end && ps2.end < ps1.end)) {
                    return true;
                }
            }
        }
        return false;
    };
    ShiftsComponent.prototype.isPartialShiftTotalOverflow = function () {
        var total = 0;
        this.parentShift.partialShiftList.forEach(function (ps, i) { total += ps.duration; });
        return total > this.parentShift.duration + this.parentShift.lunchDuration;
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ShiftsManagementService)
    ], ShiftsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "errorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "mainFormSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsComponent.prototype, "onLoadSubscription", void 0);
    return ShiftsComponent;
}());
export { ShiftsComponent };
