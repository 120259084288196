import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';;
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { PayCodeDetailsManagementService } from '../../services/pay-code-details/pay-code-details-management.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { PaycodeExceptionModel } from '../../models/pay-code-details/models/paycode-exception.model';
import { AdditionalRequirementModel } from '../../models/pay-code-details/models/additional-requirement.model';
import { RuleModel } from '../../models/pay-code-details/models/rule.model';
import { ColorUtil } from '../../../common/utils/index';
import { DatePipe, Location } from '@angular/common';
import { AllocationTypeModel } from '../../models/pay-code-details/models/allocation-type.model';
import { ExceptionGroupModel } from '../../models/pay-code-details/models/exception-group.model';
import { ExceptionVariableModel } from '../../models/pay-code-details/models/exception-variable.model';
import { PayDiffTypeModel } from '../../models/pay-code-details/models/pay-diff-type.model';
import { SpecialCodeModel } from '../../models/pay-code-details/models/special-code.model';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../common/index';
import { AccessManagementService } from '../../services';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { InterfaceCategory } from '../../models/pay-code-details/models/interface-category.model';
import { PayCodeMinException } from '../../models/pay-code-details/models/paycode-min-exception.model';
import { WorkCode } from '../../models/pay-code-details/models/work-code.model';
import { RuleFormulaModel } from '../../models/pay-code-details/models/rule-formula.model';
import { DataService } from '../../services/pay-code-details/data.service';
import { take } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'slx-paycode-details',
  templateUrl: 'pay-code-details.component.html',
  styleUrls: ['pay-code-details.component.scss'],
  providers: [
    AccessManagementService,
    PayCodeDetailsManagementService,
    ColumnManagementService
  ]
})

export class PayCodeDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('templateForm', { static: true }) templateForm: NgForm;
  public showConfirmationDialog = false;
  private savedFormState: any = null;
  orgLevelId: number | null = null;

  public container: PayCodeDetailsContainer = new PayCodeDetailsContainer();
  public state: { isLoading: boolean; exceptionsDirty: boolean; additionalRequirementsDirty: boolean; rulesDirty: boolean; };
  public allocationTypes: AllocationTypeModel[] = [];
  public exceptionGroups: ExceptionGroupModel[] = [];
  public exceptionVariables: ExceptionVariableModel[] = [];
  public payDiffTypes: PayDiffTypeModel[] = [];
  public specialCodes: SpecialCodeModel[] = [];
  public payCodeExceptions: PayCodeMinException[] = [];
  public workCodes: WorkCode[] = [];
  public interfaceCategories: InterfaceCategory[] = [];
  public appConfig: IApplicationConfig;
  public selectedOption: string;
  isImport: boolean = false;

  private subscriptions: Subscription[] = [];
  private dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly columnGroup: string = 'PayCodeDetails';

  exceptionDescription: string;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgId: number;
  jsonData: any;


  constructor(
    private stateManagement: StateManagementService,
    private management: PayCodeDetailsManagementService,
    private columnManagementService: ColumnManagementService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private location: Location,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.state = {
      isLoading: false,
      exceptionsDirty: false,
      additionalRequirementsDirty: false,
      rulesDirty: false
    };

    this.subscriptions.push(
      this.dataLoaded$.subscribe(loaded => {
        if (loaded) {
          this.sanitizeData();
          this.populateDropdownData();
          this.state.isLoading = false;
          this.cdr.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.orgId = orgLevel.organizationId;
      })
    );

    this.bindPageLoadData();
    this.management.init();

    this.container = this.management.container;
    this.state.isLoading = true;

    this.subscriptions.push(
      this.dataService.data$.pipe(take(1)).subscribe(combinedData => {
        if (combinedData) {
          this.jsonData = combinedData.data;
          this.isImport = combinedData.isImport;

          // If not importing data, proceed to load data
          if (!this.isImport) {
            this.loadData();
          }
        } else {
          // No data being imported, proceed to load data
          this.loadData();
        }
      })
    );

    this.dataService.data$.subscribe(data => {
      if (data && data.orgLevelId !== undefined) {
        this.orgLevelId = data.orgLevelId;
        localStorage.setItem('orgLevelId', this.orgLevelId.toString());
      } else {
        const storedOrgLevelId = localStorage.getItem('orgLevelId');
        if (storedOrgLevelId !== null) {
          this.orgLevelId = +storedOrgLevelId;
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.jsonData !== null && this.isImport) {
      this.state.isLoading = true; // Start loading state
      this.cdr.detectChanges();    // Ensure UI updates to show spinner

      this.importData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async loadData(): Promise<void> {
    try {
      await this.fetchAndSetData();
      if (this.container.exceptions.some(exception => exception.deleteInd)) {
        this.location.back();
        return;
      }
      this.dataLoaded$.next(true);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      this.cdr.detectChanges();
    }
  }

  private async fetchAndSetData(): Promise<void> {
    this.cdr.detectChanges();

    (this.container && this.container.id)
      ? await this.fetchDataAndPopulate(this.container.id)
      : console.error('Container ID is not set.');

    this.cdr.detectChanges();
  }

  private async fetchDataAndPopulate(containerId: any): Promise<void> {
    try {
      await Promise.all([
        this.management.fetchPaycodeExceptions(containerId),
        this.management.fetchAdditionalRequirements(containerId),
        this.management.fetchRulesByExceptionId(containerId),
      ]);

      this.populateDropdownData();

      // Ensure additionalRequirements array is initialized for new pay codes
      if (!this.container.additionalRequirements || this.container.additionalRequirements.length === 0) {
        this.container.additionalRequirements = [this.newAdditionalRequirement()];
      }

      this.setRuleFormulasValueType();
      this.sanitizeData();
      this.dataLoaded$.next(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  private populateDropdownData(): void {
    if (this.container.exceptions && this.container.exceptions.length > 0) {
      const exception = this.container.exceptions[0];
      this.allocationTypes = exception.allocationTypes || [];
      this.exceptionGroups = exception.exceptionGroups || [];
      this.exceptionVariables = exception.exceptionVariables || [];
      this.payDiffTypes = exception.payDiffTypes || [];
      this.specialCodes = exception.specialCodes || [];
      this.payCodeExceptions = exception.payCodeExceptions || [];
      this.workCodes = exception.workCodes || [];
      this.interfaceCategories = exception.interfaceCategories || [];

      exception.exInterfaceCategoryId = this.adjustInterfaceCategoryIdForDisplay(exception.exInterfaceCategoryId);
      this.parseAndFormatAdditionalRequirements();
      this.determineDefaultOption();
    }
  }

  private sanitizeData(): void {
    console.log('Sanitizing data...', this.container.exceptions);

    if (this.container.exceptions && this.container.exceptions.length > 0) {
      // Ensure the container exceptions aren't reset
      this.container.exceptions = this.container.exceptions.map(this.mergeWithDefaults.bind(this));

      // Sanitize rules and additional requirements
      this.container.rules = this.sanitizeArray(this.container.rules);
      this.container.additionalRequirements = this.sanitizeArray(this.container.additionalRequirements);
    }

    console.log('Sanitized exceptions:', this.container.exceptions);  // Log state after sanitizing
  }


  private mergeWithDefaults(exception: PaycodeExceptionModel): PaycodeExceptionModel {
    const defaults = this.newPaycodeException();
    // Merge defaults with exception ensuring undefined values in exception are replaced by defaults
    Object.keys(defaults).forEach(key => {
      if (exception[key] === undefined) {
        exception[key] = defaults[key];
      }
    });
    return exception;
  }

  private sanitizeArray<T>(array: T[]): T[] {
    return array.map(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === null || item[key] === undefined) {
          item[key] = '';
        }
      });
      return item;
    });
  }

  private formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  private determineDefaultOption(): void {
    if (this.container.exceptions[0].exActionStart || this.container.exceptions[0].exActionEnd) {
      this.selectedOption = 'Action';
    } else if (this.container.exceptions[0].exActionStartVar || this.container.exceptions[0].exActionInterval) {
      this.selectedOption = 'Interval';
    } else if (this.container.exceptions[0].exFormulaStart || this.container.exceptions[0].exFormulaEnd) {
      this.selectedOption = 'Formula';
    } else {
      this.selectedOption = 'Action';
    }
    this.updateDisplayedFields();
  }

  private parseOverride(value: any): number | null {
    if (typeof value === 'string') {
      // Try to match the specific pattern first
      const match = value.match(/OT (\d+)/);
      if (match) {
        return +match[1];
      }
      // Try to convert directly if no match
      const intValue = parseInt(value, 10);
      return isNaN(intValue) ? null : intValue;
    }
    return typeof value === 'number' ? value : null;
  }

  private parseAndFormatAdditionalRequirements(): void {
    this.container.additionalRequirements.forEach(req => {
      req.regularOverrideTemp = this.parseOverride(req.regularOverride);
      req.otOverrideTemp = this.parseOverride(req.otOverride);
      req.holidayOverrideTemp = this.parseOverride(req.holidayOverride);
    });
    this.cdr.detectChanges();
  }

  public updateDisplayedFields(): void {
    this.cdr.detectChanges();
  }

  public async saveChanges(): Promise<void> {
    if (this.isSaveDisabled()) {
      return;
    }
    this.state.isLoading = true;
    this.sanitizeData();

    try {
      if (this.state.exceptionsDirty) {
        // Save pay code exceptions first
        await this.savePaycodeExceptions();
      }

      // Proceed only if exceptions save was successful
      if (this.state.additionalRequirementsDirty) {
        await this.saveAdditionalRequirements();
      }

      if (this.state.rulesDirty) {
        await this.saveRules();
      }

      this.state.isLoading = false;
      this.state.exceptionsDirty = false;
      this.state.additionalRequirementsDirty = false;
      this.state.rulesDirty = false;
      this.isImport = false;
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error saving changes:', error);
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }


  private async savePaycodeExceptions(): Promise<void> {
    const updatedData = this.container.exceptions.map(exception => ({ ...exception }));
    this.state.isLoading = true;

    const newExceptionDescription = updatedData[0].exceptionDescription.trim().toLowerCase();
    const currentExceptionId = updatedData[0].id;

    // Check if the exception description already exists (case-insensitive)
    if (this.payCodeExceptions.some(existingException =>
      existingException &&
      existingException.description &&
      existingException.description.trim().toLowerCase() == newExceptionDescription &&
      existingException.id != currentExceptionId)) {
      window.alert("Pay code description is already used.");
      this.state.isLoading = false;
      throw new Error('Duplicate exception description');
    }

    try {
      const savePromises = updatedData.map(dataItem => this.management.savePaycodeException(dataItem));
      await Promise.all(savePromises);
      this.state.exceptionsDirty = false;
    } catch (error) {
      console.error('Error saving paycode exceptions:', error);
      throw error; // Re-throw the error to propagate it to the calling method
    } finally {
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private async saveAdditionalRequirements(): Promise<void> {
    const updatedData = this.container.additionalRequirements;
    this.state.isLoading = true;

    try {
      const savePromises = updatedData.map(dataItem => this.management.saveAdditionalRequirement(this.container.id, dataItem));
      await Promise.all(savePromises);
      this.state.additionalRequirementsDirty = false;
    } catch (error) {
      console.error('Error saving additional requirements:', error);
    } finally {
      this.parseAndFormatAdditionalRequirements();
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private async saveRules(): Promise<void> {
    const updatedData = this.container.rules.map(rule => ({
      ...rule,
      whereClause: this.generateWhereClause(rule), // Generate whereClause before validation
      startDate: rule.startDate ? this.formatDate(rule.startDate) : undefined,
      endDate: rule.endDate ? this.formatDate(rule.endDate) : undefined,
    }));

    this.state.isLoading = true;

    try {
      const savePromises = [];

      for (const rule of updatedData) {
        if (rule.whereClause) {
          const isValid = await this.management.validateRule(rule.whereClause);
          if (!isValid) {
            this.state.isLoading = false;
            this.cdr.detectChanges();
            return; // If validation fails, stop saving
          }
        }

        if (rule.id && rule.id > 0) {
          savePromises.push(this.management.saveRule(rule).then(savedRule => {
            rule.isNew = false;  // Reset the isNew flag after saving
            return savedRule;
          }));
        }
      }

      await Promise.all(savePromises);

      // Re-fetch the rules to ensure the state is up-to-date
      await this.management.fetchRulesByExceptionId(this.container.id);
      this.setRuleFormulasValueType();
      this.state.rulesDirty = false;
    } catch (error) {
      console.error('Error saving rules:', error);
    } finally {
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  public bindPageLoadData(): void {
    this.initServices();
    this.appConfig = appConfig;
  }

  private initServices(): void {
    this.stateManagement.init('PayCodeDetailsComponent');
    this.columnManagementService.init('PayCodeDetailsComponent');
    this.columnManagementService.initGroup(this.columnGroup, 12);
  }

  public markExceptionsDirty(): void {
    this.state.exceptionsDirty = true;
  }

  public markAdditionalRequirementsDirty(): void {
    this.state.additionalRequirementsDirty = true;
  }

  public markRulesDirty(): void {
    this.state.rulesDirty = true;
  }

  public isSaveDisabled(): boolean {
    const formValid = this.templateForm ? this.templateForm.valid : false;
    const dropdownsValid = this.container.exceptions.every(exception =>
      exception.exAllocationType && exception.exPayDiffType
    );

    return !(
      formValid &&
      dropdownsValid &&
      (this.state.exceptionsDirty || this.state.additionalRequirementsDirty || this.state.rulesDirty)
    );
  }

  public onExActionStartVarChange(value: number): void {
    this.container.exceptions[0].exActionStartVar = value;
    this.markExceptionsDirty();
  }

  public onGroupIdChange(value: number): void {
    this.container.exceptions[0].groupId = value;
    this.markExceptionsDirty();
  }

  public onExPayDiffTypeChange(value: number): void {
    this.container.exceptions[0].exPayDiffType = value;
    this.markExceptionsDirty();
  }

  public onExAllocationTypeChange(value: number): void {
    this.container.exceptions[0].exAllocationType = value;
    this.markExceptionsDirty();
  }

  public onExWorkStatusChange(value: string): void {
    this.container.exceptions[0].exWorkStatus = value;
    this.markExceptionsDirty();
  }

  public intToHexColor(value: number): string {
    return ColorUtil.DecToHexString(value, true);
  }

  public onColorChange(event: any): void {
    const colorValue = parseInt(event.target.value.replace('#', ''), 16);
    this.container.exceptions[0].screenColor = colorValue;
    this.markExceptionsDirty();
  }

  public onActionVariableChange(value: string, field: 'exActionStart' | 'exActionEnd'): void {
    this.container.exceptions[0][field] = value;
    this.markExceptionsDirty();
  }

  onOverrideChange(field: string, value: number): void {
    this.container.additionalRequirements[0][field + 'Temp'] = value;
    this.container.additionalRequirements[0][field] = `OT ${value}`;
    this.markAdditionalRequirementsDirty();
  }

  cancel(): void {
    if (this.isSaveDisabled()) {
      this.cancelChanges();
    }
    else {
      this.showConfirmationDialog = true;
    }
  }

  isFormDirty(): boolean {
    return this.state.exceptionsDirty ||
      this.state.additionalRequirementsDirty ||
      this.state.rulesDirty;
  }

  onConfirm(): void {
    this.showConfirmationDialog = false;
    this.cancelChanges();
  }

  onDialogCancel(): void {
    this.showConfirmationDialog = false;
  }

  cancelChanges(): void {
    this.location.back();
  }

  public async deletePaycodeException(): Promise<void> {
    this.state.isLoading = true;
    try {
      await this.management.deletePaycodeException(this.container.id);
      this.location.back();
    } catch (error) {
      console.error('Error deleting paycode exception:', error);
      this.state.isLoading = false;
    }
  }

  addRule(): void {
    this.container.rules.push(this.newRule());
    this.markRulesDirty();
  }

  public removeRule(index: number): void {
    const rule = this.container.rules[index];
    if (rule.isNew) {
      this.container.rules.splice(index, 1);
      this.cdr.detectChanges();
    } else if (rule.id && rule.id > 0) {
      this.management.deleteRule(rule.id).then(() => {
        this.setRuleFormulasValueType();
        this.cdr.detectChanges();
      }).catch(error => {
        console.error('Error deleting rule:', error);
      });
    }
  }

  addRuleFormula(rule: RuleModel): void {
    const newFormula: RuleFormulaModel = {
      id: Math.floor(Math.random() * 9000000) + 1000000,
      ruleId: rule.id,
      variableId1: '',
      operator: '=',
      variableId2: '',
      isNew: true,
      valueType1: 'value',
      valueType2: 'value'
    };
    rule.ruleFormulas.push(newFormula);
    this.markRulesDirty();
    this.cdr.detectChanges();
  }

  removeRuleFormula(rule: RuleModel, formulaIndex: number): void {
    const formula = rule.ruleFormulas[formulaIndex];
    if (formula.isNew) {
      rule.ruleFormulas.splice(formulaIndex, 1);
      this.cdr.detectChanges();
    }
    else if (formula.id && formula.id > 0) {
      this.management.deleteRuleFormula(rule.id, formula.id).then(() => {
        rule.ruleFormulas.splice(formulaIndex, 1);
        this.cdr.detectChanges();
      }).catch(error => {
        console.error('Error deleting rule formula:', error);
      });
    }
  }

  private setRuleFormulasValueType(): void {

    if (!this.container.rules) {
      return;
    }

    this.container.rules.forEach(rule => {
      rule.ruleFormulas.forEach(formula => {

        if (formula.variableId2 != null && formula.variableId2 != undefined) {
          if (formula.variableId2.includes('@')) {
            formula.valueType2 = 'formula';
          } else if (this.exceptionVariables.some(variable => variable.description === formula.variableId2)) {
            formula.valueType2 = 'variable';
          } else {
            formula.valueType2 = 'value';
          }
        }


        if (formula.variableId1 != null && formula.variableId1 != undefined) {
          if (formula.variableId1.includes('@')) {
            formula.valueType1 = 'formula';
          } else if (this.exceptionVariables.some(variable => variable.description === formula.variableId1)) {
            formula.valueType1 = 'variable';
          } else {
            formula.valueType1 = 'value';
          }
        }
      }
      );
    });
  }

  private generateWhereClause(rule: RuleModel): string {
    const clauses = rule.ruleFormulas.map(formula => {
      const operator = formula.operator;
      let variable1;
      let variable2;

      if (formula.valueType1 === 'variable') {
        variable1 = this.getFieldProperty(formula.variableId1);
      } else if (formula.valueType1 === 'formula') {
        variable1 = formula.variableId1.startsWith('@') ? formula.variableId1.slice(1) : formula.variableId1;
      } else {
        variable1 = formula.variableId1;
      }

      if (formula.valueType2 === 'variable') {
        variable2 = this.getFieldProperty(formula.variableId2);
      } else if (formula.valueType2 === 'formula') {
        variable2 = formula.variableId2.startsWith('@') ? formula.variableId2.slice(1) : formula.variableId2;
      } else {
        variable2 = formula.variableId2;
      }

      // Check if dataType is 'char' and operator is '=' to wrap variable1 in single quotes
      const variable23 = this.exceptionVariables.find(v => v.description === formula.variableId1);
      if (variable23 && variable23.dataType === 'char' && operator === '=' && !variable1.startsWith("'") && !variable1.endsWith("'")) {
        variable1 = `'${variable1}'`;
      }

      // Check if dataType is 'char' and operator is '=' to wrap variable2 in single quotes
      const variable = this.exceptionVariables.find(v => v.description === formula.variableId2);
      if (variable && variable.dataType === 'char' && operator === '=' && !variable2.startsWith("'") && !variable2.endsWith("'")) {
        variable2 = `'${variable2}'`;
      }

      return `${variable1} ${operator} ${variable2}`;
    });

    console.log('Generated WHERE Clause:', `WHERE ${clauses.join(' AND ')}`);

    return `WHERE ${clauses.join(' AND ')}`;
  }

  private getFieldProperty(variableDescription: string): string {
    const variable = this.exceptionVariables.find(v => v.description === variableDescription);
    return variable ? variable.field : '';
  }

  private adjustInterfaceCategoryIdForDisplay(id: number): number {
    return (Number)(id) + 1;
  }

  private newPaycodeException(): PaycodeExceptionModel {
    return {
      id: this.container.id,
      exceptionDescription: '',
      screenColor: 0,
      accrualInd: false,
      paidInd: false,
      payDiffPct: 0,
      deleteInd: false,
      discrepancyInd: false,
      exAllocationType: 0,
      exInterface: '',
      exWorkStatus: '',
      exInterfaceCategoryId: 0,
      exFormulaStart: '',
      exFormulaEnd: '',
      exAllowMultiple: false,
      exCountTowardsOvertimeInd: false,
      exSortOrder: 0,
      exMinimumInterval: 0,
      exMaximumAppliedInterval: 0,
      exMinimumAppliedInterval: 0,
      exShiftDiffCategory: '',
      exAddlVariable: '',
      ex2ndPayRateExceptionId: 0,
      exSecondaryJobCode: 0,
      exApplyToWeekday: false,
      exApplyShiftDiff: false,
      groupId: 0,
      exAction: '',
      exActionStart: '',
      exActionEnd: '',
      exActionStartVar: 0,
      exActionInterval: 0,
      exActionMessage: '',
      exDepartmentId: 0,
      exPayDiffType: 0,
      exShowInExceptionGrid: false,
      exAmount: 0,
      exDisplayGroup: 0,
      exOrganizationId: this.orgId,
      exUseInTimesheets: false,
      workedInd: false,
      acaInd: false,
      exceptionColumnGroup: '',
      isException: false
    };
  }

  private newAdditionalRequirement(): AdditionalRequirementModel {
    return {
      ignoreBreak: false,
      calculateNetWorkTime: false,
      useContiguousTime: false,
      stayWithinExceptionTimeframe: false,
      exportAsDollarAmount: false,
      paidAtHolidayRate: false,
      regularOverride: '',
      otOverride: '',
      holidayOverride: '',
      overtimeHolidayOverride: '',
      otherCodeOverride: '',
      recalculate: '',
      requirementIds: {}
    };
  }

  private newRule(): RuleModel {
    return {
      id: Math.floor(Math.random() * 9000000) + 1000000,
      ruleDescription: '',
      startDate: new Date(),
      endDate: undefined,
      period: 0,
      organizationId: this.orgId,
      departmentId: 0,
      jobCode: 0,
      exceptionId: this.container.id,
      whereClause: '',
      ruleFormulas: [],
      isNew: true
    };
  }

  private async importData(): Promise<void> {
    try {
      console.log('Starting import...');  // Log when import starts

      const parsedData = JSON.parse(this.jsonData);
      if (parsedData) {
        await this.management.fetchPaycodeExceptions(this.container.id);
        this.populateDropdownData();
        await this.populateAddPayCodeFormData(parsedData, this.isImport);
        this.parseAndFormatAdditionalRequirements();
        this.determineDefaultOption();
        this.sanitizeData();
      } else {
        console.error('Failed to parse JSON data');
      }
    } catch (error) {
      console.error('Error during data import:', error);
    } finally {
      this.state.isLoading = false;      // End loading state
      this.cdr.detectChanges();          // Update UI to hide spinner
    }
  }

  private async populateAddPayCodeFormData(data: any, isImport: boolean): Promise<void> {
    if (isImport) {
      // Populate exceptions
      this.container.exceptions = [data.exceptions];
      this.container.exceptions[0].id = this.container.id;
      this.container.exceptions[0].exOrganizationId = this.orgId;

      // Append "_suffixCounter" to the exceptionDescription
      const originalDescription = this.container.exceptions[0].exceptionDescription || '';

      let suffixCounter = 1;

      const normalizedIncomingException = originalDescription.trim().toLowerCase();
    
      this.payCodeExceptions.forEach(item => {
        const normalizedException = item.description.trim().toLowerCase();
    
        if (normalizedException === normalizedIncomingException || 
            normalizedException.startsWith(`${normalizedIncomingException}_`)) {
          
          let newException = `${originalDescription.trim()}_${suffixCounter}`;
          
          while (this.payCodeExceptions.some(c => c.description.trim().toLowerCase() === newException.trim().toLowerCase())) {
            suffixCounter++;
            newException = `${originalDescription.trim()}_${suffixCounter}`;
          }
    
          this.container.exceptions[0].exceptionDescription = newException;
    
        }
      });

      // Populate additional requirements
      this.container.additionalRequirements = data.additionalRequirements;

      // Populate rules
      await this.populateRules(data.rules);

      // Mark data as dirty
      this.state.exceptionsDirty = true;
      this.state.additionalRequirementsDirty = true;
      this.state.rulesDirty = true;

      // Update records and reflect the final state
      this.container.updateRecords();
      this.cdr.detectChanges();
    }
  }

  private async populateRules(rulesData: any[]): Promise<void> {
    this.container.rules = []; // Initialize rules array

    for (const ruleData of rulesData) {
      // Generate new IDs and update references
      const newRuleId = Math.floor(Math.random() * 9000000) + 1000000;
      const newRule = {
        ...ruleData,
        id: newRuleId,
        exceptionId: this.container.id,
        organizationId: this.orgId,
        ruleFormulas: [],
      };

      // Populate rule formulas
      for (const formulaData of ruleData.ruleFormulas) {
        const newFormula = {
          ...formulaData,
          id: Math.floor(Math.random() * 9000000) + 1000000,
          ruleId: newRuleId,
        };
        newRule.ruleFormulas.push(newFormula);
      }

      this.container.rules.push(newRule);
    }
  }

  exportData() {
    const exceptionsData = this.container.exceptions[0];
    const rulesData = this.container.rules;
    const additionalRequirementsData = this.container.additionalRequirements;

    const propertiesToRemove = [
      'allocationTypes', 'exceptionGroups', 'exceptionVariables',
      'payDiffTypes', 'specialCodes', 'payCodeExceptions', 'workCodes',
      'interfaceCategories'
    ];

    propertiesToRemove.forEach(property => {
      if (exceptionsData.hasOwnProperty(property)) {
        delete exceptionsData[property];
      }
    });

    const combinedData = {
      exceptions: exceptionsData,
      rules: rulesData,
      additionalRequirements: additionalRequirementsData
    };

    const jsonData = JSON.stringify(combinedData, null, 2);
    this.generateChecksum(jsonData).then((checksum) => {
      combinedData['checksum'] = checksum;
      const exportJson = JSON.stringify(combinedData, null, 2);
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const timestamp = `${year}${month}${day}_${hours}${minutes}${seconds}`;
      const fileName = `export-data_${timestamp}.json`;

      this.downloadJson(exportJson, fileName);
    });
  }

  async generateChecksum(data: string): Promise<string> {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', dataBuffer);

    const checksum = Array.from(new Uint8Array(hashBuffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');

    return checksum;
  }

  downloadJson(jsonData: string, fileName: string) {
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }

  clearForm() {
    if (this.savedFormState) {
      this.restoreFormState();
    } else {
      this.templateForm.reset();
    }
  }

  restoreFormState() {
    if (this.templateForm && this.savedFormState) {
      this.templateForm.resetForm(this.savedFormState);
    }
  }
}