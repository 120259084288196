import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';

import { IUser } from '../../../authentication/store/index';
import { OrgLevel, OrgLevelType, User } from '../../../state-model/models/index';

import { PayCodesContainer, PayCodeModel, AccrualPolicy, AccrualTypes } from '../../models/index';
import { PayCodesApiService } from './pay-codes-api.service';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { PayCodesMapService } from './pay-codes-map.service';
import { Assert } from '../../../../app/framework';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { PaycodeExceptionSortOverrideMapService } from './paycode-exception-sortoverride-map.service';
import { PaycodeExceptionSortOverrideModel } from '../../models/pay-codes/paycode-exceptionsortoverride';
//import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent, StateManagementService, ColumnManagementService, ConfirmOptions, ColumnsChangedEvent } from '../../../common/index';




@Injectable()
export class PayCodesConfigurationManagementService extends ManagementBaseService<PayCodesContainer, any> implements IConfigurationManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  isDirtySortOrder: boolean = false;

  public get container(): PayCodesContainer {
    return this.m_container;
  }

  public isEditingNewItem: boolean;
  public editingItem: any;

  public addItemCmd$: ReplaySubject<any>;
  public editItemCmd$: ReplaySubject<any>;
  public viewRefresh$: Subject<boolean>;
  public removeItemsCmd$: ReplaySubject<any>;
  public onItemSaved$: ReplaySubject<PayCodeModel>;
  private exportTo$ = new Subject<boolean>();
  private m_container: PayCodesContainer;
  public bulkSaveResponse$: Subject<boolean>;
  //public gridState: KendoGridStateHelper<PayCodeModel>;

  @unsubscribeInService()
  private appDataSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentUser: User;

  private originalCollection: PayCodeModel[];

  constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: PayCodesApiService, private map: PayCodesMapService, private notificationsService: NotificationsService, private exceptionsortoverrideMap: PaycodeExceptionSortOverrideMapService) {
    super();
    this.removeItemsCmd$ = new ReplaySubject<any>();
    this.addItemCmd$ = new ReplaySubject<PayCodeModel>();
    this.editItemCmd$ = new ReplaySubject<PayCodeModel>();
    this.onItemSaved$ = new ReplaySubject<PayCodeModel>();
    this.viewRefresh$ = new Subject<boolean>();
    this.bulkSaveResponse$ = new Subject<boolean>();

    //this.gridState = new KendoGridStateHelper<PayCodeModel>();
    //this.gridState.state.skip = 0;
  }

  public init(): void {
    this.access.allowCorporationLevel = false;
    this.access.allowOrganizationLevel = true;
    this.access.allowDepartmentLevel = true;

    this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe((value: [OrgLevel, User]) => {
      let [orgLevel, user]: [OrgLevel, User] = value;
      if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
        return;
      }
      this.currentOrgLevel = orgLevel;
      this.currentUser = user;
      this.access.orgLevelType = this.currentOrgLevel.type;
      if (this.m_container) {
        this.m_container.records = [];
        this.m_container.exceptionsSortOverrides = [];
        this.onLoaded$.next(this.m_container);
      }
      this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
      this.fetchRecords(this.currentOrgLevel.id, this.currentUser);
    });

  }

  public markAsDirty(): void {
    this.changeService.changeNotify();
  }

  //#region IConfigurationManagementService implementation

  // no add or remove actions for this component at current state
  public onRemoveItem(itemToDelete: PayCodeModel): void {
    _.noop();
  }

  public onAddItem(item: any): void {
    _.noop();
  }

  public setSelectedCount(count: number): void {
    this.access.selectedItemsCount = count;
  }

  public onEditItem(item: any): void {
    this.editingItem = item;
    this.editItemCmd$.next(item);
  }

  public onCancelEditItem(): void {
    this.changeService.clearChanges();
    this.editingItem = null;
    this.editItemCmd$.next(null);
  }

  public onSaveItem(info: { dataItem: PayCodeModel, isNew: boolean }): void {
    this.updateItem(info.dataItem);
  }

  //#endregion IConfigurationManagementService implementation

  //#region bulk actions

  public setItemDirty(item: PayCodeModel): void {
    item.isDirty = true;
    this.changeService.changeNotify();
  }

  public setSortOrderDirty(): void {
    this.isDirtySortOrder = true;
  }

  public doBulkEdit(): void {
    this.originalCollection = this.map.clonePayCodesCollection(this.m_container.records);
    this.onStateChanged$.next({ bulkEditMode: true });
  }

  public doBulkSave(paycodeSortOrderExceptions : PaycodeExceptionSortOverrideModel[]): void {

    this.onStateChanged$.next({ isLoading: true });
    let dirtyRecords: PayCodeModel[] = [];
    let dirtyexceptionSortOrdersRecords : PaycodeExceptionSortOverrideModel[] = [];
    _.each(this.m_container.records, (model: PayCodeModel) => {
      if (model.isDirty) {
        dirtyRecords.push(model);
      }
    });

    if (this.isDirtySortOrder) {      
      dirtyexceptionSortOrdersRecords = paycodeSortOrderExceptions;
    }

    if (dirtyRecords.length > 0) {
      this.api.savePayCodes(dirtyRecords, this.currentOrgLevel.id)
        .then(() => {
          this.originalCollection = null;
          this.updateDirtyRecords();
          this.bulkSaveResponse$.next(true);
          this.notificationsService.success('Success', 'The Changes are updated to Pay Codes Configuration successful');
          this.changeService.clearChanges();
          this.viewRefresh$.next(false);
          this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }).catch(() => {
          this.viewRefresh$.next(false);
          this.onStateChanged$.next({ isLoading: false });
        });
    } else {
      this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
    }

    if (dirtyexceptionSortOrdersRecords.length > 0) {
      this.api.savePaycodeExceptionSortOverrides(dirtyexceptionSortOrdersRecords)
        .then(() => {
          this.updateSortOrderDirtyRecords();
          this.notificationsService.success('Success', 'The SortOrder is updated to Pay Codes Configuration successful');
          this.changeService.clearChanges();
          this.viewRefresh$.next(false);
          this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
          //this.gridState.view.data = [...this.gridState.view.data];
          //window.location.reload();
        }).catch(() => {
          this.viewRefresh$.next(false);
          this.onStateChanged$.next({ isLoading: false });
        });
    } else {
      this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
    }
    //this.gridState.view.data = [...this.gridState.view.data];
  }

  public doBulkDiscard(): void {
    this.m_container.records = this.originalCollection;
    this.changeService.clearChanges();
    this.onStateChanged$.next({ bulkEditMode: false });
    this.onLoaded$.next(this.m_container);
  }

  public updateDirtyRecords() {
    _.each(this.m_container.records, (model: PayCodeModel) => {
      if (!model.payCode.isUsedInAccrualCalc) {
        model.payCode.accrualPolicyList = [];
        model.payCode.accrualPolicyNames = '';
      }
      else if (model.payCode.isUsedInAccrualCalc && (_.isNull(model.payCode.accrualPolicyList) || (model.payCode.accrualPolicyList && model.payCode.accrualPolicyList.length == 0))) {
        model.payCode.accrualPolicyNames = 'All';
      }
      if (model.isDirty)
        model.isDirty = false;
    });
  }

  public updateSortOrderDirtyRecords() {
    _.each(this.m_container.exceptionsSortOverrides, (model: PaycodeExceptionSortOverrideModel) => {
      if (this.isDirtySortOrder)
        this.isDirtySortOrder = false;
    });
  }

  //#endregion bulk actions

  protected fetchRecords(orgLevelId: number, user: User): void {

    this.onStateChanged$.next({ isLoading: true });
    this.access.lockActions = true;
    this.api.getPayCodes(orgLevelId).
      then(async (result: PayCodesContainer) => {
        this.access.lockActions = false;

        this.m_container = result;

        let dirtySortOrdersRecords : PaycodeExceptionSortOverrideModel[] = [];

        let dirtyExceptions : PayCodeModel[] = [];

        if (user.isSmartAdmin)
        {
          dirtySortOrdersRecords = await this.fetchSortPaycodeExceptionSortOverrides();
        }

        dirtyExceptions = this.m_container.records;
        
        if (dirtySortOrdersRecords !== undefined && dirtySortOrdersRecords !== null) {
          this.m_container.sortRecordsWithOverrides(dirtyExceptions, dirtySortOrdersRecords);
        }

        this.editingItem = null;
        this.isEditingNewItem = false;

        if (this.m_container.actions) this.access.actions = this.m_container.actions;

        this.onLoaded$.next(this.m_container);
        let state: any = {
          isLoading: false,
          bulkEditMode: false,
          canBulkEdit: this.m_container.actions.canEdit || this.m_container.canEditExceptionFlag || this.m_container.canEditPaycodestoAccrualMappingFlag,
          canEditPBJFlag: this.m_container.actions.canEdit,
          canEditUseInTimesheets: this.m_container.actions.canEdit,
          canEditExceptionFlag: this.m_container.canEditExceptionFlag,
          canEditAca:this.m_container.actions.canEdit,
          canEditPaycodestoAccrualMappingFlag: this.m_container.canEditPaycodestoAccrualMappingFlag,
          canEdit: false,
          canAdd: false,
          canDelete: false,
          isSmartAdmin: user.isSmartAdmin
        };
        this.onStateChanged$.next(state);
      }).catch((e: Error) => {
        this.m_container = new PayCodesContainer();
        this.m_container.records = [];
        this.onLoaded$.next(this.m_container);
        this.access.lockActions = false;
        this.onStateChanged$.next({ isLoading: false });
      });

  }

  protected async fetchSortPaycodeExceptionSortOverrides() : Promise<PaycodeExceptionSortOverrideModel[]> {
    try {
      const overrides = await this.fetchPaycodeExceptionSortOverrides();
      this.m_container.exceptionsSortOverrides = overrides;
      return overrides;
      } 
      catch (error) {
    }
  }

  protected updateItem(item: PayCodeModel): void {

    this.onStateChanged$.next({ isLoading: true });
    this.api.savePayCode(item)
      .then(() => {
        this.onItemSaved$.next(this.editingItem);
        this.editingItem = null;
        this.viewRefresh$.next(false);
        this.onStateChanged$.next({ isLoading: false });
        this.changeService.clearChanges();
      }).catch(() => {
        this.viewRefresh$.next(false);
        this.onStateChanged$.next({ isLoading: false });
      });
  }

  private updatePayCodeModel(from: PayCodeModel, to: PayCodeModel): void {
    to.payCode.id = from.payCode.id;
    to.payCode.description = from.payCode.description;
    to.payCode.allocationType = from.payCode.allocationType;
    to.payCode.amount = from.payCode.amount;
    to.payCode.color = from.payCode.color;
    to.payCode.department = from.payCode.department;
    to.payCode.description = from.payCode.description;
    to.payCode.group = from.payCode.group;
    to.payCode.interfaceIndicator = from.payCode.interfaceIndicator;
    to.payCode.isAca = from.payCode.isAca;
    to.payCode.isPaid = from.payCode.isPaid;
    to.payCode.isPbj = from.payCode.isPbj;
    to.payCode.payDifferential = from.payCode.payDifferential;
    to.payCode.isException = from.payCode.isException;
    to.lastUpdateDate = from.lastUpdateDate;
    to.lastUpdateUsername = from.lastUpdateUsername;
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public subscribeToBulkSaveResponse(callback: (result: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.bulkSaveResponse$.subscribe(callback);
  }

  public getAccrualPolicies(orglevelId: number): Promise<AccrualPolicy[]> {
    return this.api.getAccrualPolicy(orglevelId);
  }

  public getAccrualTypes(orglevelId: number): Promise<AccrualTypes[]> {
    return this.api.getAccrualType(orglevelId);
  }

  public fetchPaycodeExceptionSortOverrides(): Promise<PaycodeExceptionSortOverrideModel[]> {
    return this.api.getPaycodeExceptionSortOverrides();
  }

  public savePaycodeExceptionSortOverride(data: any): Promise<void> {
    return this.api.savePaycodeExceptionSortOverrides(data).then(() => {
      this.notificationsService.success('Success', 'Paycode exception sort override saved successfully');
    }).catch(error => {
      console.error('Error saving paycode exception sort override:', error);
      throw error;
    });
  }

  public deletePaycodeExceptionSortOverride(id: number): void {
    this.api.deletePaycodeExceptionSortOverride(id).then(() => {
      this.notificationsService.success('Success', 'Paycode exception sort override deleted successfully');
    }).catch(error => {
      console.error('Error deleting paycode exception sort override:', error);
    });
  }
}
